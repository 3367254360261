import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import DataTable, { type HeaderType } from '../../common/datatable';
import CustomizedDialog from '../../common/dialog';
import CustomPagination from '../../common/pagination';
import RowsPerPage from '../../common/rowsPerPage';
import SearchInput from '../../common/searchInput';
import ToastMessage from '../../common/toastMessage';
import { useCluster } from '../../hooks/useCluster';
import { type clusterDetailsType, type districtItemType } from '../../redux/cluster/types';
import AddCluster from '../addCluster';
import { useStyles } from './styles';

const Cluster: React.FC = () => {
  // classes
  const classes = useStyles();

  // table headers
  const clusterHeader: HeaderType[] = [
    {
      label: 'State',
      key: 'stateName',
      enableSort: true
    },
    {
      label: 'cluster',
      key: 'clusterName',
      enableSort: true
    },
    {
      label: 'Districts',
      key: '',
      enableSort: false,
      actions: (row: any) => renderArrayType(row, 'districts')
    },
    {
      label: 'Action',
      key: '',
      enableSort: false,
      actions: (index: any) => renderAction(index)
    }
  ];

  const { pageNo, totalPages, message, errorMessage, isClusterListLoading, pageSize, searchText, clusterListDetails: { clusterListdto }, fetchSetClusterStates, fetchGetClusterList, fetchResetClusterStates } = useCluster();

  // states
  const [view, setView] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  // effects
  useEffect(() => {
    return () => {
      fetchResetClusterStates('pageNo');
      fetchResetClusterStates('pageSize');
      fetchResetClusterStates('searchText');
    };
  }, []);

  useEffect(() => {
    fetchGetClusterList({ clusterOnboardingFlow: true, pageNo, pageSize, searchKey: searchText });
  }, [searchText, pageSize, pageNo]);

  useEffect(() => {
    if (message) {
      fetchGetClusterList({ clusterOnboardingFlow: true, pageNo, pageSize, searchKey: searchText });
      setShowDialog(false);
      setTimeout(() => {
        fetchSetClusterStates({ message: '' });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      fetchSetClusterStates({ errorMessage: '' });
    }, 5000);
  }, [errorMessage]);

  const handleMachineSearchChange = (eventVal: string) => {
    fetchSetClusterStates({
      pageNo: 0,
      searchText: eventVal?.toLowerCase()
    });
  };

  const handlePageChange = (pageNo: number) => {
    fetchSetClusterStates({ pageNo: pageNo - 1 });
  };

  const renderArrayType = (row: any, key: string) => {
    return (
      <span>
        {row?.[key]?.map((ob: any) => ob?.value)?.toString()}
      </span>
    );
  };

  const renderAction = (index: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          style={{ cursor: 'pointer' }}
          src={`${process.env.PUBLIC_URL}/view.png`}
          onClick={() => {
            onViewUserClickHandler(index);
          }}
        />
      </div>
    );
  };

  const onViewUserClickHandler = (data: clusterDetailsType) => {
    const districtsNames: string[] = data?.districts?.map((district: districtItemType) => `${district?.value}`) || [];
    const districtsIds: string[] | number[] = data?.districts?.map((district: districtItemType) => Number(district?.id)) || [];
    fetchSetClusterStates({ clusterDetails: { ...data, districtsNames, districtsIds } });
    setView(true);
    setShowDialog(true);
  };

  return (
    <div className={classes.onBoardContainer}>
      <>
            <Box className={classes.inputContainer}>
              <CustomButton
                label="Add Cluster"
                onClick={() => {
                  setView(false);
                  fetchResetClusterStates('clusterDetails');
                  setShowDialog(!showDialog);
                }}
                customStyle={classes.addButton}
                startIcon={
                  <img src={`${process.env.PUBLIC_URL}/add.png`} alt="add" />
                }
              />
              <Box></Box>
              <Box style={{ width: '20%', border: '1px solid #C2C7D0' }}>
                <SearchInput
                  onSearch={handleMachineSearchChange}
                  placeholder="Search Cluster"
                  searchText={searchText}
                  customStyle={classes.searchInput}
                ></SearchInput>
              </Box>
            </Box>

        <Box className={classes.customTableContainer}>
          <DataTable
            data={clusterListdto}
            headers={clusterHeader}
            stickyHeaders={[]}
            loading={isClusterListLoading}
          ></DataTable>
        </Box>
        <CustomizedDialog
          customStyle={classes.customizedDialog}
          showDialog={showDialog}
          setShowDialog={() => {
            setShowDialog(!showDialog);
          }}
          title={'Add Cluster'}
        >
          <AddCluster
            view={view}
            setView={(e) => {
              setView(e);
            }}
            setShowDialog={(e: any) => {
              setShowDialog(e);
            }}
          ></AddCluster>
        </CustomizedDialog>
      </>
      <Box style={{ display: 'flex', marginTop: '8px' }}>
        <Box
          style={{
            flex: 1
          }}
        >
          {totalPages > 0 && !isClusterListLoading && (
              <CustomPagination
                onChangePageNumber={handlePageChange}
                pageNumber={Number(pageNo) + 1}
                totalPages={totalPages}
              />
          )}
        </Box>
        <Box style={{ position: 'absolute', right: 0, marginRight: '16px' }}>
          { totalPages > 0 && !isClusterListLoading && <RowsPerPage
            rowsPerPage={pageSize}
            setRowsPerPage={(e: number) => {
              // setRowsPerPage(e)
              fetchSetClusterStates({ pageSize: e, pageNo: 0 });
            }}
          ></RowsPerPage>}
        </Box>
      </Box>
      {message && (
        <ToastMessage message={message} errorType="success"></ToastMessage>
      )}
      {errorMessage && (
        <ToastMessage message={errorMessage} errorType="error"></ToastMessage>
      )}
    </div>
  );
};

export default Cluster;
