import { Button, CircularProgress, FormControl, IconButton, TextField } from '@mui/material';
import React, { useState, createRef, type RefObject, useEffect } from 'react';
import CustomButton from '../../common/button';
// import { RegEx } from "../../constants";
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../hooks';
import { OTP_LOCALSTORAGE_KEY, PHONE_COUNTRY_CODE_IN, RegEx } from '../../constants';

const VerifyOtp: React.FC = () => {
  // classes
  const classes = useStyles();

  // hooks
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [otpText, setOtpText] = useState<string>('');
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);
  const navigate = useNavigate();
  const {
    otpSent,
    mobile,
    isLoading,
    resetOtpSent,
    verifyOtp,
    sendOtp,
    errorVerifyOtp
  } = useLogin();

  // timestamp
  const otpSentTimestamp = localStorage.getItem(OTP_LOCALSTORAGE_KEY);

  // effects
  useEffect(() => {
    if (otpSent) {
      resetOtpSent();
      const timestamp: string = Date.now().toString();
      localStorage.setItem(OTP_LOCALSTORAGE_KEY, timestamp);
    }
  }, [otpSent]);

  const interval = setInterval(() => {
    if (otpSentTimestamp) {
      if (otpSentTimestamp) {
        const otpSentTime = new Date(parseInt(otpSentTimestamp));
        const currentDate = new Date(Date.now());
        const difference = currentDate.getTime() - otpSentTime.getTime();
        const remainingTime: number = Math.round(Math.abs(difference / 1000)) - 60;
        if (remainingTime <= 0) {
          setRemainingSeconds(remainingTime * -1);
        }
      }
    }
  });

  const removeTimeStamp = () => {
    localStorage.removeItem('timestamp');
  };

  if (remainingSeconds && remainingSeconds <= 0) {
    removeTimeStamp();
    clearInterval(interval);
  }

  const fields: Array<RefObject<HTMLInputElement>> = [1, 2, 3, 4, 5, 6].map(() => createRef());

  // handlers
  // TODO:: Some more scenrios to be handle here
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (value?.length > 1) {
      value = value.slice(-1);
      const currentInput: HTMLInputElement | null = fields?.[currentIndex]?.current;
      if (currentInput != null) {
        currentInput.value = value;
      }
    }
    let nextIndex: number = currentIndex;
    if (value && currentIndex <= 4) {
      nextIndex = currentIndex + 1;
    } else if (!value && currentIndex > 0) {
      nextIndex = currentIndex - 1;
    }
    const nextInput: HTMLInputElement | null = fields?.[nextIndex]?.current;
    if (nextInput != null) {
      nextInput.focus();
    }
    getOtpText();
    setCurrentIndex(nextIndex);
  };

  const handleEdit = () => {
    removeTimeStamp();
    navigate('/');
  };

  const getOtpText = () => {
    let otp: string = '';
    fields.forEach((input: RefObject<HTMLInputElement>) => {
      otp = `${otp}${input.current?.value}`;
    });
    setOtpText(otp);
  };

  const handleVerifyOtp = () => {
    // getOtpText();
    verifyOtp({
      mobile,
      identifier: otpText
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, code } = event;

    if ((key.match(RegEx.OnlyDigits) == null) && code !== 'Backspace') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (code === 'Backspace' && currentIndex > 0 && otpText.length === 0) {
      const index: number = currentIndex - 1;
      setCurrentIndex(index);
      const nextInput: HTMLInputElement | null = fields?.[index]?.current;
      if (nextInput != null) {
        nextInput.focus();
      }
    }
  };

  const handleResend = () => {
    sendOtp({
      mobile
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginImage}>
        <img src={`${process.env.PUBLIC_URL}/verify-otp-screen.png`} alt="login" />
      </div>
      <div style={{ margin: '50px 150px', width: '400px' }}>
        <div className={classes.container}>
          <img loading="lazy" style={{ height: 'auto' }} src={`${process.env.PUBLIC_URL}/syngenta-logo.png`} alt="syngenta-logo" />
        </div>
        <div className={classes.headerContainer}>
          <p className={classes.otpTextHelper}>Verify OTP</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className={classes.editNumberHelper}>Edit Mobile number</p>
          <IconButton onClick={handleEdit}>
            <img style={{ height: '16px', width: '16px' }} src={`${process.env.PUBLIC_URL}/pencil.png`} alt="syngenta-logo" />
          </IconButton>
        </div>
        <div className={classes.container}>
          <p className={classes.mobileNumberLabel}>
            Verification code sent to
            {mobile && (
              ` +${PHONE_COUNTRY_CODE_IN} ${mobile}`
            )}
          </p>
          <FormControl fullWidth>
            <div style={{ display: 'inline-flex' }}>
              {fields.map((ref: React.RefObject<HTMLInputElement>, index: number) => {
                return (
                  <TextField
                    inputRef={ref}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    key={index}
                    name={String(index)}
                    placeholder="-"
                    style={index < 5 ? { marginRight: '16px' } : {}}
                    className={errorVerifyOtp ? `${classes.disabledTextFieldHelper}` : classes.textFieldHelper}
                    size="small"
                    onChange={handleChange}
                    onFocus={() => {
                      setCurrentIndex(index);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                );
              })
              }
            </div>
          </FormControl>
          {errorVerifyOtp && (
            <div className={classes.wrongTextContainer}>
              <p className={classes.wrongOtpText}>Wrong OTP entered</p>
            </div>
          )}
          <div className={errorVerifyOtp ? '' : classes.actionContainer}>
            <CustomButton
              disabled={otpText?.length < 6}
              label="SUBMIT"
              fullWidth={true}
              // loading={isLoading}
              onClick={handleVerifyOtp}
              startIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : null}
            />
          </div>
        </div>
        <div className={classes.resendContainer}>
          <p className={classes.otpNotReceived}>
            {remainingSeconds > 0
              ? (
              <span>{remainingSeconds}s to resend otp</span>
                )
              : <span>Didn’t receive the OTP yet?</span>}
          </p>
          <Button
            disabled={remainingSeconds > 0}
            className={classes.resendButton}
            onClick={handleResend}
          >
            <p className={classes.resendOtp}>
              Resend OTP
            </p>
          </Button>
        </div>
        <div className={classes.secureLoginImage}>
          <img src={`${process.env.PUBLIC_URL}/verify-otp-disable.png`} alt="secure-login" />
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
