import { ADD_CLUSTER, ADD_CLUSTER_FAILED, ADD_CLUSTER_SUCCESS, GET_CLUSTER_LIST, GET_CLUSTER_LIST_FAILED, GET_CLUSTER_LIST_SUCCESS, RESET_CLUSTER_STATES, SET_CLUSTER_STATES, UPDATE_CLUSTER, UPDATE_CLUSTER_FAILED, UPDATE_CLUSTER_SUCCESS } from './actionType';

import {
  type getClusterListRequestPayload,
  type getClusterListRequestType,
  type getClusterListSuccessType,
  type getClusterListFailedPayloadtype,
  type getClusterListFailedType,
  type clusterState,
  type ResetClusterStates,
  type SetClusterStates,
  type getClusterListSucessPayloadType,
  type addClusterFailedPayloadtype,
  type addClusterFailedType,
  type addClusterRequestPayload,
  type addClusterRequestType,
  type addClusterSuccessType,
  type addClusterSucessPayloadType,
  type updateClusterFailedPayloadtype,
  type updateClusterFailedType,
  type updateClusterRequestPayload,
  type updateClusterRequestType,
  type updateClusterSuccessType,
  type updateClusterSucessPayloadType
} from './types';

// for get cluster list

export const getClusterListRequest = (payload: getClusterListRequestPayload): getClusterListRequestType => {
  return {
    type: GET_CLUSTER_LIST,
    payload
  };
};

export const getClusterListSuccess = (
  payload: getClusterListSucessPayloadType
): getClusterListSuccessType => {
  return {
    type: GET_CLUSTER_LIST_SUCCESS,
    payload
  };
};

export const getClusterListFailed = (
  payload: getClusterListFailedPayloadtype
): getClusterListFailedType => {
  return {
    type: GET_CLUSTER_LIST_FAILED,
    payload
  };
};

// for add cluster

export const addClusterRequest = (payload: addClusterRequestPayload): addClusterRequestType => {
  return {
    type: ADD_CLUSTER,
    payload
  };
};

export const addClusterSuccess = (
  payload: addClusterSucessPayloadType
): addClusterSuccessType => {
  return {
    type: ADD_CLUSTER_SUCCESS,
    payload
  };
};

export const addClusterFailed = (
  payload: addClusterFailedPayloadtype
): addClusterFailedType => {
  return {
    type: ADD_CLUSTER_FAILED,
    payload
  };
};

// for update cluster

export const updateClusterRequest = (payload: updateClusterRequestPayload): updateClusterRequestType => {
  return {
    type: UPDATE_CLUSTER,
    payload
  };
};

export const updateClusterSuccess = (
  payload: updateClusterSucessPayloadType
): updateClusterSuccessType => {
  return {
    type: UPDATE_CLUSTER_SUCCESS,
    payload
  };
};

export const updateClusterFailed = (
  payload: updateClusterFailedPayloadtype
): updateClusterFailedType => {
  return {
    type: UPDATE_CLUSTER_FAILED,
    payload
  };
};

// for set and reset states

export const resetClusterStates = (
  payload: keyof clusterState
): ResetClusterStates => {
  return {
    type: RESET_CLUSTER_STATES,
    payload
  };
};

export const setClusterStates = (
  payload: clusterState
): SetClusterStates => {
  return {
    type: SET_CLUSTER_STATES,
    payload
  };
};
