import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  wrapper: {
    background: COLORS.TableBackgroundGray,
    flex: 1,
    overflow: 'scroll',
    paddingBottom: '16px'
  },
  regionWrapper: {
    flex: 1,
    flexDirection: 'row',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '0px'
  },
  displayInlineBlock: {
    display: 'inline-block'
  },
  displayInlineWithMarginRight16: {
    display: 'inline-block',
    marginRight: '16px'
  },
  totalAcerageWrapper: {
    background: `${COLORS.White} !important`,
    border: `1px solid ${COLORS.TableCellBorder} !important`
  },
  totalOrdersWrapper: {
    background: `${COLORS.White} !important`,
    marginTop: '24px !important'
  },
  topCropsAndProducts: {
    padding: '0px !important',
    border: '0px !important',
    background: `${COLORS.TableBackgroundGray} !important`
  },
  rankChart: {
    borderRadius: '4px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    background: `${COLORS.White} !important`,
    border: `1px solid ${COLORS.TableCellBorder} !important`
  },
  cardWrapper: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    marginRight: '16px',
    marginTop: '16px',
    height: '150px'
  },
  totalRevenuWrapper: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px 0px 16px 16px'
  },
  totalRevenuTextWrapper: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  totalRevenueText: {
    ...fontFamilies.semiBold2020,
    marginTop: '10px',
    marginBottom: '18px',
    color: COLORS.back_90
  },
  totalRevenueTextValue: {
    ...fontFamilies.boldSemiBold4020,
    marginRight: '40px',
    color: COLORS.teal_70
  },
  totalAcerageTextValue: {
    ...fontFamilies.boldSemiBold4820,
    marginRight: '40px',
    color: COLORS.Nuetral_20
  },
  totalRevenueTextDesc: {
    ...fontFamilies.semiBold1624,
    marginBottom: 0,
    color: COLORS.back_90
  },
  coinAnimationImageWrapper: {
    width: '150px',
    height: '150px'
  },
  coinImage: {
    height: '130px'
  },
  totalAcerageIconImageWrapper: {
    flexDirection: 'row',
    marginRight: '30px'
  },
  marginTop24: {
    marginTop: '24px'
  },
  totalOperatorWrapper: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    margin: '16px'
  },
  activeInActiveImgWrapper: {
    flexDirection: 'row',
    marginRight: '30px',
    marginTop: '8px'
  },
  activeInActiveImg: {
    marginTop: '10px'
  },
  activeInActiveWrapper: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: '10px'
  },
  activeValueText: {
    ...fontFamilies.boldSemiBold4020,
    fontSize: '40px',
    margin: '0px',
    color: COLORS.green_60
  },
  activeLabelText: {
    ...fontFamilies.mediumSemiBold1621,
    margin: '10px 0px 0px 0px'
  },
  inactiveWraper: {
    borderLeft: '1px dashed #A3A9B9',
    marginLeft: '24px',
    marginRight: '24px'
  },
  inactveValueText: {
    ...fontFamilies.boldSemiBold4020,
    fontSize: '40px',
    margin: '0px',
    color: COLORS.red_60
  },
  inactiveLabelText: {
    ...fontFamilies.mediumSemiBold1621,
    margin: '10px 0px 0px 0px'
  },
  totalOrderWrapper: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: '20px',
    margin: '16px'
  },
  totalOrderTextWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex'
  },
  totalOrderText: {
    ...fontFamilies.semiBold2020,
    marginTop: '10px',
    color: COLORS.Nuetral_80
  },
  totalOrderDoughnutWrapper: {
    width: '216px',
    marginTop: '50px'
  },
  totalorderListWrapper: {
    marginRight: '50px',
    marginTop: '40px'
  },
  totalOrderItemWrapper: {
    flexDirection: 'column',
    display: 'flex',
    marginBottom: '24px'
  },
  totalOrderKeyItem: {
    ...fontFamilies.boldRegular2420,
    color: COLORS.MenuBlue
  },
  totalOrderValueItem: {
    ...fontFamilies.regular1622,
    color: COLORS.Nuetral_20,
    marginTop: 1
  },
  topCropChartWrapper: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1
  },
  topCropsText: {
    ...fontFamilies.semiBold2020,
    paddingTop: '12px',
    paddingBottom: '12px',
    background: COLORS.green_20,
    paddingLeft: '24px',
    marginBottom: '16px',
    color: COLORS.Nuetral_100
  },
  topProductsText: {
    ...fontFamilies.semiBold1624,
    flex: 1,
    paddingTop: '12px',
    paddingBottom: '12px',
    background: COLORS.purple_10,
    paddingLeft: '24px',
    marginBottom: '16px',
    fontSize: '16px',
    color: COLORS.Nuetral_100
  },
  rankWrapper: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '18px'
  },
  rankItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  secondRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '11px',
    color: COLORS.yellow
  },
  firstRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '6px',
    color: COLORS.lightGreen
  },
  threeRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '13px',
    color: COLORS.lightRed
  },
  secondProdRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '11px',
    color: COLORS.lightGray
  },
  firstProdRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '6px',
    color: COLORS.lightyellow
  },
  threeProdRankText: {
    ...fontFamilies.semiBold1420,
    margin: '0px',
    textAlign: 'center',
    paddingBottom: '13px',
    color: COLORS.lightBrown
  },
  totalMachineWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    paddingBottom: '20px',
    margin: '16px'
  },
  totalMachineText: {
    ...fontFamilies.semiBold2020,
    fontSize: '20px',
    marginTop: '10px',
    color: COLORS.Nuetral_80
  },
  sprayWrapper: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    marginRight: '24px',
    marginTop: '24px'
  },
  droneWrapper: {
    flex: 1,
    borderRadius: '2px',
    border: '1px solid',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '10px'
  },
  boomWrapper: {
    flex: 1,
    borderRadius: '2px',
    border: '1px solid',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center'
  },
  totalMachineChartWrapper: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  totaMachineDoughnutWrapper: {
    width: '216px',
    marginTop: '50px'
  },
  totalMachineChartListWrapper: {
    marginRight: '30px',
    marginTop: '40px'
  },
  droneText: {
    ...fontFamilies.semiBold1622
  },
  resetButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.green_50} !important`,
    background: 'none !important',
    border: `1px dashed ${COLORS.green_50} !important`,
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  applyButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: `${COLORS.green_50} !important`,
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none',
    marginLeft: '12px'
  },
  selectedSprayType: {
    backgroundColor: COLORS.green_20
  }
}));
