import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_ORDER_STATUS, GET_ALL_STATES, GET_CLUSTORS, GET_SDO, GET_MACHINES, GET_ORDER_DETAILS, GET_DISTRICTS, GET_OPERATOR_LIST, GET_GEO_HIERARCHY_DISTRICTS } from './actionType';
import { getOrderStatusSuccess, getOrderStatusFailed, getAllStatesSuccess, getAllStatesFailed, getClutorsSuccess, getClutorsFailed, getSDOsSuccess, getSDOsFailed, getMachinesSuccess, getMachinesFailed, getOrderDetailsSuccess, getOrderDetailsFailed, getDistrictsSuccess, getDistrictsFailed, getOpearatorListSuccess, getOpearatorListFailed, getGeoHierarchyDistrictsSuccess, getGeoHierarchyDistrictsFailed } from './action';
import { type GetOrderStatusType, type GetClustorsType, type GetMachineType, type GetOrderDetailsType, type GetSDOType, type OrderDetailsType, type SDOItemType, type stateItemType, type statusItemType, type DistrictItemType, type GetDistrictType, type OperatorListItemType, type GetOperatorListType, type getClutorsServiceResponseType, type MachineServiceResponseType, type GetGeoHierarchyDistrictsType, type GeoHierarchyDistrictType } from './types';
import { getAllStatesService, getOrderStatusService, getClustorsService, getSDOService, getMachineService, getOrderDetailsService, getDistrictService, getOperatorListService, getGeoHierarchyDistrictService } from '../../services/filter';

function * getAllStates () {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: stateItemType[] = yield call(getAllStatesService);
    yield put(getAllStatesSuccess({ states: response }));
  } catch (error: unknown) {
    yield put(getAllStatesFailed({ error: true }));
  }
}

function * getOrderStatus (action: GetOrderStatusType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: statusItemType[] = yield call(getOrderStatusService, action.payload);
    yield put(getOrderStatusSuccess({ orderStatus: response, type: action?.payload?.type }));
  } catch (error: unknown) {
    yield put(getOrderStatusFailed({ error: true }));
  }
}

function * getClustors (action: GetClustorsType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: getClutorsServiceResponseType = yield call(getClustorsService, action.payload);
    const { clusterListdto } = response || {};
    yield put(getClutorsSuccess({ clustors: clusterListdto }));
  } catch (error: unknown) {
    yield put(getClutorsFailed({ error: true }));
  }
}

function * getOpearatorList (action: GetOperatorListType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: OperatorListItemType[] = yield call(getOperatorListService, action.payload);
    yield put(getOpearatorListSuccess({ operators: response }));
  } catch (error: unknown) {
    yield put(getOpearatorListFailed({ error: true }));
  }
}

function * getSDOs (action: GetSDOType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: SDOItemType[] = yield call(getSDOService, action.payload);
    yield put(getSDOsSuccess({ sdos: response }));
  } catch (error: unknown) {
    yield put(getSDOsFailed({ error: true }));
  }
}

function * getMachines (action: GetMachineType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: MachineServiceResponseType = yield call(getMachineService, action.payload);
    const { machineDetails } = response || [];
    yield put(getMachinesSuccess({ machines: machineDetails }));
  } catch (error: unknown) {
    yield put(getMachinesFailed({ error: true }));
  }
}

function * getDistricts (action: GetDistrictType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: DistrictItemType[] = yield call(getDistrictService, action.payload);
    yield put(getDistrictsSuccess({ districts: response }));
  } catch (error: unknown) {
    yield put(getDistrictsFailed({ error: true }));
  }
}

function * getgeoHierarchyDistricts (action: GetGeoHierarchyDistrictsType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: GeoHierarchyDistrictType[] = yield call(getGeoHierarchyDistrictService, action.payload);
    yield put(getGeoHierarchyDistrictsSuccess({ geoHierarchyDistricts: response }));
  } catch (error: unknown) {
    yield put(getGeoHierarchyDistrictsFailed({ error: true }));
  }
}

function * getOrderDetails (action: GetOrderDetailsType) {
  try {
    // yield delay(5000);
    // yield put(getAllStatesSuccess({ orderStatus: ['All'] }));
    const response: OrderDetailsType = yield call(getOrderDetailsService, action.payload);
    yield put(getOrderDetailsSuccess({ orderDetails: response }));
  } catch (error: unknown) {
    yield put(getOrderDetailsFailed({ error: true }));
  }
}

function * filterSaga () {
  yield all([takeLatest(GET_ALL_STATES, getAllStates)]);
  yield all([takeLatest(GET_ORDER_STATUS, getOrderStatus)]);
  yield all([takeLatest(GET_CLUSTORS, getClustors)]);
  yield all([takeLatest(GET_SDO, getSDOs)]);
  yield all([takeLatest(GET_MACHINES, getMachines)]);
  yield all([takeLatest(GET_DISTRICTS, getDistricts)]);
  yield all([takeLatest(GET_GEO_HIERARCHY_DISTRICTS, getgeoHierarchyDistricts)]);
  yield all([takeLatest(GET_ORDER_DETAILS, getOrderDetails)]);
  yield all([takeLatest(GET_OPERATOR_LIST, getOpearatorList)]);
}
export default filterSaga;
