import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,

  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,

  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,

  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  RESET_ONBOARD_STATES

} from './actionType';
import {
  type GetUserListType,
  type getUserListSucessPayloadType,
  type GetUserListSuccessType,
  type GetUserListFailedType,
  type getUserListFailedPayloadtype,
  type getUserListRequestPayload,
  type addUserSucessPayloadType,
  type AddUserSuccessType,
  type AddUserType,
  type addUserRequestPayload,
  type addUserFailedPayloadtype,
  type AddUserFailedType,
  type getUserRequestPayload,
  type GetUserType,
  type getUserSucessPayloadType,
  type GetUserSuccessType,
  type getUserFailedPayloadtype,
  type GetUserFailedType,
  type updateUserRequestPayload,
  type UpdateUserType,
  type updateUserSucessPayloadType,
  type UpdateUserSuccessType,
  type updateUserFailedPayloadtype,
  type UpdateUserFailedType,
  type OnBoardState,
  type ResetOnboardStates
} from './types';

// for get user list

export const getUserListRequest = (payload: getUserListRequestPayload): GetUserListType => {
  return {
    type: GET_USER_LIST,
    payload
  };
};

export const getUserListSuccess = (
  payload: getUserListSucessPayloadType
): GetUserListSuccessType => {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload
  };
};

export const getUserListFailed = (
  payload: getUserListFailedPayloadtype
): GetUserListFailedType => {
  return {
    type: GET_USER_LIST_FAILED,
    payload
  };
};

// for add user

export const addUserRequest = (payload: addUserRequestPayload): AddUserType => {
  return {
    type: ADD_USER,
    payload
  };
};

export const addUserSuccess = (
  payload: addUserSucessPayloadType
): AddUserSuccessType => {
  return {
    type: ADD_USER_SUCCESS,
    payload
  };
};

export const addUserFailed = (
  payload: addUserFailedPayloadtype
): AddUserFailedType => {
  return {
    type: ADD_USER_FAILED,
    payload
  };
};

// for get user

export const getUserRequest = (payload: getUserRequestPayload): GetUserType => {
  return {
    type: GET_USER,
    payload
  };
};

export const getUserSuccess = (
  payload: getUserSucessPayloadType
): GetUserSuccessType => {
  return {
    type: GET_USER_SUCCESS,
    payload
  };
};

export const getUserFailed = (
  payload: getUserFailedPayloadtype
): GetUserFailedType => {
  return {
    type: GET_USER_FAILED,
    payload
  };
};

// for update user

export const updateUserRequest = (payload: updateUserRequestPayload): UpdateUserType => {
  return {
    type: UPDATE_USER,
    payload
  };
};

export const updateUserSuccess = (
  payload: updateUserSucessPayloadType
): UpdateUserSuccessType => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload
  };
};

export const updateUserFailed = (
  payload: updateUserFailedPayloadtype
): UpdateUserFailedType => {
  return {
    type: UPDATE_USER_FAILED,
    payload
  };
};

export const resetOnboardStates = (
  payload: keyof OnBoardState
): ResetOnboardStates => {
  return {
    type: RESET_ONBOARD_STATES,
    payload
  };
};
