import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../common/button';
import CustomSelectBox from '../../common/customSelectBox';
import CustomRadioButtonsGroup from '../../common/radioInputField';
import { useFilter } from '../../hooks';
import { useMachine } from '../../hooks/useMachine';
import { useStyles } from './styles';
import { type updateMachineStatusRequestPayload } from '../../redux/machine/types';
interface AddOperatorProps {
  view: boolean
  setShowDialog: (e: any) => void
}

const MachinStatus: React.FC<AddOperatorProps> = ({
  view,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const { machines, machineBreakdownReason, fetchMachines, fetchOrderStatus } = useFilter();
  const {
    fetchUpdateMachineStatus,
    breakdownReasonId,
    selectedBreakdownMachine,
    alternateMachineId,
    fetchSetMachineStates
  } = useMachine();

  // states

  // effects
  useEffect(() => {
    fetchOrderStatus({ type: 'SPRAY_MACHINE_BREAKDOWN_REASON' });
    fetchMachines(selectedBreakdownMachine?.sdoId
      ? {
          sdoIds: selectedBreakdownMachine?.sdoId ? [selectedBreakdownMachine?.sdoId] : [],
          clusterIds: [],
          machineTypeId: selectedBreakdownMachine?.machineTypeId
        }
      : {
          sdoIds: [],
          clusterIds: selectedBreakdownMachine?.clusterId ? [selectedBreakdownMachine?.clusterId] : [],
          machineTypeId: selectedBreakdownMachine?.machineTypeId
        });
  }, []);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    fetchSetMachineStates({ [inputEvent?.name]: inputEvent?.value });
  };

  const onClickResetHandler = () => {
    setShowDialog(false);
  };

  const isAddOperatorDisable = () => {
    return !(alternateMachineId && breakdownReasonId);
  };

  const updateBreakdownMachineStatusHandler = () => {
    const requestBody: updateMachineStatusRequestPayload = {
      alternateMachineId,
      breakdownReasonId,
      machineId: selectedBreakdownMachine?.machineId,
      machineStatus: 'BREAKDOWN'

    };
    fetchUpdateMachineStatus(requestBody);
  };

  return (
    <>
      {view
        ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '25%' }}
        >
          <CircularProgress></CircularProgress>
        </div>
          )
        : (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box className={classes.inputElementBox}>
                    <CustomRadioButtonsGroup
                      label="Select Reason"
                      value={breakdownReasonId}
                      onChange={onChangeHandler}
                      view={view}
                      name="breakdownReasonId"
                      options={machineBreakdownReason}
                      optionLabel="value"
                      optionValue="key"
                      required
                    />
                  </Box>
                </div>
          </div>

          <div className={classes.locationContainer}>
            <div>
              <div className={classes.alternateLabel}>Assign alternate machine ID</div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox} style={{ flex: 2 }}>
                  <CustomSelectBox
                    label="Machine Id"
                    placeholder="Select"
                    view={view}
                    value={ alternateMachineId }
                    onChange={onChangeHandler}
                    name="alternateMachineId"
                    options={machines ? machines?.[0]?.keyValueDto?.filter((op: any) => { return op?.machineId !== selectedBreakdownMachine?.machineId; }) : []}
                    optionLabel="value"
                    optionValue="machineId"
                    required
                  />
                </Box>
                <Box style={{ flex: 1 }}> </Box>
              </div>
            </div>

            <div className={classes.buttonContainer}>
              <CustomButton
                label="Cancel"
                onClick={onClickResetHandler}
                customStyle={classes.resetButton}
              ></CustomButton>
              <CustomButton
                label={'Next'}
                disabled={isAddOperatorDisable()}
                onClick={updateBreakdownMachineStatusHandler}
                customStyle={classes.applyButton}
              ></CustomButton>
            </div>
          </div>
        </>
          )}
    </>
  );
};

export default MachinStatus;
