export const GET_MACHINE_LIST = 'GET_MACHINE_LIST';
export const GET_MACHINE_LIST_SUCCESS = 'GET_MACHINE_LIST_SUCCESS';
export const GET_MACHINE_LIST_FAILED = 'GET_MACHINE_LIST_FAILED';

export const REASSIGN_ORDER_LIST = 'REASSIGN_ORDER_LIST';
export const REASSIGN_ORDER_LIST_SUCCESS = 'REASSIGN_ORDER_LIST_SUCCESS';
export const REASSIGN_ORDER_LIST_FAILED = 'REASSIGN_ORDER_LIST_FAILED';

export const RESET_REASSIGN_STATES = 'RESET_REASSIGN_STATES';

export const SET_REASSIGN_STATES = 'SET_REASSIGN_STATES';
