import {
  LOGOUT_USER,
  RESET_OTP_SENT,
  SEND_OTP,
  SEND_OTP_FAILED,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_SUCCESS
} from './actionType';
import {
  type ResetOtpSent,
  type SendOtpRequest,
  type SendOtpRequestFailed,
  type SendOtpRequestFailedPayload,
  type SendOtpRequestPayload,
  type SendOtpRequestSuccess,
  type SendOtpRequestSuccessPayload,
  type VerifyOtpRequestPayload,
  type VerifyOtpRequestSuccessPayload,
  type VerifyOtpRequestFailedPayload,
  type VerifyOtpRequest,
  type VerifyOtpRequestSuccess,
  type VerifyOtpRequestFailed
} from './types';

export const requestOtp = (payload: SendOtpRequestPayload): SendOtpRequest => {
  return {
    type: SEND_OTP,
    payload
  };
};

export const requestOtpSuccess = (payload: SendOtpRequestSuccessPayload): SendOtpRequestSuccess => {
  return {
    type: SEND_OTP_SUCCESS,
    payload
  };
};

export const requestOtpFailed = (payload: SendOtpRequestFailedPayload): SendOtpRequestFailed => {
  return {
    type: SEND_OTP_FAILED,
    payload
  };
};

export const requestVerifyOtp = (payload: VerifyOtpRequestPayload): VerifyOtpRequest => {
  return {
    type: VERIFY_OTP,
    payload
  };
};

export const requestVerifyOtpSuccess = (payload: VerifyOtpRequestSuccessPayload): VerifyOtpRequestSuccess => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload
  };
};

export const requestVerifyOtpFailed = (payload: VerifyOtpRequestFailedPayload): VerifyOtpRequestFailed => {
  return {
    type: VERIFY_OTP_FAILED,
    payload
  };
};

export const resetOtpSent = (payload: SendOtpRequestSuccessPayload): ResetOtpSent => {
  return {
    type: RESET_OTP_SENT,
    payload
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};
