import React, { useEffect } from 'react';
import { Switch, CircularProgress } from '@mui/material';
import DataTable, { type HeaderType } from '../../common/datatable';
import CustomButton from '../../common/button';
import { useStyles } from './styles';
// import Sidebar from '../sidebar';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
// import CheckBox from "../../common/Checkbox";
import CustomPagination from '../../common/pagination';
import RowsPerPage from '../../common/rowsPerPage';
// import Header from '../header'
import Filter from '../filter';
import { useNavigate } from 'react-router-dom';
import {
  OTP_LOCALSTORAGE_KEY,
  ROLES
} from '../../constants';
import { useFilter } from '../../hooks/useFilter';
import moment from 'moment';
import { useLogin } from '../../hooks';

const FILE_REPORT_NAME = `Spray Service Report_${moment(new Date()).format('DDMMYYYY')}`;

// interface HeaderType {
//   label: string
//   key: string
//   enableSort: boolean
// }

// export interface StickyHeaderType extends HeaderType {
//   width: string
// }

const StickyHeaders: HeaderType[] = [
  { label: 'State', key: 'state', width: '120px', enableSort: true },
  { label: 'Cluster', key: 'cluster', width: '120px', enableSort: false },
  { label: 'Sdo', key: 'sdo', width: '120px', enableSort: false },
  { label: 'Operator', key: 'operatorName', width: '120px', enableSort: false }
];

const HeaderData: HeaderType[] = [
  { label: 'Status', key: 'bookingStatus', enableSort: false },
  { label: 'Order ID', key: 'orderId', enableSort: false },
  { label: 'Booking Date', key: 'bookingDate', enableSort: false },
  { label: 'Acre', key: 'sprayArea', enableSort: true },
  { label: 'Cost', key: 'serviceCharge', enableSort: true },
  { label: 'Spray Date', key: 'sprayDate', enableSort: false },
  { label: 'Spray Type', key: 'serviceType', enableSort: false },
  { label: 'Machine ID', key: 'machineName', enableSort: false },
  { label: 'Product Name', key: 'productName', enableSort: false },
  { label: 'Farmer Name', key: 'farmerName', enableSort: false },
  { label: 'Mobile', key: 'mobileNo', enableSort: false },
  { label: 'Farmer Details', key: 'farmAddress', enableSort: false }
];

const Dashboard: React.FC = () => {
  // classes
  const classes = useStyles();

  const { userProfile: { userRole } } = useLogin();

  if (userRole === ROLES.VENDOR_ADMIN) {
    const productNameIndex: number = HeaderData.findIndex((header: HeaderType) => header.key === 'productName');
    if (productNameIndex > -1) {
      HeaderData.splice(productNameIndex, 1);
    }
  }

  const {
    orderDetails: {
      totalPages,
      serviceOrdersReportDTO,
      totalElements
    },
    allOrderDetails,
    filterRequest,
    filterRequest: { redFlag, pageNo, pageSize },
    setFilterRequest, fetchOrderDetails,
    fetchResetFilterStates, isExcelDownloading
  } = useFilter();

  // const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  // hooks
  const navigate = useNavigate();

  // effects
  useEffect(() => {
    if (localStorage.getItem(OTP_LOCALSTORAGE_KEY)) {
      localStorage.removeItem('timestamp');
    }
    navigate('/', { replace: true });
  }, []);

  useEffect(() => {
    if (allOrderDetails?.totalElements && allOrderDetails?.totalElements === allOrderDetails?.serviceOrdersReportDTO?.length) { downloadReport(); }
  }, [allOrderDetails]);

  useEffect(() => {
    fetchOrderDetails(filterRequest);
  }, [redFlag, pageNo, pageSize]);

  const exportToExcel = () => {
    setFilterRequest({ ...filterRequest, isOrderDetailsFlag: true });
    fetchOrderDetails({ ...filterRequest, pageSize: totalElements, pageNo: 0 });
  };

  const camelToFlat = (camel: string) => {
    const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

    let flat = '';

    camelCase.forEach((word: string) => {
      flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' ';
    });
    return flat.trim();
  };

  const downloadReport = () => {
    setFilterRequest({ ...filterRequest, isOrderDetailsFlag: false });
    fetchResetFilterStates('allOrderDetails');
    const fileType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtention: string = '.xlsx';
    const fileName: string = FILE_REPORT_NAME;

    const ws = XLSX.utils.json_to_sheet(allOrderDetails?.serviceOrdersReportDTO);

    const headers = Object.keys(allOrderDetails?.serviceOrdersReportDTO[0]);

    for (let i = 65; i < 65 + headers.length; i++) {
      const cell: string = `${String.fromCharCode(i)}1`;
      ws[cell].s = {
        font: {
          sz: 14
        },
        fill: {
          fgColor: { rgb: 'ffc2c7d0' }
        }
      };
      ws[cell].v = camelToFlat(ws[cell].v);
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelbuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelbuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName}${fileExtention}`);
  };

  const handlePageChange = (pageNo: number) => {
    setFilterRequest({ ...filterRequest, pageNo: pageNo - 1 });
  };

  const redFlagChange = () => {
    setFilterRequest({ ...filterRequest, redFlag: !filterRequest?.redFlag, pageNo: 0 });
  };

  return (
    <div>
      {/* <div style={{ height: '100vh', display: 'flex' }}>
        <Sidebar />
      </div> */}
      <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 'calc(100vh - 50px)' }}>
        {/* <Header />
        <Divider /> */}
        <div style={{ padding: '10px 24px', background: 'lightgray' }}>
          <Filter />
        </div>
        {totalElements > 0 && (
          <>
            <div className={classes.topActionsContainer}>
              <div className={classes.totalElements}>
                <span>Total: {totalElements}</span>
              </div>
              <div className={classes.switchContainer}>
                <Switch
                  checked={filterRequest.redFlag}
                  onChange={redFlagChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <p style={{ marginRight: '8px' }}>Show red flag</p>
                <img src={`${process.env.PUBLIC_URL}/red-flag.png`} style={{ height: '10px', width: '10px' }} alt="red-flag" />
              </div>
            </div>
          </>
        )}
        <DataTable stickyHeaders={StickyHeaders} headers={HeaderData} data={serviceOrdersReportDTO} />
        <div className={classes.actionsContainer}>
          <div>
            <CustomButton label="Export to excel" onClick={() => { exportToExcel(); }} disabled={serviceOrdersReportDTO?.length <= 0} loading={isExcelDownloading} startIcon={isExcelDownloading ? <CircularProgress size={16} color="inherit" /> : null} />
          </div>
          {totalPages > 0 && (
            <div>
              <CustomPagination
                pageNumber={filterRequest.pageNo + 1}
                onChangePageNumber={handlePageChange}
                totalPages={totalPages}
              />
            </div>
          )}
          <div>
            <RowsPerPage rowsPerPage={filterRequest?.pageSize} setRowsPerPage={(e: number) => { setFilterRequest({ ...filterRequest, pageSize: e }); }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
