import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  sidebar: {
    backgroundColor: COLORS.Navy,
    width: '80px'
  },
  contents: {
    width: '100%'
  },
  actionsContainer: {
    display: 'flex',
    margin: '8px 24px',
    justifyContent: 'space-between'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '74px'
  },
  menuIcon: { height: '16px', width: '16px' },
  menuLabel: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600
  },
  topActionsContainer: {
    background: `${COLORS.TableBackgroundGray}`,
    height: '32px',
    display: 'flex',
    margin: '8px 24px 0px 24px'
  },
  totalElements: {
    padding: '8px'
  },
  switchContainer: {
    marginLeft: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px'
  }
})
);
