import { apiUrls } from '../api/apiUrls';
import { getApiRequest, postApiRequest } from '../api/api';
import { type SendOtpRequestPayload, type VerifyOtpRequestPayload, type VerifyOtpRequestSuccessPayload } from '../redux/login/types';
import { type AxiosError } from 'axios';
import { PHONE_COUNTRY_CODE_IN } from '../constants';

export const getOtp = async (body: SendOtpRequestPayload) => {
  try {
    const { mobile } = body;
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.SEND_OTP_URL}${PHONE_COUNTRY_CODE_IN}${mobile}?channel=web`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const verifyOtp = async (body: VerifyOtpRequestPayload) => {
  try {
    const url: string = `${apiUrls.VERIFY_OTP_URL}91${body.mobile}`;
    const response: VerifyOtpRequestSuccessPayload = await postApiRequest(url, {
      identifier: body.identifier
    });
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
