import { apiUrls } from '../api/apiUrls';
import { getApiRequest } from '../api/api';
import { type AxiosError } from 'axios';
import moment from 'moment';
import { type getOrderStatusRequestPayload, type getClustorRequestPayload, type getMachineRequestPayload, type getOrderDetailsRequestPayload, type getSDORequestPayload, type getDistrictRequestPayload, type getOperatorListRequestPayload, type getGeoHierarchyDistrictsRequestPayload } from '../redux/filter/types';

export const getAllStatesService = async () => {
  try {
    // const { mobile } = body
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_STATES_URL}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getOrderStatusService = async (body: getOrderStatusRequestPayload) => {
  try {
    const { type } = body;
    // SPRAY_BOOKING_STATUS
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_ORDER_STATUS_URL}?type=${type}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getClustorsService = async (body: getClustorRequestPayload) => {
  try {
    const { statesIds } = body;
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_CLUSTORS_URL}?stateIds=${statesIds}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getOperatorListService = async (body: getOperatorListRequestPayload) => {
  try {
    const { sdoId, clusterId } = body;
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_OPERATOR_LIST_URL}?sdoId=${sdoId}&clusterId=${clusterId}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getSDOService = async (body: getSDORequestPayload) => {
  try {
    const { clusterIds } = body;
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_SDO_URL}?clusterIds=${String(clusterIds)}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getMachineService = async (body: getMachineRequestPayload) => {
  try {
    const { sdoIds, clusterIds, machineTypeId } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_MACHINE_URL}?sdoIds=${String(sdoIds)}&clusterIds=${String(clusterIds)}&machineTypeId=${machineTypeId || ''}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getDistrictService = async (body: getDistrictRequestPayload) => {
  try {
    const { clusterIds } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_DISTRICT_URL}?clusteIds=${String(clusterIds)}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getGeoHierarchyDistrictService = async (body: getGeoHierarchyDistrictsRequestPayload) => {
  try {
    const { stateId } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_GEO_HIERARCHY_DISTRICT_URL}?stateId=${String(stateId)}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getOrderDetailsService = async (body: getOrderDetailsRequestPayload) => {
  try {
    let queryString: string = '';
    const payload: any = body;
    ['pageNo', 'pageSize', 'redFlag'].map((param: string) => {
      queryString += !queryString ? `?${param}=${payload[param]}` : `&${param}=${payload[param]}`;
    });

    ['startDate', 'endDate'].map((param: string) => {
      if (payload[param]) { queryString += `&${param}=${String(moment(payload[param]).format('DD-MM-YYYY'))}`; }
    });

    ['statusIds', 'statesIds', 'clusterIds', 'sdoIds', 'machineIds', 'sprayTypeIds'].map((param: string) => {
      if (payload[param] && payload[param]?.length > 0) { queryString += `&${param}=${payload[param].join(',')}`; }
    });

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_ORDER_DETAILS_URL}${queryString}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
