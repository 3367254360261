import { useSelector, useDispatch } from 'react-redux';
import {
  getUserListError,
  getUserListLoading,
  getUserList,
  addUserSucess,
  getUser,
  addUserLoading,
  addUserErrorMessage
} from '../redux/onBoard/selectors';
import { type getUserRequestPayload, type updateUserRequestPayload, type addUserRequestPayload, type getUserListRequestPayload, type OnBoardState, type userItemType, type getUserListSucessPayloadType } from '../redux/onBoard/types';
import { getUserListRequest as getUserListAction, getUserRequest as getUserAction, addUserRequest as addUserAction, updateUserRequest as updateUserAction, resetOnboardStates as resetOnboardStatesAction } from '../redux/onBoard/action';

interface OnBoardType extends OnBoardState {
  fetchUserList: (action: getUserListRequestPayload) => void
  addUser: (action: addUserRequestPayload) => void
  updateUser: (action: updateUserRequestPayload) => void
  fetchUser: (action: getUserRequestPayload) => void
  fetchResetOnboardStates: (action: keyof OnBoardState) => void
}

export const useOnBoard = (): OnBoardType => {
  const dispatch = useDispatch();
  const isUserListLoading: boolean = useSelector(getUserListLoading);
  const isLoading: boolean = useSelector(addUserLoading);
  const error: boolean = useSelector(getUserListError);
  const userList: getUserListSucessPayloadType = useSelector(getUserList);
  const message: string = useSelector(addUserSucess);
  const errorMessage: string = useSelector(addUserErrorMessage);
  const user: userItemType = useSelector(getUser);

  const fetchUserList = (action: getUserListRequestPayload) => {
    dispatch(getUserListAction(action));
  };

  const addUser = (action: addUserRequestPayload) => {
    dispatch(addUserAction(action));
  };

  const updateUser = (action: updateUserRequestPayload) => {
    dispatch(updateUserAction(action));
  };

  const fetchUser = (action: getUserRequestPayload) => {
    dispatch(getUserAction(action));
  };

  const fetchResetOnboardStates = (action: keyof OnBoardState) => {
    dispatch(resetOnboardStatesAction(action));
  };

  return {
    error,
    isLoading,
    isUserListLoading,
    userList,
    message,
    errorMessage,
    user,
    fetchUserList,
    addUser,
    fetchUser,
    updateUser,
    fetchResetOnboardStates
  };
};
