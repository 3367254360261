import React, { useState } from 'react';
import { Divider } from '@mui/material';
import { useStyles } from './styles';
import Sidebar from '../sidebar';
import Header from '../header';
import OnBoard from '../onBoard';
import Dashboard from '../dashboard';
import ReAssign from '../reAssign';
// import ComingSoon from '../../common/comingSoon';
import Machine from '../machine';
import Cluster from '../cluster';
import HomeDashboard from '../homeDashboard';

const Home: React.FC = () => {
  // classes
  const classes = useStyles();

  const [selectedMenu, setSelectedMenu] = useState<string>('Home');

  const handleChangeMenu = (menu: string) => {
    setSelectedMenu(menu);
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <Sidebar selectedMenu={selectedMenu} onChangeManu={handleChangeMenu} />
      </div>
      <div style={{ width: 'calc(100% - 80px)' }}>
        <Header />
        <Divider />

        {selectedMenu === 'Reports' && (
          <Dashboard />
        )}
        {selectedMenu === 'Onboard' && (
          <OnBoard />
        )}
        {selectedMenu === 'Re-assign' && (
          <ReAssign />
        )}
         {selectedMenu === 'Machine' && (
          <Machine />
         )}
         {selectedMenu === 'Cluster' && (
          <Cluster/>
         )}
        {selectedMenu === 'Home' && (
          <HomeDashboard/>
        )}
      </div>
    </div>
  );
};

export default Home;
