import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#00004B !important',
      color: '#FFFFFF'
    },
    '& .MuiPagination-ul': {
      justifyContent: 'center'
    }
  }
});
