import { Box, type SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import DataTable, { type HeaderType } from '../../common/datatable';
import CustomizedDialog from '../../common/dialog';
import CustomPagination from '../../common/pagination';
import RowsPerPage from '../../common/rowsPerPage';
import SearchInput from '../../common/searchInput';
import ToastMessage from '../../common/toastMessage';
import ToggleSwitch from '../../common/toggleSwitch';
import { useMachine } from '../../hooks/useMachine';
import { type updateMachineStatusRequestPayload } from '../../redux/machine/types';
import AddMachine from '../addMachine';
import MachinStatus from '../machineStatus';
import { useStyles } from './styles';

const Machine: React.FC = () => {
  // classes
  const classes = useStyles();

  // table headers
  const machineHeader: HeaderType[] = [
    {
      label: 'Machine ID',
      key: 'machineId',
      enableSort: false
    },
    {
      label: 'Breakdown status',
      key: '',
      enableSort: false,
      actions: (index: any) => renderBreakdownStatusButton(index)
    },
    {
      label: 'status',
      key: '',
      enableSort: false,
      actions: (index: any) => renderStatusButton(index)
    },
    {
      label: 'Type',
      key: 'machineType',
      enableSort: false,
      width: '80px'
    },
    {
      label: 'State',
      key: 'stateName',
      enableSort: false
    },
    {
      label: 'Cluster',
      key: 'clusterName',
      enableSort: false
    },
    {
      label: 'SDO',
      key: 'sdoName',
      enableSort: false
    },
    {
      label: 'Operators',
      key: '',
      enableSort: false,
      actions: (row: any) => renderArrayType(row, 'operators')
    },
    {
      label: 'Pincode',
      key: '',
      enableSort: false,
      actions: (row: any) => renderPincodes(row, 'pincodes')
    },
    {
      label: 'Action',
      key: '',
      enableSort: false,
      actions: (index: any) => renderAction(index)
    }
  ];

  const {
    pageNo,
    pageSize,
    machineDetails,
    totalPages,
    searchText,
    errorMessage,
    machineList,
    isMachineListLoading,
    message,
    selectedBreakdownMachine,
    fetchUpdateMachineStatus,
    fetchSetMachineStates,
    fetchGetMachineByStatusList,
    fetchResetMachineStates
  } = useMachine();

  // states
  const [view, setView] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showAddMachineDialog, setShowAddMachineDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  // effects
  useEffect(() => {
    fetchResetMachineStates('message');
    fetchResetMachineStates('statusList');
    fetchSetMachineStates({ searchText: '' });
    return () => {
      fetchResetMachineStates('pageNo');
      fetchResetMachineStates('pageSize');
      fetchResetMachineStates('searchText');
    };
  }, []);

  useEffect(() => {
    fetchGetMachineByStatusList({ clusterIds: [], sdoIds: [], isBreakdown: true, pgno: pageNo, pgsize: pageSize, searchKey: searchText, status: [] });
  }, [pageNo, pageSize]);

  useEffect(() => {
    if (message) {
      fetchGetMachineByStatusList({ clusterIds: [], sdoIds: [], isBreakdown: true, pgno: 0, pgsize: 10, searchKey: searchText, status: [] });
      setShowDialog(false);
      setShowAddMachineDialog(false);
      setShowConfirmationDialog(false);
      setTimeout(() => {
        fetchSetMachineStates({ message: '' });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      fetchSetMachineStates({ errorMessage: '' });
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    fetchSetMachineStates({ alternateMachineId: '', breakdownReasonId: '' });
  }, [showDialog, showConfirmationDialog]);

  const handleMachineSearchChange = (eventVal: any) => {
    fetchGetMachineByStatusList({ clusterIds: [], sdoIds: [], isBreakdown: true, pgno: 0, pgsize: pageSize, searchKey: eventVal, status: [] });
    fetchSetMachineStates({
      pageNo: 0,
      searchText: eventVal?.toLowerCase()
    });
  };

  const handlePageChange = (pageNo: number) => {
    fetchSetMachineStates({ pageNo: pageNo - 1 });
  };

  const onStatusChangeHandler = (event: React.ChangeEvent | SelectChangeEvent, selectedMachineRow: any, isBreakdown: boolean) => {
    const inputEvent = event.target as HTMLInputElement;
    if (selectedMachineRow?.operators?.includes('NA') && selectedMachineRow?.machineStatus === 'ACTIVE' && isBreakdown) {
      fetchSetMachineStates({ message: 'Please assign operator to this machine' });
    } else {
      fetchSetMachineStates({ selectedBreakdownMachine: selectedMachineRow });
      // if ((inputEvent?.checked || selectedMachineRow?.machineStatus === 'INACTIVE') && isBreakdown) {
      //   setShowConfirmationDialog(true);
      // } else if ((inputEvent?.checked || selectedMachineRow?.machineStatus === 'INACTIVE') && !isBreakdown) {
      //   setShowConfirmationDialog(true);
      // } else {
      //   setShowDialog(true);
      // }
      if ((inputEvent?.checked && isBreakdown) || ((!inputEvent?.checked || inputEvent?.checked) && !isBreakdown)) {
        setShowConfirmationDialog(true);
      } else {
        setShowDialog(true);
      }
    }
  };

  const renderArrayType = (row: any, key: string) => {
    return (
      <span>
        {row?.[key]?.map((ob: any) => ob?.value ? ob?.value : ob)?.toString()}
      </span>
    );
  };

  const renderPincodes = (row: any, key: string) => {
    return (
      <span className={classes.pincodesContainer}>
        {row?.[key]?.map((ob: any) => ob?.value ? ob?.value : ob)?.toString()}
      </span>
    );
  };

  const renderAction = (row: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          style={{ cursor: 'pointer' }}
          src={`${process.env.PUBLIC_URL}/view.png`}
          onClick={() => {
            onViewUserClickHandler(row);
          }}
        />
      </div>
    );
  };

  const onViewUserClickHandler = (data: any) => {
    const { id: operatorId, value: operatorName } = data?.operators?.[0] || {};
    fetchSetMachineStates({ machineDetails: { ...data, operatorId, operatorName } });
    setView(true);
    setShowDialog(false);
    setShowAddMachineDialog(true);
  };

  const renderBreakdownStatusButton = (row: any) => {
    const rightLabel: string = row?.machineStatus === 'ACTIVE' || row?.machineStatus === 'INACTIVE' ? 'Live' : `${row?.machineStatus?.charAt(0)}${row?.machineStatus?.slice(1)?.toLowerCase()}`;
    return (
      <>
        {<ToggleSwitch label='breakdown' onChange={(e) => { onStatusChangeHandler(e, row, true); }} leftLabel='' rightLabel={rightLabel} value={row?.machineStatus === 'ACTIVE' || row?.machineStatus === 'INACTIVE'} ></ToggleSwitch>}
        </>
    );
  };
  const renderStatusButton = (row: any) => {
    const rightLabel: string = row?.machineStatus === 'BREAKDOWN' ? 'InActive' : `${row?.machineStatus?.charAt(0)}${row?.machineStatus?.slice(1)?.toLowerCase()}`;
    return (
        <>
        {<ToggleSwitch label='breakdown' onChange={(e) => { onStatusChangeHandler(e, row, false); }} leftLabel='' rightLabel={rightLabel} value={row?.machineStatus === 'ACTIVE'} ></ToggleSwitch>}
        </>
    );
  };

  const updateBreakdownMachineStatusHandler = () => {
    const requestBody: updateMachineStatusRequestPayload = {
      alternateMachineId: '',
      breakdownReasonId: '',
      machineId: selectedBreakdownMachine?.machineId,
      machineStatus: selectedBreakdownMachine?.machineStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'

    };
    fetchUpdateMachineStatus(requestBody);
  };

  return (
    <div className={classes.onBoardContainer}>
      <>
            <Box className={classes.inputContainer}>
              <CustomButton
                label="Add Machine"
                onClick={() => {
                  setView(false);
                  setShowAddMachineDialog(!showAddMachineDialog);
                  fetchResetMachineStates('machineDetails');
                }}
                customStyle={classes.addButton}
                startIcon={
                  <img src={`${process.env.PUBLIC_URL}/add.png`} alt="add" />
                }
              />
              <Box></Box>
              <Box style={{ width: '25%', border: '1px solid #C2C7D0' }}>
                <SearchInput
                  onSearch={handleMachineSearchChange}
                  placeholder="Search Machine ID / Operator"
                  searchText={searchText}
                  customStyle={classes.searchInput}
                ></SearchInput>
              </Box>
            </Box>

        <Box className={classes.customTableContainer}>
          <DataTable
            data={machineList}
            headers={machineHeader}
            stickyHeaders={[]}
            loading={isMachineListLoading}
          ></DataTable>
        </Box>
        <CustomizedDialog
          showDialog={showDialog}
          setShowDialog={() => {
            setShowDialog(!showDialog);
          }}
          title={'Machine Status'}
        >
          <MachinStatus
            view={false}
            setShowDialog={(e: any) => {
              setShowDialog(e);
            }}
          ></MachinStatus>
        </CustomizedDialog>
        <CustomizedDialog
          customStyle={classes.customizedDialog}
          showDialog={showAddMachineDialog}
          setShowDialog={() => {
            setShowAddMachineDialog(!showAddMachineDialog);
          }}
          title={view || machineDetails?.machineId ? 'Edit Machine' : 'Add Machine'}
        >
          <AddMachine
            view={view}
            setView={(e) => {
              setView(e);
            }}
            setShowDialog={(e: any) => {
              setShowDialog(e);
            }}
          ></AddMachine>
        </CustomizedDialog>
        <CustomizedDialog
            showDialog={showConfirmationDialog}
            setShowDialog={() => {
              setShowConfirmationDialog(!showConfirmationDialog);
            }}
            title={'Confirm Activation'}
          >
            <div className={classes.confirmcontainer}>
              <div className={classes.confirmLabelText}>
                {`Confirm mark the machine ${selectedBreakdownMachine?.machineStatus === 'ACTIVE' ? 'Inactive' : 'Active'}?`}
              </div>
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowConfirmationDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Confirm"
                  onClick={updateBreakdownMachineStatusHandler}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
            </div>
          </CustomizedDialog>
      </>
      <Box style={{ display: 'flex', marginTop: '8px' }}>
        <Box
          style={{
            flex: 1
          }}
        >
          {totalPages > 0 && !isMachineListLoading && (
              <CustomPagination
                onChangePageNumber={handlePageChange}
                pageNumber={Number(pageNo) + 1}
                totalPages={totalPages}
              />
          )}
        </Box>
        <Box style={{ position: 'absolute', right: 0, marginRight: '16px' }}>
          { totalPages > 0 && !isMachineListLoading && <RowsPerPage
            rowsPerPage={pageSize}
            setRowsPerPage={(e: number) => {
              // setRowsPerPage(e)
              fetchSetMachineStates({ pageSize: e, pageNo: 0 });
            }}
          ></RowsPerPage>}
        </Box>
      </Box>
      {message && (
        <ToastMessage message={message?.toString()} errorType="success"></ToastMessage>
      )}
      {errorMessage && (
        <ToastMessage message={errorMessage?.toString()} errorType="error"></ToastMessage>
      )}
    </div>
  );
};

export default Machine;
