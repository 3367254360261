import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

interface BookingStatusColorTypes {
  background: string
  border: string
}

export const useStyles = makeStyles({
  tableContainerHelper: {
    backgroundColor: 'white',
    border: '1px solid #CFD3DB',
    marginInline: 'auto',
    minHeight: 'calc(100vh - 240px) !important',
    maxHeight: 'calc(100vh - 240px) !important',
    // minHeight: 'calc(100vh - 260px) !important',
    // maxHeight: 'calc(100vh - 260px) !important',
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid #E8EAED',
      border: '1px solid #C2C7D0',
      borderRadius: '10px'
    },
    overflowY: 'auto',
    '&.MuiTableContainer-root': {
      width: 'calc(100% - 48px)',
      overflowX: 'revert'
    },
    '& .MuiTableRow-root': {
      height: '8px '
    },
    '& .MuiTableCell-root': {
      lineHeight: '6px '
    },
    '& .MuiTableCell-head': {
      ...fontFamilies.regular1616,
      textTransform: 'uppercase',
      borderRight: `1px solid ${COLORS.TableCellBorder}`,
      padding: '0px '
    },
    '& .css-1ps4owl-MuiTypography-root-MuiLink-root': {
      cursor: 'pointer'
    }
  },
  dataLoading: {
    margin: '40px 24px 0px 24px '
  },
  tabelCell: {
    borderRight: `1px solid ${COLORS.TableCellBorder}`,
    whiteSpace: 'nowrap',
    padding: '8px'
  },
  tabelCellContents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'start',
    padding: '0px 8px '
  },
  tableCellHeader: {
    background: `${COLORS.TableHeaderGray} !important`
  },
  switchHelper: {
    '& .MuiSwitch-thumb': {
      position: 'absolute',
      left: '17px ',
      top: '16px ',
      background: COLORS.SwitchGray,
      height: '10px',
      width: '10px'
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: COLORS.White,
      border: `2px solid ${COLORS.SwitchGray} `,
      borderRadius: '16px '
    }
  },
  progressHelper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'inherit',
    height: 'calc(100vh - 220px) '
  },
  bookingStatus: (props: BookingStatusColorTypes) => ({
    ...fontFamilies.semiBold1420,
    padding: '4px ',
    borderRadius: '5px ',
    background: `${props.background} `,
    color: props.border,
    border: `1px solid ${props.border}`
  }),
  sortIcon: {
    height: 20
  },
  sortIconDescending: {
    height: 20,
    rotate: '180deg'
  },
  displayCenter: {
    display: 'flex'
  },
  checkboxTabelCell: {
    // display: 'flex',
    // justifyContent: 'center',
    textAlign: 'center',
    borderRight: `1px solid ${COLORS.TableCellBorder}`,
    whiteSpace: 'nowrap',
    padding: '0px'
  },
  stickyCellData: {
    lineHeight: 'initial',
    textAlign: 'start',
    whiteSpace: 'pre-wrap'
  }
});
