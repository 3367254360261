import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useStyles } from './styles';

interface CustomRadioButtonsGroupProps {
  label?: string
  view?: boolean
  value?: string
  name?: string
  options: any
  required?: boolean
  optionLabel: string
  optionValue: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomRadioButtonsGroup: React.FC<CustomRadioButtonsGroupProps> = ({
  label,
  view = false,
  name,
  value,
  required = false,
  options = [],
  optionLabel = '',
  optionValue = '',
  onChange
}) => {
  const classes = useStyles();

  return (
    <FormControl>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        className={view ? classes.viewLabel : classes.label}
      >
        {label}{required && '*'}
      </FormLabel>
      {view
        ? (
        <div className={classes.valueLabel}>{value}</div>
          )
        : (
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {options && options.map((option: any) => <FormControlLabel
            key={`raadio_${option[optionValue]}`}
            control={<Radio value={option[optionValue]}
            name={name}
            checked={option[optionValue] === value}
            onChange={onChange} />}
            className={classes.optionLabel}
            label={option[optionLabel]}
          />)}
          {/* <FormControlLabel
            value="male"
            control={<Radio />}
            className={classes.optionLabel}
            label="Male"
          /> */}
        </RadioGroup>
          )}
    </FormControl>
  );
};

export default CustomRadioButtonsGroup;
