import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle (props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose
        ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
          )
        : null}
    </DialogTitle>
  );
}

interface CustomizedDialogProps {
  title?: string
  customStyle?: any
  showDialog: boolean
  setShowDialog: () => void
  children: React.ReactNode
}

const CustomizedDialog: React.FC<CustomizedDialogProps> = ({ showDialog, setShowDialog, children, title, customStyle }) => {
  const classes = useStyles();

  return (
    <div>
      <BootstrapDialog
       // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        className= {[classes.dialog, customStyle].join(' ')}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={setShowDialog} >
          {title}
        </BootstrapDialogTitle>
        {children}
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialog;
