import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  sidebar: {
    backgroundColor: '#00004B',
    width: '80px'
  },
  contents: {
    width: '100%'
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'baseline',
    justifyContent: 'space-between',
    height: '30px',
    // marginTop:'8px',
    marginLeft: '50px'
  },
  applyButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.White}`,
    background: '#19A04B !important',
    borderRadius: '4px'
  },
  resetButton: {
    ...fontFamilies.regular1420,
    // color: `${COLORS.green_50}`,
    background: 'none !important',
    color: 'black',
    backgroundColor: 'white !important',
    // background: 'white',
    // border: '1px dashed #19A04B',
    border: '1px dashed black',
    boxShadow: 'none !important',
    marginRight: '12px',
    borderRadius: '4px',
    '& :hover': {
      background: 'none'
    }
  },
  filterSubContent: {
    paddingRight: '16px',
    width: '-webkit-fill-available'
  }

})
);
