import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  label: {
    ...fontFamilies.semiBold1420
  },
  viewLabel: {
    ...fontFamilies.regular1218,
    wordBreak: 'break-word'
  },
  valueLabel: {
    ...fontFamilies.medium1621
  },
  inputBox: {
    '& .MuiOutlinedInput-notchedOutline': {
      ...fontFamilies.regular1422,
      color: COLORS.Nuetral_20,
      marginTop: '5px !important',
      // height: '50px'
      padding: '10px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
      ...fontFamilies.regular1422
    },
    '& .MuiMenuItem-root': {
      ...fontFamilies.regular1422
    }
  }
}));
