import {
  ADD_CLUSTER,
  ADD_CLUSTER_FAILED,
  ADD_CLUSTER_SUCCESS,
  GET_CLUSTER_LIST,
  GET_CLUSTER_LIST_FAILED,
  GET_CLUSTER_LIST_SUCCESS,
  RESET_CLUSTER_STATES,
  SET_CLUSTER_STATES,
  UPDATE_CLUSTER,
  UPDATE_CLUSTER_FAILED,
  UPDATE_CLUSTER_SUCCESS
} from './actionType';

import { type clusterState, type clusterAction } from './types';

const initialState: clusterState = {
  isClusterListLoading: false,
  error: false,
  searchText: '',
  pageNo: 0,
  pageSize: 10,
  totalPages: 0,
  message: '',
  errorMessage: '',
  clusterListDetails: {},
  clusterDetails: {
    clusterId: '',
    clusterName: '',
    districtList: [],
    stateId: '',
    districts: [],
    districtsIds: [],
    districtsNames: [],
    stateName: ''
  }
};

const defaultState: clusterState = JSON.parse(JSON.stringify(initialState));

export const reducer = (
  state = defaultState,
  action: clusterAction
): clusterState => {
  switch (action.type) {
    case GET_CLUSTER_LIST:
      return { ...state, isClusterListLoading: true, clusterListDetails: {} };
    case GET_CLUSTER_LIST_SUCCESS:
      return {
        ...state,
        isClusterListLoading: false,
        clusterListDetails: action.payload,
        pageNo: action?.payload?.pageNo,
        pageSize: action?.payload?.pageSize,
        totalPages: action?.payload?.totalPages
      };
    case GET_CLUSTER_LIST_FAILED:
      return { ...state, error: true, isClusterListLoading: false, errorMessage: action.payload?.errorMessage || '', pageNo: 0, pageSize: 10, totalPages: 0 };

    case ADD_CLUSTER:
      return { ...state };
    case ADD_CLUSTER_SUCCESS:
      return {
        ...state,
        message: action.payload?.message
      };
    case ADD_CLUSTER_FAILED:
      return { ...state, error: true, errorMessage: action.payload?.errorMessage || '' };

    case UPDATE_CLUSTER:
      return { ...state };
    case UPDATE_CLUSTER_SUCCESS:
      return {
        ...state,
        message: action.payload?.message
      };
    case UPDATE_CLUSTER_FAILED:
      return { ...state, error: true, errorMessage: action.payload?.errorMessage || '' };

    case RESET_CLUSTER_STATES:
      return { ...state, [action?.payload]: initialState[action?.payload] };

    case SET_CLUSTER_STATES:
      return { ...state, ...action?.payload };

    default:
      return state;
  }
};

export default reducer;
