import { all, call, put, takeLatest } from 'redux-saga/effects';
import { addClusterService, getClusterListService, updateClusterService } from '../../services/cluster';
import { addClusterFailed, addClusterSuccess, getClusterListFailed, getClusterListSuccess, updateClusterFailed, updateClusterSuccess } from './action';
import { ADD_CLUSTER, GET_CLUSTER_LIST, UPDATE_CLUSTER } from './actionType';
import { type getClusterListSucessPayloadType, type getClusterListRequestType, type addClusterRequestType, type updateClusterRequestType } from './types';

function * getClusterList (action: getClusterListRequestType) {
  try {
    const response: getClusterListSucessPayloadType = yield call(getClusterListService, action?.payload);
    yield put(getClusterListSuccess(response));
  } catch (error: any) {
    const { message } = error;
    yield put(getClusterListFailed({ error: true, errorMessage: message }));
  }
}

function * addCluster (action: addClusterRequestType) {
  try {
    const response: string = yield call(addClusterService, action?.payload);
    yield put(addClusterSuccess({ message: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(addClusterFailed({ error: true, errorMessage: message }));
  }
}

function * updateCluster (action: updateClusterRequestType) {
  try {
    const response: string = yield call(updateClusterService, action?.payload);
    yield put(updateClusterSuccess({ message: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(updateClusterFailed({ error: true, errorMessage: message }));
  }
}

function * clusterSaga () {
  yield all([takeLatest(GET_CLUSTER_LIST, getClusterList)]);
  yield all([takeLatest(ADD_CLUSTER, addCluster)]);
  yield all([takeLatest(UPDATE_CLUSTER, updateCluster)]);
}
export default clusterSaga;
