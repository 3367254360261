import { apiUrls } from '../api/apiUrls';
import { getApiRequest, putApiRequest } from '../api/api';
import { type AxiosError } from 'axios';
import { type reAssignOrderListRequestPayload, type getMachineListRequestPayload } from '../redux/reAssign/types';
// import { type getClustorRequestPayload, type getMachineRequestPayload, type getOrderDetailsRequestPayload, type getSDORequestPayload } from '../redux/filter/types'

export const getMachineListService = async (body: getMachineListRequestPayload) => {
  try {
    const { pgno, pgsize, searchkey, stateId, clusterId } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.MACHINE_LIST_URL}?pgno=${pgno}&pgsize=${pgsize}&searchKey=${searchkey}&stateId=${stateId || ''}&clusterId=${clusterId || ''}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const reAssignOrderListService = async (body: reAssignOrderListRequestPayload) => {
  try {
    const response: unknown = await putApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.REASSIGN_ORDER_URL}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
