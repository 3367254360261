import { Box, type SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import CustomSelectBox from '../../common/customSelectBox';
import CustomRadioButtonsGroup from '../../common/radioInputField';
import TextInputField from '../../common/textInputField';
// import ToastMessage from '../../common/toastMessage'
import { RegEx } from '../../constants';
import { useFilter } from '../../hooks';
import { useMachine } from '../../hooks/useMachine';
import { useStyles } from './styles';
import MultiInputPincode from '../../common/multiInputPincode';

interface AddMachineProps {
  view: boolean
  setView: (e: any) => void
  setShowDialog: (e: any) => void
}

const AddMachine: React.FC<AddMachineProps> = ({
  view,
  setView,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const {
    states,
    machineTypes,
    clustors,
    operators,
    fetchAllStates,
    fetchClustors,
    fetchOperators,
    fetchResetFilterStates
  } = useFilter();

  const {
    machineDetails,
    errorMessage,
    statusList,
    fetchResetMachineStates,
    fetchSetMachineStates,
    fetchSaveMachineDetails,
    fetchUpdateMachineDetails
  } = useMachine();
  // file reference

  // states
  const [edit, setEdit] = useState<boolean>(false);

  // effects

  useEffect(() => {
    fetchAllStates();
    fetchResetFilterStates('operators');
  }, []);

  useEffect(() => {
    if (machineDetails?.stateId) {
      // fetchSetMachineStates({ machineDetails: { ...machineDetails, operatorId: '', clusterId: '' } });
      fetchClustors({ statesIds: [machineDetails?.stateId] });
    }
  }, [machineDetails?.stateId]);

  useEffect(() => {
    if (machineDetails?.clusterId) {
      fetchOperators({ clusterId: [machineDetails?.clusterId], sdoId: [] });
    }
  }, [machineDetails?.clusterId]);

  useEffect(() => {
    setTimeout(() => {
      fetchResetMachineStates('errorMessage');
    }, 5000);
  }, [errorMessage]);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent?.name === 'stateId') {
      fetchResetFilterStates('operators');
    }
    fetchSetMachineStates({
      machineDetails: {
        ...machineDetails,
        [inputEvent?.name]: inputEvent?.value
      }
    });
  };

  const onClickResetHandler = () => {
    edit || view ? fetchSetMachineStates({ machineDetails: { machineId: machineDetails?.machineId, machineTypeId: machineDetails?.machineTypeId } }) : fetchResetMachineStates('machineDetails');
  };

  const onClickAddOperatorHandler = () => {
    if (machineDetails) { fetchSaveMachineDetails({ ...machineDetails }); }
    // addUser({ ...UserDetails });
  };

  const onClickUpdateOperatorHandler = () => {
    if (machineDetails) { fetchUpdateMachineDetails({ ...machineDetails, isMachineOnBoardingFlow: true }); }
  };

  const isAddOperatorDisable = () => {
    return !(
      machineDetails?.machineId &&
      machineDetails?.machineTypeId &&
      machineDetails?.stateId &&
      machineDetails?.clusterId &&
      // machineDetails?.operatorIds?.length &&
      machineDetails?.pincodes?.length
    );
  };

  return (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                <TextInputField
                  placeholder="Enter Machine ID"
                  label="New Machine"
                  value={machineDetails?.machineId}
                  onChange={onChangeHandler}
                  view={view || edit}
                  name="machineId"
                  regExPattern={RegEx.Alphnumeric}
                  maxLength={50}
                  required
                />
              </Box>
              <Box className={classes.inputElementBox}>
                <CustomRadioButtonsGroup
                  label="Machine Status"
                  value={
                    view
                      ? machineDetails?.machineStatus
                      : machineDetails?.machineStatus
                  }
                  onChange={onChangeHandler}
                  view={view}
                  name="machineStatus"
                  options={statusList}
                  optionLabel="value"
                  optionValue="key"
                  required={true}
                />
              </Box>
            </div>

            <Box className={classes.inputElementBox}>
                <CustomRadioButtonsGroup
                  label="Machine Type"
                  value={
                    view || edit
                      ? machineDetails?.machineType
                      : machineDetails?.machineTypeId
                  }
                  onChange={onChangeHandler}
                  view={view || edit}
                  name="machineTypeId"
                  options={machineTypes}
                  optionLabel="value"
                  optionValue="key"
                  required={true}
                />
              </Box>
          </div>
          <div className={classes.locationContainer}>
            <h3 className={classes.locationTitle}>Location Details</h3>
            <div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="State"
                    placeholder="Select"
                    view={view}
                    value={view ? machineDetails?.stateName : machineDetails?.stateId}
                    onChange={onChangeHandler}
                    name="stateId"
                    options={states}
                    optionLabel="value"
                    optionValue="id"
                    required
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="Cluster"
                    placeholder="Select"
                    view={view}
                    value={view ? machineDetails?.clusterName : machineDetails?.clusterId}
                    onChange={onChangeHandler}
                    name="clusterId"
                    options={clustors && clustors[0]?.keyValueDto}
                    optionLabel="value"
                    optionValue="id"
                    disable={!machineDetails?.stateId}
                    required
                  />
                </Box>
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="Operator"
                    view={view}
                    value={view ? machineDetails?.operatorName : machineDetails?.operatorId}
                    onChange={onChangeHandler}
                    name="operatorId"
                    options={operators}
                    optionLabel="value"
                    optionValue="key"
                    disable={!machineDetails?.clusterId}
                    placeholder="Select"
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                <MultiInputPincode
                  placeholder="Enter Pincode"
                  label="pincodes"
                  PincodeValues={machineDetails?.pincodes}
                  onChange={(val: any) => { fetchSetMachineStates({ machineDetails: { ...machineDetails, pincodes: val } }); }}
                  view={view}
                  name="pincodes"
                  regExPattern={RegEx.Alphnumeric}
                  required
                />
                </Box>
              </div>
            </div>
            {view
              ? (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                {machineDetails?.machineStatus !== 'BREAKDOWN' && <CustomButton
                  label="Edit"
                  onClick={() => {
                    setView(false);
                    setEdit(true);
                  }}
                  customStyle={classes.applyButton}
                ></CustomButton>}
              </div>
                )
              : (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Reset"
                  onClick={onClickResetHandler}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label={edit ? 'Update' : 'Add Machine'}
                  disabled={isAddOperatorDisable()}
                  onClick={
                    edit
                      ? onClickUpdateOperatorHandler
                      : onClickAddOperatorHandler
                  }
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
                )}
          </div>
        </>
  );
};

export default AddMachine;
