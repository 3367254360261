import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  label: {
    ...fontFamilies.semiBold1420
  },
  viewLabel: {
    ...fontFamilies.regular1218
  },
  valueLabel: {
    ...fontFamilies.medium1621,
    color: '#14803C !important'
  },
  status: {
    ...fontFamilies.semiBold1624,
    color: '#14803C !important'
  },
  inActiveStatus: {
    ...fontFamilies.semiBold1624,
    color: '#696F88 !important'
  },
  inputBox: {
    marginTop: '10px !important',
    height: '50px'
  },
  toggleContainer: {
    // marginTop: '20px'
  }
}));
