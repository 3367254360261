import { ROLES } from '../../constants';
import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  GET_USER,
  GET_USER_FAILED,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  RESET_ONBOARD_STATES
} from './actionType';

import { type OnBoardState, type onBoardAction } from './types';

const initialState: OnBoardState = {
  error: false,
  isLoading: false,
  isUserListLoading: false,
  userList: {
    pageNo: 0,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    userProfiles: []

  },
  message: '',
  errorMessage: '',
  user: {
    cluster: '',
    clusterIds: '',
    district: '',
    districtId: '',
    licenseImage: '',
    licenseNumber: '',
    machineId: '',
    machineType: '',
    machineTypeId: '',
    mobileNo: '',
    name: '',
    state: '',
    stateId: '',
    pincode: '',
    status: 'Active',
    userRole: ROLES?.OPERATOR,
    id: '',
    userId: ''

  }
};

const defaultState: OnBoardState = JSON.parse(JSON.stringify(initialState));

export const reducer = (
  state = defaultState,
  action: onBoardAction
): OnBoardState => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        isUserListLoading: true,
        userList: {
          pageNo: 0,
          pageSize: 10,
          totalPages: 0,
          totalRecords: 0,
          userProfiles: []
        }
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isUserListLoading: false,
        userList: action?.payload || {}
      };
    case GET_USER_LIST_FAILED:
      return { ...state, isUserListLoading: false, error: true, errorMessage: action.payload?.errorMessage || '' };

    case ADD_USER:
      return { ...state, isLoading: true, errorMessage: '' };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        message: action?.payload?.message || ''
      };
    case ADD_USER_FAILED:
      return { ...state, isLoading: false, error: true, errorMessage: action.payload?.errorMessage || '' };

    case GET_USER:
      return { ...state, isLoading: true };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action?.payload?.user || {}
      };
    case GET_USER_FAILED:
      return { ...state, isLoading: false, error: true };

    case UPDATE_USER:
      return { ...state, isLoading: true };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action?.payload?.message || ''
      };
    case UPDATE_USER_FAILED:
      return { ...state, isLoading: false, error: true };

    case RESET_ONBOARD_STATES:
      return { ...state, [action?.payload]: initialState[action?.payload] };
    default:
      return state;
  }
};

export default reducer;
