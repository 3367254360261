import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  innerRoot: { minHeight: '100vh', display: 'flex' },
  sidebar: {
    backgroundColor: COLORS.Navy,
    width: '80px'
  },
  contents: {
    width: '100%'
  },
  actionsContainer: {
    display: 'flex',
    margin: '24px',
    justifyContent: 'space-between'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '74px'
  },
  menuIcon: { height: '16px', width: '16px' },
  menuLabel: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600
  }
})
);
