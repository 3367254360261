import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './styles';
// import { Switch } from "@mui/material";
import { COLORS } from '../../resources/colors';
// import NoData from "../noData";
import {
  withStyles
} from '@material-ui/core';
import { useFilter } from '../../hooks/useFilter';
import { Box, Checkbox, CircularProgress, IconButton, Link } from '@mui/material';
import { BOOKING_STATUS_TYPES } from '../../constants';
import { fontFamilies } from '../../resources/fonts';
import NoData from '../noData';

export interface HeaderType {
  label: string
  key: string
  enableSort: boolean
  actions?: any
  customStyle?: any
  isLink?: boolean
  width?: number | string
}

export interface DataTableProps {
  stickyHeaders: StickyHeaderType[]
  headers: HeaderType[]
  data: any[]
  loading?: boolean
  isCheckbox?: boolean
  selectedRows?: any
  selectedRowKey?: number | string
  setSelectedRows?: (event: React.ChangeEvent<HTMLInputElement>, row: any) => void
}

export interface StickyHeaderType extends HeaderType {
  // width1: string
}

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    left: 0,
    position: 'sticky',
    zIndex: 10
  },
  body: {
    background: 'white',
    left: 0,
    position: 'sticky',
    zIndex: 1
  }
}))(TableCell);

const StyledTableCell = withStyles((theme: any) => ({
  head: {
    backgroundColor: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme: any) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

interface GetBookingStatusType {
  status: string
}

interface BookingStatusColorTypes {
  background: string
  border: string
}

const GetBookingStatus = (props: GetBookingStatusType) => {
  const { status } = props;

  const color: BookingStatusColorTypes = {
    background: '',
    border: ''
  };
  switch (status) {
    case BOOKING_STATUS_TYPES.COMPLETED:
      color.background = COLORS.Status_Completed;
      color.border = COLORS.blue_60;
      break;
    case BOOKING_STATUS_TYPES.CONFIRMED:
      color.background = COLORS.Status_Confirmed;
      color.border = COLORS.green_60;
      break;
    case BOOKING_STATUS_TYPES.SERVICE_BOOKED:
      color.background = COLORS.Status_ServiceBooked;
      color.border = COLORS.yellow_60;
      break;
    case BOOKING_STATUS_TYPES.REJECTED:
      color.background = COLORS.Status_Rejected;
      color.border = COLORS.red_60;
      break;
    case BOOKING_STATUS_TYPES.CANCELLED:
      color.background = COLORS.Status_Cancelled;
      color.border = COLORS.orange_60;
      break;
  }

  return (
    <div style={{
      ...fontFamilies.semiBold1420,
      padding: '4px',
      borderRadius: '5px',
      background: `${color.background}`,
      color: color.border,
      border: `1px solid ${color.border}`,
      textAlign: 'center'
    }}>
      <span>{status}</span>
    </div>
  );
};
const initialSortInfo: Record<string, string> = {};

const DataTable: React.FC<DataTableProps> = ({
  stickyHeaders,
  headers,
  data,
  isCheckbox = false,
  loading,
  selectedRowKey,
  selectedRows,
  setSelectedRows
}) => {
  const classes = useStyles({ background: '', border: '' });

  const { orderDetails: { serviceOrdersReportDTO }, isOrderDetailsloading } = useFilter();

  const [orders, setOrders] = useState<any[]>(data);

  const [sortInfo, setSortInfo] = useState<Record<string, string>>({
    bookingStatus: ''
  });

  useEffect(() => {
    setOrders(serviceOrdersReportDTO);
    setSortInfo(initialSortInfo);
  }, [orders, serviceOrdersReportDTO]);

  const getValue = (arg: unknown) => {
    return arg || '';
  };

  const handleSort = (column: string, order: string) => {
    const sortOrder: string = order === 'asc' ? 'dsc' : 'asc';
    setSortInfo({ [column]: sortOrder });
  };

  const getSortedData = (sortInfo: any) => {
    const column = Object.keys(sortInfo)[0];
    const order = sortInfo[column];
    const sortOrder: string = order === 'asc' ? 'dsc' : 'asc';
    sortInfo[column] = sortOrder;
    if (column && column !== 'undefined') {
      switch (typeof column) {
        case 'string':
          data.sort((a: any, b: any) => sortOrder === 'asc' ? getValue(a[column]) > getValue(b[column]) ? 1 : -1 : getValue(a[column]) > getValue(b[column]) ? -1 : 1);
          break;
        default:
          data.sort((a: any, b: any) => sortOrder === 'asc' ? a[column] - b[column] : b[column] - a[column]);
          break;
      }
      return data;
    } else {
      return data;
    }
  };

  const onChangeClickHandler = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    setSelectedRows && setSelectedRows(event, row);
  };
  const tableClass = isOrderDetailsloading || loading || !serviceOrdersReportDTO?.length ? `${classes.tableContainerHelper} ${classes.dataLoading}` : `${classes.tableContainerHelper}`;

  return (
    <TableContainer className={tableClass}>
      {isOrderDetailsloading || loading
        ? (
          <Box className={classes.progressHelper}>
            <CircularProgress />
          </Box>
          )
        : (
          <>
            {data?.length > 0
              ? (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ fontSize: 1 }}>
                      {stickyHeaders?.length > 0 && (
                        <StickyTableCell style={{ padding: 0 }}>
                          {stickyHeaders?.map((header: StickyHeaderType, index: number) => {
                            return (
                              <TableCell
                                style={{
                                  width: header.width,
                                  textAlign: 'start',
                                  background: '#DFE2E7'
                                }} key={`${header.key}-${index}`} className={`${classes.tabelCell}`}
                              >
                                <div style={{ width: header.width }} className={classes.tabelCellContents}>
                                  {header.label}
                                  {header.enableSort
                                    ? (
                                      <IconButton onClick={() => {
                                        handleSort(header.key, sortInfo[header.key]);
                                      }}>
                                        <img className={sortInfo[header.key] === 'asc' ? classes.sortIconDescending : classes.sortIcon} src={`${process.env.PUBLIC_URL}/sort.png`} alt="sort" />
                                      </IconButton>
                                      )
                                    : null}
                                </div>
                              </TableCell>
                            );
                          })}
                        </StickyTableCell>
                      )}
                      { isCheckbox && <StyledTableCell className={[classes.checkboxTabelCell, classes.tableCellHeader].join(' ')}>
                        <Checkbox
                            color="primary"
                            indeterminate={selectedRows?.length > 0 && selectedRows?.length < data?.length}
                            checked={selectedRows?.length > 0 && selectedRows?.length === data?.length}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangeClickHandler(event, null); }}
                          />
                        </StyledTableCell>}
                      {headers?.map((header: HeaderType, index: number) => {
                        return (
                          <StyledTableCell
                            style={{ zIndex: 1, width: header?.width || 'auto' }}
                            className={`${classes.tabelCell} ${classes.tableCellHeader}`}
                            key={`${index}-${header.key}`}
                          >
                            <div className={ header.key ? classes.tabelCellContents : `${classes.tabelCellContents} ${classes.displayCenter}`}>
                              {header.label}
                              {header.enableSort
                                ? (
                                  <IconButton onClick={() => {
                                    handleSort(header.key, sortInfo[header.key]);
                                  }}>
                                    <img className={sortInfo[header.key] === 'asc' ? classes.sortIconDescending : classes.sortIcon} src={`${process.env.PUBLIC_URL}/sort.png`} alt="sort" />
                                  </IconButton>
                                  )
                                : null}
                            </div>
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      getSortedData(sortInfo)?.map((row: any, rowIndex: number) => {
                        return (
                          <>
                          <StyledTableRow
                            key={`${row.orderId}-${rowIndex}`}
                          >
                             { isCheckbox && <StyledTableCell className={classes.checkboxTabelCell} >
                                <Checkbox
                                    color="primary"
                                    checked={selectedRows?.length > 0 && selectedRowKey && selectedRows?.some((rowData: any) => rowData?.[selectedRowKey] === row[selectedRowKey])}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangeClickHandler(event, row); }}
                                  />
                              </StyledTableCell>}
                            {stickyHeaders?.length > 0 && (
                              <StickyTableCell style={{ padding: 0 }}>
                                <div>
                                  {stickyHeaders?.map((header: StickyHeaderType, index: number) => {
                                    return (
                                      <TableCell
                                        key={`${index}-${header.key}`}
                                        style={{ width: header.width, borderBottom: '0px' }}
                                        className={`${classes.tabelCell}`}
                                      >
                                        <div className={classes.stickyCellData} style={{ width: header.width }}>
                                          {row[header.key] || <span>{' '}</span>}
                                        </div>
                                      </TableCell>
                                    );
                                  })}
                                </div>
                              </StickyTableCell>
                            )}
                            {headers?.map((header: HeaderType, index: number) => {
                              return (
                                <StyledTableCell className={[classes.tabelCell, header?.customStyle].join(' ')} key={`${index}-${header.key}`}>
                                  {header.key === 'bookingStatus'
                                    ? (
                                      <GetBookingStatus key={`${index}-status`} status={row[header.key]} />
                                      )
                                    : header.key === ''
                                      ? (
                                          header.actions(row)
                                        )
                                      : header.isLink
                                        ? <><Link onClick={() => header.actions(row)} underline="always">
                                        {row[header.key]}
                                      </Link></>
                                        : (
                                      <>{row[header.key]}</>
                                          )}
                                </StyledTableCell>
                              );
                            })
                            }
                          </StyledTableRow>
                          </>
                        );
                      })
                    }
                  </TableBody>
                </Table >
                )
              : (
                <NoData />
                )}
          </>

          )}
    </TableContainer >
  );
};

export default DataTable;
