import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useStyles } from './styles';
import { DeletableChip } from '../deletableChip';

interface TextInputFieldProps {
  placeholder: string
  label?: string
  view?: boolean
  PincodeValues: string[] | number[]
  name?: string
  regExPattern?: RegExp
  required?: boolean
  multiline?: boolean
  error?: boolean
  rows?: number
  customStyle?: any
  maxLength?: number
  helperText?: string
  onChange: (e: any) => void
}
const MultiInputPincode: React.FC<TextInputFieldProps> = ({
  placeholder,
  label,
  view = false,
  name,
  PincodeValues,
  maxLength,
  rows,
  customStyle,
  error = false,
  helperText = '',
  regExPattern = '',
  required,
  multiline = false,
  onChange
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState('');
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, code } = event;
    if (maxLength && value && maxLength <= value?.length && code !== 'Backspace') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (regExPattern && (key.match(regExPattern) == null) && code !== 'Backspace') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEvent = event.target as HTMLInputElement;
    let inputValues: any = [...PincodeValues];
    if (inputEvent?.value?.includes(',')) {
      inputValues = inputEvent?.value?.split(',');
      setValue('');
      onChange([...new Set(onlyNumbers(inputValues))]);
    } else if (inputEvent?.value?.length === 6) {
      inputValues.push(String(inputEvent?.value));
      setValue('');
      onChange([...new Set(inputValues)]);
    } else { if (!isNaN(Number(inputEvent?.value))) setValue(inputEvent?.value); }
  };

  function onlyNumbers (array: any) {
    return array.filter((element: any) => {
      return !isNaN(element) && element?.length === 6;
    });
  }

  return (
    <Box>
      <label className={view ? classes.viewLabel : classes.label}>
        {label}
        {required && <span> *</span>}
      </label>
      {view
        ? (
        <div className={classes.valueLabel}>{PincodeValues?.join(',')}</div>
          )
        : (
          <>
        <TextField
          fullWidth
          placeholder={placeholder}
          className={[classes.inputBox, customStyle].join(' ')}
          value={value}
          name={name}
          onKeyDown={handleKeyDown}
          onChange={onChangeHandler}
          error={error}
          multiline={multiline}
          rows={rows}
          helperText={helperText}
        />
        <div className={classes?.pincodeContainer}>
        {PincodeValues?.map((pincode: any, index: number) => <DeletableChip key={index} label={pincode} onDelete={() => {
          PincodeValues?.splice(index, 1);
          onChange(PincodeValues);
        }}/>)}
        </div>
        </>
          )}
    </Box>
  );
};

export default MultiInputPincode;
