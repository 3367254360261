export const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
export const GET_ORDER_STATUS_SUCCESS = 'GET_ORDER_STATUS_SUCCESS';
export const GET_ORDER_STATUS_FAILED = 'GET_ORDER_STATUS_FAILED';

export const GET_ALL_STATES = 'GET_ALL_STATES';
export const GET_ALL_STATES_SUCCESS = 'GET_ALL_STATES_SUCCESS';
export const GET_ALL_STATES_FAILED = 'GET_ALL_STATES_FAILED';

export const GET_CLUSTORS = 'GET_CLUSTORS';
export const GET_CLUSTORS_SUCCESS = 'GET_CLUSTORS_SUCCESS';
export const GET_CLUSTORS_FAILED = 'GET_CLUSTORS_FAILED';

export const GET_SDO = 'GET_SDO';
export const GET_SDO_SUCCESS = 'GET_SDO_SUCCESS';
export const GET_SDO_FAILED = 'GET_SDO_FAILED';

export const GET_MACHINES = 'GET_MACHINES';
export const GET_MACHINES_SUCCESS = 'GET_MACHINES_SUCCESS';
export const GET_MACHINES_FAILED = 'GET_MACHINES_FAILED';

export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_FAILED = 'GET_DISTRICTS_FAILED';

export const GET_GEO_HIERARCHY_DISTRICTS = 'GET_GEO_HIERARCHY_DISTRICTS';
export const GET_GEO_HIERARCHY_DISTRICTS_SUCCESS = 'GET_GEO_HIERARCHY_DISTRICTS_SUCCESS';
export const GET_GEO_HIERARCHY_DISTRICTS_FAILED = 'GET_GEO_HIERARCHY_DISTRICTS_FAILED';

export const GET_OPERATOR_LIST = 'GET_OPERATOR_LIST';
export const GET_OPERATOR_LIST_SUCCESS = 'GET_OPERATOR_LIST_SUCCESS';
export const GET_OPERATOR_LIST_FAILED = 'GET_OPERATOR_LIST_FAILED';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED';

export const GET_FILTER_REQUEST = 'GET_FILTER_REQUEST';
export const SET_FILTER_REQUEST = 'SET_FILTER_REQUEST';

export const RESET_FILTER_STATES = 'RESET_FILTER_STATES';
