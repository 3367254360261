import { GET_MACHINE_LIST_BY_STATUS, GET_MACHINE_LIST_BY_STATUS_FAILED, GET_MACHINE_LIST_BY_STATUS_SUCCESS, RESET_MACHINE_STATES, SAVE_MACHINE_DETAILS, SAVE_MACHINE_DETAILS_FAILED, SAVE_MACHINE_DETAILS_SUCCESS, SET_MACHINE_STATES, UPDATE_MACHINE_DETAILS, UPDATE_MACHINE_DETAILS_FAILED, UPDATE_MACHINE_DETAILS_SUCCESS, UPDATE_MACHINE_STATUS, UPDATE_MACHINE_STATUS_FAILED, UPDATE_MACHINE_STATUS_SUCCESS } from './actionType';

import {
  type SetMachineStates,
  type machineState,
  type ResetMachineStates,
  type updateMachineStatusFailedPayloadtype,
  type updateMachineStatusFailedType,
  type updateMachineStatusRequestPayload,
  type updateMachineStatusRequestType,
  type updateMachineStatusSuccessType,
  type updateMachineStatusSucessPayloadType,
  type getMachineListByStatusFailedPayloadtype,
  type getMachineListByStatusFailedType,
  type getMachineListByStatusRequestPayload,
  type getMachineListByStatusRequestType,
  type getMachineListByStatusSuccessType,
  type getMachineListByStatusSucessPayloadType,
  type saveMachineDetailsRequestType,
  type machineDetailsType,
  type saveMachineDetailsFailedPayloadtype,
  type saveMachineDetailsFailedType,
  type saveMachineDetailsSuccessType,
  type saveMachineDetailsSucessPayloadType,
  type updateMachineDetailsFailedPayloadtype,
  type updateMachineDetailsFailedType,
  type updateMachineDetailsRequestType,
  type updateMachineDetailsSuccessType,
  type updateMachineDetailsSucessPayloadType
} from './types';

// for update status

export const updateMachineStatusRequest = (payload: updateMachineStatusRequestPayload): updateMachineStatusRequestType => {
  return {
    type: UPDATE_MACHINE_STATUS,
    payload
  };
};

export const updateMachineStatusSuccess = (
  payload: updateMachineStatusSucessPayloadType
): updateMachineStatusSuccessType => {
  return {
    type: UPDATE_MACHINE_STATUS_SUCCESS,
    payload
  };
};

export const updateMachineStatusFailed = (
  payload: updateMachineStatusFailedPayloadtype
): updateMachineStatusFailedType => {
  return {
    type: UPDATE_MACHINE_STATUS_FAILED,
    payload
  };
};

// for get machine list by status

export const getMachineListByStatusRequest = (payload: getMachineListByStatusRequestPayload): getMachineListByStatusRequestType => {
  return {
    type: GET_MACHINE_LIST_BY_STATUS,
    payload
  };
};

export const getMachineListByStatusSuccess = (
  payload: getMachineListByStatusSucessPayloadType
): getMachineListByStatusSuccessType => {
  return {
    type: GET_MACHINE_LIST_BY_STATUS_SUCCESS,
    payload
  };
};

export const getMachineListByStatusFailed = (
  payload: getMachineListByStatusFailedPayloadtype
): getMachineListByStatusFailedType => {
  return {
    type: GET_MACHINE_LIST_BY_STATUS_FAILED,
    payload
  };
};

// for save machine details

export const saveMachineDetailsRequest = (payload: machineDetailsType): saveMachineDetailsRequestType => {
  return {
    type: SAVE_MACHINE_DETAILS,
    payload
  };
};

export const saveMachineDetailsSuccess = (
  payload: saveMachineDetailsSucessPayloadType
): saveMachineDetailsSuccessType => {
  return {
    type: SAVE_MACHINE_DETAILS_SUCCESS,
    payload
  };
};

export const saveMachineDetailsFailed = (
  payload: saveMachineDetailsFailedPayloadtype
): saveMachineDetailsFailedType => {
  return {
    type: SAVE_MACHINE_DETAILS_FAILED,
    payload
  };
};

// for update machine details

export const updateMachineDetailsRequest = (payload: machineDetailsType): updateMachineDetailsRequestType => {
  return {
    type: UPDATE_MACHINE_DETAILS,
    payload
  };
};

export const updateMachineDetailsSuccess = (
  payload: updateMachineDetailsSucessPayloadType
): updateMachineDetailsSuccessType => {
  return {
    type: UPDATE_MACHINE_DETAILS_SUCCESS,
    payload
  };
};

export const updateMachineDetailsFailed = (
  payload: updateMachineDetailsFailedPayloadtype
): updateMachineDetailsFailedType => {
  return {
    type: UPDATE_MACHINE_DETAILS_FAILED,
    payload
  };
};

// for set & reset machine

export const resetMachineStates = (
  payload: keyof machineState
): ResetMachineStates => {
  return {
    type: RESET_MACHINE_STATES,
    payload
  };
};

export const setMachineStates = (
  payload: machineState
): SetMachineStates => {
  return {
    type: SET_MACHINE_STATES,
    payload
  };
};
