import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  selectBox: {
    ...fontFamilies.regular1419,
    color: COLORS.Nuetral_100,
    textAlign: 'start',
    textTransform: 'uppercase',
    height: '30px',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #868CA2',
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    '& .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
      ...fontFamilies.regular1419,
      color: COLORS.Nuetral_100
    },
    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
      padding: '5px !important'
    }
  },
  checkbox: {
    padding: '5px 9px !important',
    '& .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
      ...fontFamilies.regular1419,
      color: COLORS.Nuetral_100
    }
  },
  displayNone: {
    display: 'none !important',
    ...fontFamilies.regular1419,
    color: COLORS.Nuetral_100
  },
  sublabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  options: {
    marginTop: '7px',
    paddingLeft: '5px',
    paddingRight: '5px',
    whiteSpace: 'nowrap'
  },
  optionContainer: {
    padding: '7px 15px 3px 15px',
    width: 'min-content',
    minWidth: '160px',
    maxHeight: '300px',
    overflow: 'scroll'

  },
  expandIcon: {
    cursor: 'pointer'
  },
  noDataDiv: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  }
}));
