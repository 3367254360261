import React from 'react';
import Pagination from '@mui/material/Pagination';
import { useStyles } from './styles';

export interface CustomPaginationProps {
  onChangePageNumber: Function
  pageNumber: number
  totalPages: number
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ onChangePageNumber, pageNumber, totalPages }: CustomPaginationProps) => {
  //   const isLoading = useSelector((storeState: RootState) => {
  //     return {
  //       loader: storeState.loader.showLoader
  //     }
  //   });

  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    onChangePageNumber(value);
  };
  return (
    <>
      <Pagination
        key={totalPages}
        defaultPage={1}
        className={classes.root}
        boundaryCount={1}
        siblingCount={1}
        count={totalPages}
        page={pageNumber}
        variant='outlined'
        shape='rounded'
        onChange={handleChange}
      />
    </>
  );
};

export default CustomPagination;
