import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  wrapper: {
    background: '#FFF3DD',
    width: '30% !important',
    border: `1px solid ${COLORS.teal_70}`,
    borderRadius: '4px',
    marginLeft: '16px',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
    boxSizing: 'border-box'
  }
}));
