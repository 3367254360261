import axios from 'axios';
import { store } from '../redux/store';
import { type AppState } from '../redux/rootReducer';
import { COMMON_HEADERS } from '../constants';
import { apiUrls } from './apiUrls';

export const axiosApiInstance = axios.create({
  baseURL: apiUrls.BASE_URL,
  timeout: 90000
});

const getHeadersDetails = () => {
  const { login } = store.getState() as AppState;
  return {
    token: login.token,
    languageCode: COMMON_HEADERS.defaultLanguageCode,
    countryCode: COMMON_HEADERS.defaultCountryCode
    // role: 'SDO'
  };
};
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    const { countryCode, languageCode, token } = getHeadersDetails();
    config.headers = {
      authorization: token?.length > 0 ? token : '',
      'country-code': countryCode,
      'language-code': languageCode,
      Accept: '*/*',
      'Content-Type': 'application/json',
      ...config.headers
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // return await Promise.reject(error.response.data);
    return await Promise.reject(error.response.data || error);
  }
);
