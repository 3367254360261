import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  buttonHelper: {
    background: COLORS.Navy,
    borderRadius: 'unset',
    '& .css-sghohy-MuiButtonBase-root-MuiButton-root': {
      textTransform: 'none'
    },
    '&:hover': {
      background: COLORS.Navy
    }
  },
  disabledButton: {
    background: COLORS.TableHeaderGray,
    borderRadius: 'unset',
    textTransform: 'none',
    '& .css-sghohy-MuiButtonBase-root-MuiButton-root': {
      textTransform: 'none'
    }
  }
}));
