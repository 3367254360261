import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStyles } from './styles';
import { Box } from '@mui/material';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#14803C',
      '& + .MuiSwitch-track': {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
        backgroundColor: 'white',
        border: '3px solid #14803C'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

interface ToggleSwitchProps {
  label?: string
  rightLabel: string
  disabled?: boolean
  leftLabel: string
  view?: boolean
  value?: boolean
  required?: boolean
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  rightLabel,
  disabled = false,
  leftLabel,
  view = false,
  name,
  value,
  onChange,
  required
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      {view
        ? (
        <>
          <div className={classes.viewLabel}>Status</div>
          <div className={classes.valueLabel}>
            {value ? 'Active' : 'InActive'}
          </div>
        </>
          )
        : (
        <Box className = {classes.toggleContainer}>
          <Stack direction="row" spacing={1} alignItems="center">
            {leftLabel && <Typography className={classes.label}>
              {leftLabel}{required && <span>*</span>}
            </Typography>}
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              color={'secondary'}
              disabled={disabled}
              name={name}
              checked={value}
              onChange={onChange}
            />
            { rightLabel && <Typography
              className={value ? classes.status : classes.inActiveStatus}
            >
              {rightLabel}
            </Typography>}
          </Stack>
        </Box>
          )}
    </FormGroup>
  );
};

export default ToggleSwitch;
