import { Box, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import CheckBox from '../../common/Checkbox';
import DataTable, { type HeaderType } from '../../common/datatable';
import CustomizedDialog from '../../common/dialog';
import NestedDropdown from '../../common/NestedDropdown';
import CustomPagination from '../../common/pagination';
import RowsPerPage from '../../common/rowsPerPage';
import SearchInput from '../../common/searchInput';
import SelectedValueBadge from '../../common/selectedValueBadge';
import TextInputField from '../../common/textInputField';
import ToastMessage from '../../common/toastMessage';
import { useFilter } from '../../hooks';
import { useReAssign } from '../../hooks/useReAssign';
import ReassignToNewOperator from '../reAssignToNewOperator';
import { useStyles } from './styles';

const ReAssign: React.FC = () => {
  // classes
  const classes = useStyles();

  const viewOrderList = (data: any) => {
    fetchSetReAssignStates({ selectedMachineDetails: data });
    fetchOrderDetails({
      clusterIds: [],
      endDate: '',
      machineIds: [data?.machineId],
      pageNo: 0,
      pageSize: 10,
      redFlag: false,
      sdoIds: [],
      startDate: '',
      statesIds: [],
      statusIds: ['1', '3']
    });
    setViewOrder(true);
  };

  // table headers
  const machineHeader: HeaderType[] = [
    {
      label: 'Machine ID',
      key: 'machineId',
      enableSort: false,
      isLink: true,
      actions: viewOrderList
    },
    // {
    //   label: 'Reason',
    //   key: 'mobileNo',
    //   enableSort: false
    // },
    {
      label: 'State',
      key: 'stateName',
      enableSort: false
    },
    {
      label: 'Cluster',
      key: 'clusterName',
      enableSort: false
    },
    {
      label: 'machine type',
      key: 'machineType',
      enableSort: false
    },
    {
      label: 'Sdo',
      key: 'sdoName',
      enableSort: false
    },
    {
      label: 'Operator Name',
      key: 'operatorName',
      enableSort: false
    },
    {
      label: 'total orders',
      key: 'totalOrders',
      enableSort: false,
      customStyle: classes?.statusLabel
    }
  ];

  const ordersHeader: HeaderType[] = [
    {
      label: 'Order ID',
      key: 'orderId',
      enableSort: false
    },
    {
      label: 'status',
      key: 'bookingStatus',
      enableSort: false
    },
    {
      label: 'Amount',
      key: 'serviceCharge',
      enableSort: false
    },
    {
      label: 'Acres',
      key: 'sprayArea',
      enableSort: false
    },
    {
      label: 'Farmer details',
      key: 'farmerName',
      enableSort: false
    },
    {
      label: 'Booking Date',
      key: 'bookingDate',
      enableSort: false
    },
    {
      label: 'Spraying Date',
      key: 'sprayDate',
      enableSort: false
    },
    {
      label: 'Brands',
      key: 'productName',
      enableSort: false
    }
  ];

  const {
    fetchMachineList,
    fetchSetReAssignStates,
    resetReAssignStates,
    machineList: { machines, pageNo: machinePageNo, pageSize: machinePageSize },
    pageNo,
    pageSize,
    totalPages,
    stateIds,
    stateNames,
    clusterIds,
    clusterNames,
    searchText,
    selectedOrdersDetails,
    selectedMachineDetails,
    errorMessage,
    message,
    isMachineListLoading
  } = useReAssign();

  const {
    fetchAllStates,
    states,
    fetchOperators,
    isOrderDetailsloading,
    clustors,
    fetchClustors,
    fetchOrderDetails,
    orderDetails: {
      serviceOrdersReportDTO,
      pageNo: ordersPageNo,
      pageSize: ordersPageSize,
      totalPages: ordersTotalPages
    }
  } = useFilter();

  // states
  const [view, setView] = useState<boolean>(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [viewOrder, setViewOrder] = useState<boolean>(false);

  // effects
  useEffect(() => {
    fetchAllStates();
    fetchOperators({ sdoId: [], clusterId: [] });
    fetchClustors({ statesIds: [] });

    function resetState () {
      resetReAssignStates();
    }
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    fetchSetReAssignStates({ clusterIds: [], clusterNames: [] });
    // CHANGES for multiple API call
    if (stateIds?.length > 0) {
      fetchClustors({ statesIds: stateIds });
    }
  }, [stateIds]);

  useEffect(() => {
    if (viewOrder) {
      fetchOrderDetails({
        clusterIds: [],
        endDate: '',
        machineIds: [selectedMachineDetails?.machineId],
        pageNo,
        pageSize,
        redFlag: false,
        sdoIds: [],
        startDate: '',
        statesIds: [],
        statusIds: ['1', '3']
      });
    } else {
      fetchMachineList({
        pgno: pageNo,
        pgsize: pageSize,
        searchkey: searchText,
        stateId: String(stateIds),
        clusterId: String(clusterIds)
      });
    }
  }, [pageNo, pageSize]);

  useEffect(() => {
    fetchSetReAssignStates({ selectedOrdersDetails: [] });
  }, [viewOrder]);

  useEffect(() => {
    if (viewOrder && ordersPageNo !== 0 && ordersPageSize !== 10) {
      fetchSetReAssignStates({
        pageNo: ordersPageNo,
        pageSize: ordersPageSize,
        totalPages: ordersTotalPages
      });
    }
  }, [ordersPageNo, ordersPageSize, ordersTotalPages]);

  useEffect(() => {
    if (message) {
      fetchMachineList({
        pgno: machinePageNo,
        pgsize: machinePageSize,
        searchkey: searchText,
        stateId: String(stateIds),
        clusterId: String(clusterIds)
      });
      setViewOrder(false);
      setShowDialog(false);
      setTimeout(() => {
        fetchSetReAssignStates({ message: '' });
      }, 5000);
    }
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      fetchSetReAssignStates({ errorMessage: '' });
    }, 5000);
  }, [errorMessage]);

  const setSelectedIds = (
    e: any,
    label?: string,
    e1?: any,
    label1?: string
  ) => {
    if (label && label1) {
      fetchSetReAssignStates({ [label]: [...e], [label1]: [...e1] });
    }
  };

  const handleSearchChange = (eventVal: string) => {
    fetchMachineList({
      pgno: 0,
      pgsize: pageSize,
      searchkey: eventVal,
      stateId: String(stateIds),
      clusterId: String(clusterIds)
    });
    fetchSetReAssignStates({
      searchText: eventVal?.toLowerCase()
    });
  };

  const handlePageChange = (pageNo: number) => {
    fetchSetReAssignStates({ pageNo: pageNo - 1 });
  };
  const onOrderSelectionHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any
  ) => {
    let tempSelectedRows: any = [...selectedOrdersDetails];
    if (event.target.checked) {
      if (row) {
        fetchSetReAssignStates({
          selectedOrdersDetails: [...tempSelectedRows, row]
        });
      } else {
        const selectedOrders: any = serviceOrdersReportDTO?.map(
          (order: any) => order
        );
        fetchSetReAssignStates({ selectedOrdersDetails: [...selectedOrders] });
      }
    } else {
      if (row) {
        tempSelectedRows = tempSelectedRows?.filter(
          (tempRow: any) => tempRow?.orderId !== row?.orderId
        );
        fetchSetReAssignStates({
          selectedOrdersDetails: [...tempSelectedRows]
        });
      } else {
        fetchSetReAssignStates({ selectedOrdersDetails: [] });
      }
    }
  };

  const resetFilter = () => {
    fetchSetReAssignStates({
      stateIds: [],
      stateNames: [],
      clusterIds: [],
      clusterNames: []
    });
    fetchMachineList({
      pgno: pageNo,
      pgsize: pageSize,
      searchkey: searchText,
      stateId: '',
      clusterId: ''
    });
  };
  const applyFilter = () => {
    fetchMachineList({
      pgno: 0,
      pgsize: 10,
      searchkey: searchText,
      stateId: String(stateIds),
      clusterId: String(clusterIds)
    });
  };

  const getSelectedOptionLength = (arr: any) => {
    if (arr && arr?.length) {
      return arr?.map((e: any) => e?.keyValueDto?.length)?.reduce((partialSum: number, a: number) => partialSum + a, 0);
    } else {
      return 0;
    }
  };

  return (
    <div className={classes.onBoardContainer}>
      <>
        {!viewOrder
          ? (
            <Box className={classes.inputContainer}>
              <Box className={classes.container}>
                <div className={classes.filterSubContent}>
                  <CheckBox
                    label="States"
                    optionLabel="value"
                    optionValue="id"
                    customStyle={classes.checkBoxInput}
                    options={[{ id: 0, value: 'All' }, ...states]}
                    setSelectedIds={(e: any, e1: any) => {
                      setSelectedIds(e, 'stateIds', e1, 'stateNames');
                    }}
                    selectedIds={stateIds}
                    selectedNames={stateNames}
                  />
                  <SelectedValueBadge
                    title={'States'}
                    contentList={stateNames}
                    selectionLabel={
                      stateIds && stateIds?.length && stateIds?.length !== states?.length
                        ? `${stateIds?.length} Selected`
                        : 'All'
                    }
                  ></SelectedValueBadge>
                </div>
                <div className={classes.filterSubContent}>
                  <NestedDropdown
                    label="Cluster"
                    subOptionName="keyValueDto"
                    subOptionlabel="stateName"
                    subOptionValue="stateId"
                    customStyle={classes.checkBoxInput}
                    optionLabel="value"
                    optionValue="id"
                    options={[
                      { stateId: 0, stateName: 'All', keyValueDto: [] },
                      ...clustors
                    ]}
                    setSelectedIds={(e: any, e1: any) => {
                      setSelectedIds(e, 'clusterIds', e1, 'clusterNames');
                    }}
                    selectedIds={clusterIds}
                    selectedNames={clusterNames}
                  />
                  <SelectedValueBadge
                    title={'Cluster'}
                    contentList={clusterNames}
                    selectionLabel={
                      clusterIds && clusterIds?.length && clusterIds?.length !== getSelectedOptionLength(clustors)
                        ? `${clusterIds?.length} Selected`
                        : 'All'
                    }
                  ></SelectedValueBadge>
                </div>
                <div className={classes.buttonContainer}>
                  <CustomButton
                    label="Reset"
                    onClick={resetFilter}
                    customStyle={classes.resetButton}
                  ></CustomButton>
                  <CustomButton
                    label="Apply"
                    onClick={applyFilter}
                    customStyle={classes.applyButton}
                  ></CustomButton>
                </div>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'end',
                  justifyContent: 'end'
                }}
              >
                <Box style={{ border: '1px solid #C2C7D0', width: '43%' }}>
                  <SearchInput
                    onSearch={handleSearchChange}
                    placeholder="Search Machine ID / Operator"
                    searchText={searchText}
                    customStyle={classes.searchInput}
                  ></SearchInput>
                </Box>
              </Box>
            </Box>
            )
          : (
            <Box
              style={{
                display: 'flex',
                background: '#F3F4F6',
                border: '1px solid #C2C7D0',
                alignItems: 'center',
                padding: '20px'
              }}
            >
              <Box
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  setViewOrder(false);
                }}
              >
                <img
                  style={{ height: '15px', width: '15px', paddingRight: '24px' }}
                  src={`${process.env.PUBLIC_URL}/backArrow.png`}
                  alt="add"
                />
                <Link underline="none"></Link>
              </Box>
              <Box style={{ display: 'flex', flex: 1, columnGap: 20 }}>
                <Box>
                  <TextInputField
                    placeholder="Machine ID"
                    label="Machine ID"
                    value={selectedMachineDetails?.machineId}
                    onChange={() => {}}
                    view={true}
                    name="name"
                    maxLength={50}
                  />
                </Box>
                <Box>
                  <TextInputField
                    placeholder="Current Operator"
                    label="Current Operator"
                    value={selectedMachineDetails?.operatorName}
                    onChange={() => {}}
                    view={true}
                    name="mobileNo"
                    maxLength={10}
                  />
                </Box>
              </Box>
            </Box>
            )}
        <div className={classes.customTableContainer}>
          <DataTable
            data={viewOrder ? serviceOrdersReportDTO : machines}
            headers={viewOrder ? ordersHeader : machineHeader}
            stickyHeaders={[]}
            loading={isOrderDetailsloading || isMachineListLoading}
            isCheckbox={!!viewOrder}
            selectedRows={selectedOrdersDetails}
            selectedRowKey={'orderId'}
            setSelectedRows={(
              event: React.ChangeEvent<HTMLInputElement>,
              row: any
            ) => {
              onOrderSelectionHandler(event, row);
            }}
          ></DataTable>
        </div>
        <CustomizedDialog
          showDialog={showDialog}
          setShowDialog={() => {
            setShowDialog(!showDialog);
          }}
          title={'Re-assign to New operator'}
        >
          <ReassignToNewOperator
            view={view}
            // setView={(e) => {
            //   setView(e)
            // }}
            setShowDialog={(e) => {
              setShowDialog(e);
            }}
          ></ReassignToNewOperator>
        </CustomizedDialog>
      </>
      <Box style={{ display: 'flex', marginTop: '8px' }}>
        {viewOrder && (
          <Box>
            <CustomButton
              label="Re-assign"
              onClick={() => {
                setView(false);
                setShowDialog(!showDialog);
                fetchSetReAssignStates({ newOperatorId: '', comment: '' });
              }}
              disabled={selectedOrdersDetails?.length <= 0}
              customStyle={classes.addButton}
            />
          </Box>
        )}
        <Box
          style={{
            flex: 1
          }}
        >
          {totalPages > 0 &&
            !isMachineListLoading &&
            !isOrderDetailsloading && (
              <CustomPagination
                onChangePageNumber={handlePageChange}
                pageNumber={viewOrder ? Number(ordersPageNo) + 1 : Number(machinePageNo) + 1}
                totalPages={ viewOrder ? ordersTotalPages : totalPages}
              />
          )}
        </Box>
        <Box style={{ position: 'absolute', right: 0, marginRight: '16px' }}>
          {totalPages > 0 &&
            !isMachineListLoading &&
            !isOrderDetailsloading && <RowsPerPage
              rowsPerPage={viewOrder ? ordersPageSize : pageSize}
              setRowsPerPage={(e: number) => {
                // setRowsPerPage(e)
                fetchSetReAssignStates({ pageSize: e, pageNo: 0 });
              }}
            ></RowsPerPage>}
        </Box>
      </Box>
      {message && (
        <ToastMessage message={message} errorType="success"></ToastMessage>
      )}
      {errorMessage && (
        <ToastMessage message={errorMessage} errorType="error"></ToastMessage>
      )}
    </div>
  );
};

export default ReAssign;
