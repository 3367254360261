import {
  GET_ORDER_STATUS,
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_STATUS_FAILED
} from './actionType';
import { type OrderStatusState, type OrderStatusAction } from './types';

const initialState: OrderStatusState = {
  error: false,
  isLoading: false,
  status: ['All', 'Completed', 'Confirmed']
};

const defaultState: OrderStatusState = JSON.parse(JSON.stringify(initialState));

export const reducer = (state = defaultState, action: OrderStatusAction): OrderStatusState => {
  switch (action.type) {
    case GET_ORDER_STATUS:
      return { ...state, isLoading: true };
    case GET_ORDER_STATUS_SUCCESS:
      return { ...state, isLoading: false, status: action?.payload?.orderStatus || [] };
    case GET_ORDER_STATUS_FAILED:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
