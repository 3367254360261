export const BASE_URL_QA = 'https://uat-in.api.sprayer-operator.syndpe.com/';
export const BASE_URL_DEV = 'https://dev-in.api.sprayer-operator.syndpe.com/';
export const BASE_URL_PROD = 'https://in.api.sprayer-operator.syndpe.com/';

export const apiUrls = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  SEND_OTP_URL: 'api/v2/login/sendOtp/',
  VERIFY_OTP_URL: 'api/v2/login/verifyOtp/',
  GET_STATES_URL: 'api/v1/spray-service/states',
  GET_ORDER_STATUS_URL: 'api/v1/masters',
  GET_CLUSTORS_URL: 'api/v1/spray-service/clusters',
  GET_OPERATOR_LIST_URL: 'api/v1/spray-service/operators',
  GET_SDO_URL: 'api/v1/spray-service/sdos',
  GET_MACHINE_URL: 'api/v1/spray-service/machines',
  GET_DISTRICT_URL: 'api/v1/spray-service/districts',
  GET_GEO_HIERARCHY_DISTRICT_URL: 'api/v1/geohierarchy/districts',
  GET_ORDER_DETAILS_URL: 'api/v1/spray-service/order-details',
  USER_ONBOARD_URL: 'api/v1/useronboarding/users',
  MACHINE_LIST_URL: 'api/v1/spray-service/machine/orders',
  REASSIGN_ORDER_URL: 'api/v1/spray-service/orders/re-assign',
  UPDATE_BREAKDOWN_MACHINE_STATUS_URL: 'api/v1/spray-service/machines/',
  GET_CLUSTORS_DETAILS_URL: 'api/v1/spray-service/clusters',
  ADD_CLUSTOR_URL: 'api/v1/spray-service/clusters',
  UPDATE_CLUSTOR_URL: 'api/v1/spray-service/clusters/',
  SAVE_MACHINE_URL: 'api/v1/spray-service/machines',
  UPDATE_MACHINE_URL: 'api/v1/spray-service/machines/',
  DASHBOARD_DETAILS: '/api/v1/spray-service/dashboard'
};
