import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../common/button';
import CustomSelectBox from '../../common/customSelectBox';
import TextInputField from '../../common/textInputField';
import { RegEx } from '../../constants';
import { useFilter } from '../../hooks';
import { useReAssign } from '../../hooks/useReAssign';
import { type reAssignOrderListRequestPayload } from '../../redux/reAssign/types';
import { useStyles } from './styles';

interface AddOperatorProps {
  view: boolean
  setShowDialog: (e: any) => void
}

const ReassignToNewOperator: React.FC<AddOperatorProps> = ({
  view,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const { operators, fetchOperators } = useFilter();
  const {
    selectedMachineDetails,
    selectedOrdersDetails,
    newOperatorId,
    comment,
    fetchSetReAssignStates,
    fetchReAssignOrderList,
    isMachineListLoading
  } = useReAssign();

  // states

  // effects
  useEffect(() => {
    fetchOperators({
      sdoId: [],
      clusterId: [selectedMachineDetails?.clusterId]
    });
  }, []);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    fetchSetReAssignStates({ [inputEvent?.name]: inputEvent?.value });
  };

  const onClickResetHandler = () => {
    setShowDialog(false);
  };

  const onClickAddOperatorHandler = () => {
    const tempOrderIds: string[] = selectedOrdersDetails?.map(
      (order: any) => order.orderId
    );
    const reAssignOrderList: reAssignOrderListRequestPayload = {
      reassignComment: comment,
      orderIds: tempOrderIds || [],
      operatorId: String(newOperatorId)
    };
    fetchReAssignOrderList({ ...reAssignOrderList });
  };

  const isAddOperatorDisable = () => {
    return !newOperatorId;
  };

  return (
    <>
      {view
        ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '25%' }}
        >
          <CircularProgress></CircularProgress>
        </div>
          )
        : (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                <TextInputField
                  placeholder="Machine ID"
                  label="Machine ID"
                  value={selectedMachineDetails?.machineId}
                  onChange={onChangeHandler}
                  view={true}
                  name="name"
                  regExPattern={RegEx.TextwithSpaces}
                  maxLength={50}
                />
              </Box>
            </div>

            <Box className={classes.inputElementBox}>
              <TextInputField
                placeholder="Current Operator"
                label="Current Operator"
                regExPattern={RegEx.OnlyDigits}
                value={selectedMachineDetails?.operatorName}
                onChange={onChangeHandler}
                view={true}
                name="mobileNo"
                maxLength={10}
              />
            </Box>

            <Box className={classes.inputElementBox}>
              <TextInputField
                placeholder="Total Order for Re-assign"
                label="Total Order for Re-assign"
                regExPattern={RegEx.OnlyDigits}
                value={String(selectedOrdersDetails?.length)}
                onChange={onChangeHandler}
                view={true}
                name="mobileNo"
                maxLength={10}
              />
            </Box>
          </div>

          <div className={classes.locationContainer}>
            <div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox} style={{ flex: 2 }}>
                  <CustomSelectBox
                    label="New Operator"
                    placeholder="Select"
                    view={view}
                    value={String(newOperatorId)}
                    onChange={onChangeHandler}
                    name="newOperatorId"
                    options={operators ? operators?.filter((op: any) => { return op?.key !== selectedMachineDetails?.operatorId; }) : []}
                    optionLabel="value"
                    optionValue="key"
                    required
                  />
                </Box>
                <Box style={{ flex: 1 }}> </Box>
              </div>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <Box className={classes.inputElementBox}>
                  <TextInputField
                    placeholder="Type here ..."
                    label="Comment"
                    // regExPattern={RegEx.OnlyDigits}
                    value={comment}
                    onChange={onChangeHandler}
                    view={false}
                    customStyle={classes.commentInput}
                    name="comment"
                    maxLength={100}
                    multiline
                    rows={6}
                  />
                </Box>
              </div>
            </div>

            <div className={classes.buttonContainer}>
              <CustomButton
                label="Cancel"
                onClick={onClickResetHandler}
                customStyle={classes.resetButton}
              ></CustomButton>
              <CustomButton
                label={'Re-assign'}
                disabled={isAddOperatorDisable()}
                onClick={onClickAddOperatorHandler}
                customStyle={classes.applyButton}
                startIcon={
                  isMachineListLoading
                    ? (
                    <CircularProgress size={16} color="inherit" />
                      )
                    : null
                }
              ></CustomButton>
            </div>
          </div>
        </>
          )}
    </>
  );
};

export default ReassignToNewOperator;
