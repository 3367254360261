import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  toastContainer: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: '-100px'
    // marginBottom: '100px',
    position: 'relative',
    bottom: '50px'
  },
  toastMessage: {
    zIndex: 100000,
    width: 'auto',
    color: 'white',
    backgroundColor: 'black',
    ...fontFamilies.regular1622,
    padding: '0px 10px',
    alignItems: 'center'
  }
}));
