import {
  GET_MACHINE_LIST_BY_STATUS,
  GET_MACHINE_LIST_BY_STATUS_FAILED,
  GET_MACHINE_LIST_BY_STATUS_SUCCESS,
  RESET_MACHINE_STATES,
  SAVE_MACHINE_DETAILS,
  SAVE_MACHINE_DETAILS_FAILED,
  SAVE_MACHINE_DETAILS_SUCCESS,
  SET_MACHINE_STATES,
  UPDATE_MACHINE_DETAILS,
  UPDATE_MACHINE_DETAILS_FAILED,
  UPDATE_MACHINE_DETAILS_SUCCESS,
  UPDATE_MACHINE_STATUS,
  UPDATE_MACHINE_STATUS_FAILED,
  UPDATE_MACHINE_STATUS_SUCCESS
} from './actionType';

import { type machineState, type machineAction } from './types';

const initialState: machineState = {
  error: false,
  isMachineListLoading: false,
  searchText: '',
  pageNo: 0,
  pageSize: 10,
  totalPages: 0,
  message: '',
  errorMessage: '',
  filteredMachine: [],
  alternateMachineId: '',
  breakdownReasonId: '',
  selectedBreakdownMachine: {},
  machineList: [],
  machineDetails: {
    clusterId: '',
    machineId: '',
    stateId: '',
    machineTypeId: '',
    operatorId: '',
    pincodes: [],
    machineStatus: '',
    operatorName: '',
    stateName: '',
    machineType: '',
    clusterName: '',
    isMachineOnBoardingFlow: true
  },
  statusList: [{ key: 'ACTIVE', value: 'Active' }, { key: 'INACTIVE', value: 'InActive' }]
};

const defaultState: machineState = JSON.parse(JSON.stringify(initialState));

export const reducer = (
  state = defaultState,
  action: machineAction
): machineState => {
  switch (action.type) {
    case UPDATE_MACHINE_STATUS:
      return { ...state };
    case UPDATE_MACHINE_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message
      };
    case UPDATE_MACHINE_STATUS_FAILED:
      return { ...state, error: true, errorMessage: action.payload?.errorMessage || '' };

      // for get Machines list
    case GET_MACHINE_LIST_BY_STATUS:
      return { ...state, isMachineListLoading: true, machineList: [] };
    case GET_MACHINE_LIST_BY_STATUS_SUCCESS:
      return {
        ...state,
        isMachineListLoading: false,
        pageNo: action?.payload?.pageNo,
        pageSize: action?.payload?.pageSize,
        totalPages: action?.payload?.totalPages,
        machineList: action?.payload?.machineDetails || []
      };
    case GET_MACHINE_LIST_BY_STATUS_FAILED:
      return { ...state, isMachineListLoading: false, error: true, errorMessage: action?.payload?.errorMessage || '', pageNo: 0, pageSize: 10, totalPages: 0 };

    // for save machine
    case SAVE_MACHINE_DETAILS:
      return { ...state };
    case SAVE_MACHINE_DETAILS_SUCCESS:
      return {
        ...state,
        message: action?.payload?.message
      };
    case SAVE_MACHINE_DETAILS_FAILED:
      return { ...state, isMachineListLoading: false, error: true, errorMessage: action?.payload?.errorMessage || '' };
    // for update machine
    case UPDATE_MACHINE_DETAILS:
      return { ...state };
    case UPDATE_MACHINE_DETAILS_SUCCESS:
      return {
        ...state,
        message: action?.payload?.message
      };
    case UPDATE_MACHINE_DETAILS_FAILED:
      return { ...state, isMachineListLoading: false, error: true, errorMessage: action?.payload?.errorMessage || '' };

    case RESET_MACHINE_STATES:
      return { ...state, [action?.payload]: initialState[action?.payload] };

    case SET_MACHINE_STATES:
      return { ...state, ...action?.payload };

    default:
      return state;
  }
};

export default reducer;
