import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  selectHelper: {
    height: '32px',
    background: '#F3F4F6',
    marginLeft: '8px',
    textAlign: 'start'
  }
});
