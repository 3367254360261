import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useStyles } from './styles';

interface TextInputFieldProps {
  placeholder: string
  label?: string
  view?: boolean
  value?: string
  name?: string
  regExPattern?: RegExp
  required?: boolean
  multiline?: boolean
  error?: boolean
  rows?: number
  customStyle?: any
  maxLength?: number
  helperText?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const TextInputField: React.FC<TextInputFieldProps> = ({
  placeholder,
  label,
  view = false,
  name,
  value,
  maxLength,
  rows,
  customStyle,
  error = false,
  helperText = '',
  regExPattern = '',
  required,
  multiline = false,
  onChange
}) => {
  const classes = useStyles();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, code } = event;
    if (maxLength && value && maxLength <= value?.length && code !== 'Backspace') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (regExPattern && (key.match(regExPattern) == null) && code !== 'Backspace' && !event?.metaKey && !event?.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Box>
      <label className={view ? classes.viewLabel : classes.label}>
        {label}
        {required && <span> *</span>}
      </label>
      {view
        ? (
        <div className={classes.valueLabel}>{value}</div>
          )
        : (
        <TextField
          fullWidth
          placeholder={placeholder}
          className={[classes.inputBox, customStyle].join(' ')}
          value={value}
          name={name}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          error={error}
          multiline={multiline}
          rows={rows}
          helperText={helperText}
        />
          )}
    </Box>
  );
};

export default TextInputField;
