import React from 'react';
import { useStyles } from './CardComponentStyles';

interface ICardComponentProps {
  children?: React.ReactNode
  customStyle?: any
}
const CardComponent: React.FC<ICardComponentProps> = ({
  customStyle,
  children
}) => {
  const classes = useStyles();
  return (
    <div className={[classes.wrapper, customStyle].join(' ')}>{children}</div>
  );
};

export default CardComponent;
