import React from 'react';
import { useStyles } from './styles';

const NoData: React.FC = () => {
  // classes
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={`${process.env.PUBLIC_URL}/no-data.png`} alt="no-data" />
      <p className={classes.noDataHeader}>No Data available</p>
      <p className={classes.noDataSubHeader}>Please try modifying filters OR try again later</p>
    </div>
  );
};

export default NoData;
