import React from 'react';
import './App.css';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import Login from './components/login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Dashboard from './components/dashboard';
import VerifyOtp from './components/verifyOtp';
import { useLogin } from './hooks';
import Home from './components/home';

// export const useStyles = makeStyles(() => ({
//   root: {
//     display: 'flex',
//     height: '100vh'
//   },
//   sidebar: {
//     backgroundColor: '#00004B',
//     width: '80px'
//   },
//   contentsContainer: {
//     width: '100%',
//   }
// })
// )

function App () {
  // const classes = useStyles();
  // const isAuthenticated: boolean = true;
  // const { isAuthenticated } = useLogin();

  // const isAuthenticated: boolean = false;
  const { isAuthenticated } = useLogin();
  // const isAuthenticated: boolean = true;

  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          {!isAuthenticated
            ? (
            <>
              <Route path='/' element={<Login />} />
              <Route path='/verify' element={<VerifyOtp />} />
            </>
              )
            : (
            <Route path='*' element={<Home />} />
              )}
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
