export const COLORS = {
  Navy: '#00004B',
  ErrorRed: '#EB4B4B',
  LabelGray: '#646464',
  White: '#FFFFFF',
  Black: '#000000',
  SwitchGray: '#696F88',
  TableCellBorder: '#C2C7D0',
  TableHeaderGray: '#DFE2E7',
  TableBackgroundGray: '#F3F4F6',
  SubHEaderGray: '#4D5165',
  MenuBlue: '#005693',
  Nuetral_100: '#14151C',
  green_50: '#19A04B',
  Nuetral_80: '#363948',
  Nuetral_20: '#232630',
  Status_Completed: '#EAF6FF',
  Status_Confirmed: '#DFFBE8',
  Status_Rejected: '#FFD9D9',
  Status_ServiceBooked: '#FFF3DD',
  Status_Cancelled: '#FFF1ED',
  blue_60: '#0071CD',
  yellow_60: '#9A6412',
  red_60: '#CF3537',
  green_60: '#14803C',
  orange_60: '#C1462B',
  gray_50: '#E8EAED',
  teal_70: '#165C56',
  back_90: '#372102',
  green_20: '#C3EAD1',
  purple_10: '#F6F2FE',
  yellow: '#A08E00',
  lightGreen: '#019D95',
  lightRed: '#DD5858',
  lightGray: '#686868',
  lightBrown: '#D66C05',
  lightyellow: '#E99921',
  statusCompleted: '#FF6384',
  statusOpened: '#36A2EB',
  statusConfirmed: '#FFCE56',
  statusCancelled: '#005693',
  statusRejected: '#CF3537'
};
