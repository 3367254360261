import { CircularProgress, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import { RegEx } from '../../constants';
import { useLogin } from '../../hooks';
import VerifyOtp from '../verifyOtp';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  // custom hooks
  const {
    otpSent,
    isLoading,
    errorSendingOtp,
    mobile: userMobile,
    sendOtp
  } = useLogin();
  const navigate = useNavigate();
  // classes
  const classes = useStyles();

  // hooks
  const [mobile, setMobile] = useState<string>(userMobile);

  // effects
  useEffect(() => {
    if (otpSent) {
      navigate('/verify');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpSent]);

  // handlers
  const handleSubmit = () => {
    sendOtp({ mobile });
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (value?.includes('-')) {
      value = value.split('-').join('').replace(/\s/g, '');
    }
    if (RegEx.PhoneNumber.test(value)) {
      setMobile(value);
    }
  };

  const getFormatterMobile = () => {
    const value: string = mobile;
    const x: RegExpMatchArray | null = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (x != null) {
      return !x[2] ? x[1] : x[1] + ' - ' + x[2] + (x[3] ? ' - ' + x[3] : '');
    }
  };

  const showVerifyOtp: boolean = false;

  const textFieldStyle = errorSendingOtp && errorSendingOtp.message ? `${classes.textFieldHelper} ${classes.textFieldErrorHelper}` : `${classes.textFieldHelper}`;

  return (
    <div className={classes.rootContainer}>
      {showVerifyOtp
        ? (
        <VerifyOtp />
          )
        : (
        <div className={classes.rootContainer}>
          <div className={classes.root}>
            <div className={classes.loginImage}>
              <img loading="lazy" src={`${process.env.PUBLIC_URL}/login.png`} alt="login" />
            </div>
            <div className={classes.contents}>
              <div className={classes.container}>
                <img src={`${process.env.PUBLIC_URL}/syngenta-logo.png`} alt="syngenta-logo" />
              </div>
              <div className={classes.headerContainer}>
                <p className={classes.welcomeHelper}>Welcome</p>
                <p className={classes.adminHelper}>{' '}Admin</p>
              </div>
              <div className={classes.container}>
                <p className={classes.mobileNumberLabel}>Enter Mobile Number</p>
                <FormControl fullWidth>
                  <div className={classes.root}>
                    <TextField
                      className={`${classes.textFieldHelper} ${classes.countryCodeHelper}`}
                      size="small"
                      value="+91"
                      inputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      fullWidth
                      className={textFieldStyle}
                      size="small"
                      placeholder="000 - 000 - 0000"
                      value={mobile?.length === 10 ? getFormatterMobile() : mobile}
                      onChange={handlePhoneChange}
                      error={errorSendingOtp?.message?.length > 0}
                    />
                  </div>
                </FormControl>
                {errorSendingOtp?.message?.length > 0
                  ? (
                  <div className={classes.errorContainer}>
                    <img className={classes.errorImage} src={`${process.env.PUBLIC_URL}/error.png`} alt="error" />
                    <p className={classes.errorText}>{errorSendingOtp?.message}</p>
                  </div>
                    )
                  : null}
                <div className={classes.actionContainer}>
                  <CustomButton
                    disabled={!RegEx.ValidPhoneNumber.test(mobile)}
                    label="SEND OTP"
                    fullWidth={true}
                    // loading={isLoading}
                    onClick={handleSubmit}
                    startIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : null}
                  />
                </div>
              </div>
              <div className={classes.secureLoginImage}>
                <img src={`${process.env.PUBLIC_URL}/secure-login.png`} alt="secure-login" />
              </div>
            </div>
          </div>
        </div>
          )
      }
    </div>
  );
};

export default Login;
