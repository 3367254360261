import {
  GET_MACHINE_LIST,
  GET_MACHINE_LIST_FAILED,
  GET_MACHINE_LIST_SUCCESS,
  REASSIGN_ORDER_LIST,
  REASSIGN_ORDER_LIST_FAILED,
  REASSIGN_ORDER_LIST_SUCCESS,
  RESET_REASSIGN_STATES,
  SET_REASSIGN_STATES
} from './actionType';

import { type ReAssignState, type reAssignAction } from './types';

const initialState: ReAssignState = {
  error: false,
  isMachineListLoading: false,
  machineList: {
    pageNo: 0,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
    machines: []
  },
  operatorIds: [],
  operatorNames: [],
  stateIds: [],
  stateNames: [],
  clusterIds: [],
  clusterNames: [],
  selectedMachineDetails: {},
  selectedOrdersDetails: [],
  searchText: '',
  pageNo: 0,
  pageSize: 10,
  totalPages: 0,
  comment: '',
  newOperatorId: '',
  message: '',
  errorMessage: ''
};

const defaultState: ReAssignState = JSON.parse(JSON.stringify(initialState));

export const reducer = (
  state = defaultState,
  action: reAssignAction
): ReAssignState => {
  switch (action.type) {
    case GET_MACHINE_LIST:
      // return { ...state, isMachineListLoading: true, pageNo: 0, pageSize: 10, totalPages: 0, error: false };
      return {
        ...state,
        isMachineListLoading: true,
        error: false,
        machineList: {
          pageNo: 0,
          pageSize: 10,
          totalPages: 0,
          totalRecords: 0,
          machines: []
        }
      };
    case GET_MACHINE_LIST_SUCCESS:
      return {
        ...state,
        isMachineListLoading: false,
        machineList: action?.payload || {},
        pageNo: action?.payload?.pageNo,
        pageSize: action?.payload?.pageSize,
        totalPages: action?.payload?.totalPages
      };
    case GET_MACHINE_LIST_FAILED:
      return { ...state, isMachineListLoading: false, error: true, errorMessage: action.payload?.errorMessage || '', pageNo: 0, pageSize: 10, totalPages: 0 };

    case REASSIGN_ORDER_LIST:
      return { ...state, isMachineListLoading: true };
    case REASSIGN_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isMachineListLoading: false,
        message: action.payload.message
      };
    case REASSIGN_ORDER_LIST_FAILED:
      return { ...state, isMachineListLoading: false, error: true, errorMessage: action.payload?.errorMessage || '' };

    case RESET_REASSIGN_STATES:
      return { ...initialState };
    case SET_REASSIGN_STATES:
      return { ...state, ...action?.payload };

    default:
      return state;
  }
};

export default reducer;
