import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useStyles } from './styles';
import { ListItem, ListItemText } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxHeight: '200px',
    overflow: 'auto'
  }
}));

interface SelectedValueBadgeProps {
  title: string
  contentList?: string[]
  selectionLabel?: string
}

const SelectedValueBadge: React.FunctionComponent<SelectedValueBadgeProps> = ({
  contentList,
  selectionLabel
}) => {
  const classes = useStyles();

  const renderContent = () => {
    if ((contentList != null) && contentList?.length) {
      return <div className={classes.listContainer}>
        {contentList?.map((e: any, index: number) => (
          <ListItem className={classes.listItem} key={`sel${index}`}>
            <ListItemText
              primary={e}
            />
          </ListItem>
        ))}
      </div>;
    } else {
      return '';
    }
  };
  return (
    <div className={classes.tooltipContainer}>
      <HtmlTooltip
        title={
          renderContent()
        }
      >
        <div className={classes.selectedText}>
          {selectionLabel}
          {(contentList != null) && contentList?.length
            ? <img
            src={`${process.env.PUBLIC_URL}/info.png`}
            alt="search-input"
            className={classes.infoIcon}
          />
            : null}
        </div>
      </HtmlTooltip>
    </div>
  );
};

export default SelectedValueBadge;
