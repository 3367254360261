import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import CustomMultiSelectBox from '../../common/customMultiSelectBox';
import CustomSelectBox from '../../common/customSelectBox';
import CustomizedDialog from '../../common/dialog';
import TextInputField from '../../common/textInputField';
// import ToastMessage from '../../common/toastMessage'
import ToggleSwitch from '../../common/toggleSwitch';
import { RegEx, ROLES } from '../../constants';
import { useFilter } from '../../hooks';
import { useOnBoard } from '../../hooks/onBoard';
import {
  type updateUserRequestPayload,
  type addUserRequestPayload,
  type userRole
} from '../../redux/onBoard/types';
import { useStyles } from './styles';

interface operatorDetailsType {
  userId: string
  cluster: string
  clusterIds: string[]
  district: string
  districtId: string
  licenseImage: any
  licenseNumber: string
  machineId: string
  machineType: string
  machineTypeId: string
  mobileNo: string
  name: string
  state: string
  stateId: string
  status: string
  pincode: string
  userRole: keyof userRole
}

const operatorDetailsInit: operatorDetailsType = {
  userId: '',
  cluster: '',
  clusterIds: [],
  district: '',
  districtId: '',
  licenseImage: null,
  licenseNumber: '',
  machineId: '',
  machineType: '',
  machineTypeId: '',
  mobileNo: '',
  name: '',
  state: '',
  stateId: '',
  status: 'Active',
  pincode: '',
  userRole: ROLES?.SDO
};

interface AddOperatorProps {
  view: boolean
  data: operatorDetailsType
  setView: (e: any) => void
  setShowDialog: (e: any) => void
}

const AddOperator: React.FC<AddOperatorProps> = ({
  view,
  data,
  setView,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const {
    states,
    status,
    clustors,
    fetchAllStates,
    fetchClustors,
    fetchDistricts
  } = useFilter();
  const {
    isLoading,
    errorMessage,
    addUser,
    updateUser,
    fetchResetOnboardStates
  } = useOnBoard();

  // states
  const [operatorDetails, setOperatoeDetails] =
    useState<operatorDetailsType>(operatorDetailsInit);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  // effects
  useEffect(() => {
    const tempData: operatorDetailsType = {
      ...data,
      clusterIds: data?.clusterIds && data?.clusterIds?.length ? data?.clusterIds?.map((id: any) => String(id)) : [],
      district: data?.district || '',
      districtId: data?.districtId ? String(data?.districtId) : '',
      mobileNo: data?.mobileNo || '',
      name: data?.name,
      userRole: ROLES?.SDO,
      userId: data?.userId
    };
    setOperatoeDetails(tempData);
  }, [data]);

  useEffect(() => {
    setOperatoeDetails({ ...operatorDetails, districtId: '' });
    if (operatorDetails?.clusterIds) {
      fetchDistricts({ clusterIds: operatorDetails?.clusterIds });
    }
  }, [operatorDetails?.clusterIds]);

  useEffect(() => {
    fetchAllStates();
  }, []);

  useEffect(() => {
    setOperatoeDetails({
      ...operatorDetails,
      machineType: String(status[0].key)
    });
  }, [operatorDetails?.machineId]);

  useEffect(() => {
    if (operatorDetails?.stateId) {
      fetchClustors({ statesIds: [operatorDetails?.stateId] });
    }
  }, [operatorDetails?.stateId]);

  useEffect(() => {
    setTimeout(() => {
      fetchResetOnboardStates('errorMessage');
    }, 5000);
  }, [errorMessage]);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent?.name === 'stateId') {
      setOperatoeDetails({ ...operatorDetails, clusterIds: [], [inputEvent?.name]: inputEvent?.value });
    } else {
      setOperatoeDetails({
        ...operatorDetails,
        [inputEvent?.name]: inputEvent?.value
      });
    }
  };

  const onMultiChangeHandler = (value: string[] | number [] | string, name: string) => {
    // const {
    //   target: { value, name }
    // } = event;

    setOperatoeDetails({
      ...operatorDetails,
      [name]: typeof value === 'string' ? value.split(',') : value
    });
  };

  const onClickResetHandler = () => {
    setOperatoeDetails({ ...operatorDetailsInit });
  };

  const onClickAddOperatorHandler = () => {
    const UserDetails: addUserRequestPayload = {
      cluster: operatorDetails?.cluster || null,
      clusterIds: operatorDetails?.clusterIds,
      district: operatorDetails?.district || null,
      districtId: Number(operatorDetails?.districtId) || null,
      licenseImage: operatorDetails?.licenseImage?.split(',')[1] || null,
      licenseNumber: operatorDetails?.licenseNumber || null,
      machineId: operatorDetails?.machineId || null,
      machineType: operatorDetails?.machineType,
      machineTypeId: Number(operatorDetails?.machineTypeId) || null,
      mobileNo: operatorDetails?.mobileNo || null,
      name: operatorDetails?.name,
      state: operatorDetails?.state || null,
      stateId: Number(operatorDetails?.stateId),
      status: operatorDetails?.status,
      userRole: ROLES?.SDO
    };
    addUser({ ...UserDetails });
  };

  const onClickUpdateOperatorHandler = () => {
    setShowConfirmationDialog(true);
  };

  const onUpdateUserHandler = () => {
    setShowConfirmationDialog(false);
    const UserDetails: updateUserRequestPayload = {
      ...data,
      cluster: operatorDetails?.cluster || null,
      clusterIds: operatorDetails?.clusterIds,
      district: operatorDetails?.district || null,
      districtId: Number(operatorDetails?.districtId),
      licenseImage: operatorDetails?.licenseImage?.split(',')[1] || null,
      licenseNumber: operatorDetails?.licenseNumber || null,
      machineId: operatorDetails?.machineId || null,
      machineType: operatorDetails?.machineType,
      machineTypeId: Number(operatorDetails?.machineTypeId) || null,
      mobileNo: operatorDetails?.mobileNo || null,
      name: operatorDetails?.name,
      state: operatorDetails?.state || null,
      stateId: Number(operatorDetails?.stateId),
      status: operatorDetails?.status,
      userRole: ROLES?.SDO,
      userId: data?.userId
    };
    updateUser({ ...UserDetails });
  };

  const isAddOperatorDisable = () => {
    return !(
      operatorDetails.name &&
      operatorDetails?.mobileNo?.length === 10 &&
      operatorDetails.stateId &&
      operatorDetails?.clusterIds?.length
    );
  };

  const onSwitchHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    setOperatoeDetails({
      ...operatorDetails,
      [inputEvent?.name]: inputEvent?.checked ? 'Active' : 'InActive'
    });
  };

  return (
    <>
      {view && isLoading
        ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '25%' }}
        >
          <CircularProgress></CircularProgress>
        </div>
          )
        : (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                <TextInputField
                  placeholder="Enter"
                  label="Name"
                  value={operatorDetails?.name}
                  onChange={onChangeHandler}
                  view={view}
                  name="name"
                  regExPattern={RegEx.TextwithSpaces}
                  maxLength={50}
                  required
                />
              </Box>
            </div>

            <Box className={classes.inputElementBox}>
              <TextInputField
                placeholder="Enter"
                label="Mobile Number"
                regExPattern={RegEx.OnlyDigits}
                value={operatorDetails?.mobileNo}
                onChange={onChangeHandler}
                view={view}
                name="mobileNo"
                maxLength={10}
                required
              />
            </Box>
          </div>

          <div className={classes.locationContainer}>
            <h3 className={classes.locationTitle}>Location Details</h3>
            <div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="State"
                    placeholder="Select"
                    view={view}
                    value={view ? data?.state : operatorDetails?.stateId}
                    onChange={onChangeHandler}
                    name="stateId"
                    options={states}
                    optionLabel="value"
                    optionValue="id"
                    required
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                  <CustomMultiSelectBox
                    label="Cluster"
                    placeholder="Select"
                    view={view}
                    value={view ? data?.cluster : operatorDetails?.clusterIds}
                    // value={[]}
                    onChange={(values: string[] | number[] | string, name: string) => { onMultiChangeHandler(values, name); }}
                    name="clusterIds"
                    disable={!operatorDetails?.stateId}
                    options={clustors && clustors[0]?.keyValueDto}
                    optionLabel="value"
                    optionValue="id"
                    required
                  />
                </Box>
              </div>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <Box className={[classes.inputElementBox, classes?.toggleSwitchContainer].join(' ')}>
                  <ToggleSwitch
                    value={operatorDetails?.status === 'Active'}
                    onChange={onSwitchHandler}
                    view={view}
                    leftLabel='Status'
                    rightLabel='Active'
                    name="status"
                  />
                </Box>
              </div>
            </div>
            {view
              ? (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Edit"
                  onClick={() => {
                    setView(false);
                    setEdit(true);
                  }}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
                )
              : (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Reset"
                  onClick={onClickResetHandler}
                  disabled={
                    JSON.stringify(operatorDetailsInit) ===
                    JSON.stringify(operatorDetails)
                  }
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label={edit ? 'Update' : 'Add SDO'}
                  disabled={isAddOperatorDisable()}
                  onClick={
                    edit
                      ? onClickUpdateOperatorHandler
                      : onClickAddOperatorHandler
                  }
                  customStyle={classes.applyButton}
                  startIcon={
                    isLoading
                      ? (
                      <CircularProgress size={16} color="inherit" />
                        )
                      : null
                  }
                ></CustomButton>
              </div>
                )}
          </div>
          <CustomizedDialog
            showDialog={showConfirmationDialog}
            setShowDialog={() => {
              setShowConfirmationDialog(!showConfirmationDialog);
            }}
            title={'Confirmation'}
          >
            <div className={classes.confirmcontainer}>
              <div className={classes.confirmLabelText}>
                Are you sure you want to save the changes?
              </div>
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowConfirmationDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Save"
                  onClick={onUpdateUserHandler}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
            </div>
          </CustomizedDialog>
        </>
          )}
    </>
  );
};

export default AddOperator;
