import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  label: {
    ...fontFamilies.semiBold1420,
    color: '#232630 !important'
  },
  viewLabel: {
    ...fontFamilies.regular1218,
    wordBreak: 'break-word'
  },
  valueLabel: {
    ...fontFamilies.medium1621
  },
  inputBox: {
    marginTop: '10px !important',
    height: '50px'
  },
  optionLabel: {
    '& .css-ahj2mt-MuiTypography-root': {
      ...fontFamilies.regular1420
    },
    '& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked': {
      color: '#14803C',
      innerWidth: '20px',
      innerHeight: '20px'
    }
  }
}));
