import { useSelector, useDispatch } from 'react-redux';
import {
  getOrderStatus,
  getOrderStatusLoading,
  getOrderStatusError,
  getAllStates,
  getClustors,
  getSDO,
  getMachine,
  getOrderDetails,
  getFilterRequest,
  getPageSize,
  getAllOrderDetails,
  getExcelDownLoading,
  getOrderDetailsloading,
  getDistrict,
  getMachineTypes,
  getOperatorList,
  getMachineBreakDownReason,
  getGeoHierarchyDistricts
} from '../redux/filter/selectors';
import {
  getOrderStatus as getOrder,
  getAllStates as getStates,
  getClustors as getClustorAction,
  getOpearatorList as getOpearatorListAction,
  getSDOs,
  getMachines as getMachineActions,
  getDistricts as getDistrictActions,
  getOrderDetails as getOrderDetailsAction,
  setFilterRquest as setFilterRequestAction,
  getFilterRquest as getFilterRequestAction,
  resetFilterStates as resetFilterStatesAction,
  getGeoHierarchyDistricts as getGeoHierarchyActions
} from '../redux/filter/action';
import {
  type clustorItemType,
  type FilterState,
  type getClustorRequestPayload,
  type getSDORequestPayload,
  type SDOItemType,
  type stateItemType,
  type statusItemType,
  type getMachineRequestPayload,
  type MachineItemType,
  type getOrderDetailsRequestPayload,
  type filterRequestPayloadType,
  type OrderDetailsType,
  type getOrderStatusRequestPayload,
  type DistrictItemType,
  type getDistrictRequestPayload,
  type OperatorListItemType,
  type getOperatorListRequestPayload,
  type getGeoHierarchyDistrictsRequestPayload,
  type GeoHierarchyDistrictType
} from '../redux/filter/types';

interface FilterType extends FilterState {
  pageSize: number
  fetchOrderStatus: (action: getOrderStatusRequestPayload) => void
  fetchAllStates: () => void
  fetchClustors: (action: getClustorRequestPayload) => void
  fetchOperators: (action: getOperatorListRequestPayload) => void
  fetchSDOs: (action: getSDORequestPayload) => void
  fetchMachines: (action: getMachineRequestPayload) => void
  fetchDistricts: (action: getDistrictRequestPayload) => void
  fetchGeoHierarchyDistricts: (action: getGeoHierarchyDistrictsRequestPayload) => void
  fetchOrderDetails: (action: getOrderDetailsRequestPayload) => void
  fetchFilterRequest: () => void
  setFilterRequest: (action: filterRequestPayloadType) => void
  fetchResetFilterStates: (action: keyof FilterState) => void
}

export const useFilter = (): FilterType => {
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(getOrderStatusLoading);
  const isExcelDownloading: boolean = useSelector(getExcelDownLoading);
  const isOrderDetailsloading: boolean = useSelector(getOrderDetailsloading);
  const error: boolean = useSelector(getOrderStatusError);
  const status: statusItemType[] = useSelector(getOrderStatus);
  const machineTypes: statusItemType[] = useSelector(getMachineTypes);
  const machineBreakdownReason: statusItemType[] = useSelector(getMachineBreakDownReason);
  const pageSize: number = useSelector(getPageSize);
  const states: stateItemType[] = useSelector(getAllStates);
  const clustors: clustorItemType[] = useSelector(getClustors);
  const operators: OperatorListItemType[] = useSelector(getOperatorList);
  const sdos: SDOItemType[] = useSelector(getSDO);
  const machines: MachineItemType[] = useSelector(getMachine);
  const districts: DistrictItemType[] = useSelector(getDistrict);
  const geoHierarchyDistricts: GeoHierarchyDistrictType[] = useSelector(getGeoHierarchyDistricts);
  const orderDetails: OrderDetailsType = useSelector(getOrderDetails);
  const allOrderDetails: OrderDetailsType = useSelector(getAllOrderDetails);
  const filterRequest: filterRequestPayloadType = useSelector(getFilterRequest);

  const fetchOrderStatus = (action: getOrderStatusRequestPayload) => {
    dispatch(getOrder(action));
  };

  const fetchAllStates = () => {
    dispatch(getStates());
  };

  const fetchClustors = (action: getClustorRequestPayload) => {
    dispatch(getClustorAction(action));
  };

  const fetchSDOs = (action: getSDORequestPayload) => {
    dispatch(getSDOs(action));
  };

  const fetchMachines = (action: getMachineRequestPayload) => {
    dispatch(getMachineActions(action));
  };

  const fetchOrderDetails = (action: getOrderDetailsRequestPayload) => {
    dispatch(getOrderDetailsAction(action));
  };

  const setFilterRequest = async (action: filterRequestPayloadType) => {
    dispatch(setFilterRequestAction(action));
  };

  const fetchFilterRequest = () => {
    dispatch(getFilterRequestAction());
  };

  const fetchResetFilterStates = (action: keyof FilterState) => {
    dispatch(resetFilterStatesAction(action));
  };

  const fetchOperators = (action: getOperatorListRequestPayload) => {
    dispatch(getOpearatorListAction(action));
  };

  const fetchGeoHierarchyDistricts = (action: getGeoHierarchyDistrictsRequestPayload) => {
    dispatch(getGeoHierarchyActions(action));
  };

  const fetchDistricts = (action: getDistrictRequestPayload) => {
    dispatch(getDistrictActions(action));
  };

  return {
    pageSize,
    error,
    isLoading,
    status,
    states,
    clustors,
    operators,
    sdos,
    machineTypes,
    machineBreakdownReason,
    districts,
    geoHierarchyDistricts,
    machines,
    orderDetails,
    allOrderDetails,
    filterRequest,
    isExcelDownloading,
    isOrderDetailsloading,
    fetchOrderStatus,
    fetchAllStates,
    fetchClustors,
    fetchDistricts,
    fetchSDOs,
    fetchMachines,
    fetchOrderDetails,
    setFilterRequest,
    fetchFilterRequest,
    fetchResetFilterStates,
    fetchOperators,
    fetchGeoHierarchyDistricts
  };
};
