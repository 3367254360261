import { all, call, put, takeLatest } from 'redux-saga/effects';
import { addUserService, getUserListService, getUserService, updateUserService } from '../../services/onBoard';
import { addUserFailed, addUserSuccess, getUserFailed, getUserListFailed, getUserListSuccess, getUserSuccess, updateUserFailed, updateUserSuccess } from './action';
import { ADD_USER, GET_USER, GET_USER_LIST, UPDATE_USER } from './actionType';
import { type GetUserType, type UpdateUserType, type AddUserType, type GetUserListType, type userItemType, type getUserListSucessPayloadType } from './types';

function * getUserList (action: GetUserListType) {
  try {
    const response: getUserListSucessPayloadType = yield call(getUserListService, action.payload);
    yield put(getUserListSuccess({ ...response }));
  } catch (error: any) {
    const { message } = error;
    yield put(getUserListFailed({ error: true, errorMessage: message }));
  }
}

function * addUser (action: AddUserType) {
  try {
    const response: string = yield call(addUserService, action.payload);
    yield put(addUserSuccess({ message: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(addUserFailed({ error: true, errorMessage: message }));
  }
}

function * getUser (action: GetUserType) {
  try {
    const response: userItemType = yield call(getUserService, action.payload);
    yield put(getUserSuccess({ user: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(getUserFailed({ error: true, errorMessage: message }));
  }
}

function * updateUser (action: UpdateUserType) {
  try {
    const response: string = yield call(updateUserService, action.payload);
    yield put(updateUserSuccess({ message: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(updateUserFailed({ error: true, errorMessage: message }));
  }
}

function * onBoardSaga () {
  yield all([takeLatest(GET_USER_LIST, getUserList)]);
  yield all([takeLatest(ADD_USER, addUser)]);
  yield all([takeLatest(GET_USER, getUser)]);
  yield all([takeLatest(UPDATE_USER, updateUser)]);
}
export default onBoardSaga;
