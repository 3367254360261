import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../common/button';
import CustomSelectBox from '../../common/customSelectBox';
import CustomizedDialog from '../../common/dialog';
// import CustomRadioButtonsGroup from '../../common/radioInputField';
import TextInputField from '../../common/textInputField';
// import ToastMessage from '../../common/toastMessage'
import ToggleSwitch from '../../common/toggleSwitch';
import { RegEx, ROLES } from '../../constants';
import { useFilter } from '../../hooks';
import { useOnBoard } from '../../hooks/onBoard';
import {
  type updateUserRequestPayload,
  type addUserRequestPayload,
  type userRole
} from '../../redux/onBoard/types';
import { fontFamilies } from '../../resources/fonts';
import { useStyles } from './styles';

interface operatorDetailsType {
  userId: string
  cluster: string
  clusterIds: string
  district: string
  districtId: string
  licenseImage: any
  licenseNumber: string
  machineId: string
  machineType: string
  machineTypeId: string
  mobileNo: string
  name: string
  state: string
  stateId: string
  status: string
  userRole: keyof userRole
}

const operatorDetailsInit: operatorDetailsType = {
  userId: '',
  cluster: '',
  clusterIds: '',
  district: '',
  districtId: '',
  licenseImage: null,
  licenseNumber: '',
  machineId: '',
  machineType: '',
  machineTypeId: '',
  mobileNo: '',
  name: '',
  state: '',
  stateId: '',
  status: 'Active',
  userRole: ROLES?.OPERATOR
};

interface AddOperatorProps {
  view: boolean
  viewLicenceImage: boolean
  data: operatorDetailsType
  setView: (e: any) => void
  setShowDialog: (e: any) => void
}

const AddOperator: React.FC<AddOperatorProps> = ({
  view,
  data,
  viewLicenceImage = false,
  setView,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const {
    states,
    machineTypes,
    clustors,
    districts,
    machines,
    fetchAllStates,
    fetchClustors,
    fetchDistricts,
    fetchMachines
  } = useFilter();
  const {
    isLoading,
    errorMessage,
    addUser,
    updateUser,
    fetchResetOnboardStates
  } = useOnBoard();

  // file reference
  const inputFileRef = useRef<any>(null);

  // states
  const [operatorDetails, setOperatoeDetails] =
    useState<operatorDetailsType>(operatorDetailsInit);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  // effects
  useEffect(() => {
    const tempData: operatorDetailsType = {
      ...data,
      cluster: data?.cluster || '',
      clusterIds: data?.clusterIds ? String([data?.clusterIds]) : '',
      district: data?.district || '',
      districtId: data?.districtId ? String(data?.districtId) : '',
      licenseImage: `data:image/png;base64,${data?.licenseImage}`,
      licenseNumber: data?.licenseNumber || '',
      machineId: data?.machineId ? String(data?.machineId) : '',
      machineType: data?.machineType || '',
      machineTypeId: data?.machineTypeId ? String(data?.machineTypeId) : '',
      mobileNo: data?.mobileNo || '',
      name: data?.name,
      state: data?.state || '',
      stateId: data?.stateId ? String(data?.stateId) : '',
      status: data?.status,
      userRole: ROLES?.OPERATOR,
      userId: data?.userId
    };
    setOperatoeDetails(tempData);
  }, [data]);

  useEffect(() => {
    fetchAllStates();
    fetchDistricts({ clusterIds: [] });
  }, []);

  // useEffect(() => {
  //   setOperatoeDetails({
  //     ...operatorDetails,
  //     machineTypeId: String(status[0].key)
  //   })
  // }, [operatorDetails?.machineId])

  useEffect(() => {
    setOperatoeDetails({ ...operatorDetails, licenseImage: null });
  }, [!view && !edit]);

  useEffect(() => {
    if (operatorDetails?.stateId) {
      fetchClustors({ statesIds: [operatorDetails?.stateId] });
    }
  }, [operatorDetails?.stateId]);

  useEffect(() => {
    if (operatorDetails?.clusterIds) {
      fetchDistricts({ clusterIds: [operatorDetails?.clusterIds] });
      fetchMachines({ sdoIds: [], clusterIds: [operatorDetails?.clusterIds] });
    }
  }, [operatorDetails?.clusterIds]);

  useEffect(() => {
    setTimeout(() => {
      fetchResetOnboardStates('errorMessage');
    }, 5000);
  }, [errorMessage]);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent?.name === 'clusterIds' || inputEvent?.name === 'stateId') {
      setOperatoeDetails({
        ...operatorDetails,
        districtId: '',
        machineId: '',
        clusterIds: '',
        [inputEvent?.name]: inputEvent?.value
      });
    } else if (inputEvent?.name === 'machineId') {
      setOperatoeDetails({
        ...operatorDetails,
        machineTypeId: String(machineTypes[0].key),
        [inputEvent?.name]: inputEvent?.value
      });
    } else {
      setOperatoeDetails({
        ...operatorDetails,
        [inputEvent?.name]: inputEvent?.value
      });
    }
  };

  const onSwitchHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    setOperatoeDetails({
      ...operatorDetails,
      [inputEvent?.name]: inputEvent?.checked ? 'Active' : 'InActive'
    });
  };

  const toBase64 = async (file: any) =>
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      // reader.readAsArrayBuffer(file)
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

  const onFilechangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && (await toBase64(e?.target?.files[0]))) {
      const bse64: any = await toBase64(e?.target?.files[0]);
      console.log(bse64);
    }
    setFileSizeError(false);
    if (
      e.target.files &&
      e?.target?.files[0] &&
      e?.target?.files[0]?.size > 3000000
    ) {
      setFileSizeError(true);
      return;
    }
    if (e.target.files) {
      const bse64: any = await toBase64(e?.target?.files[0]);
      setOperatoeDetails({
        ...operatorDetails,
        licenseImage: bse64
      });
    }
  };

  const onFileUploadHandler = () => {
    inputFileRef.current.value = null;
    if (inputFileRef) {
      inputFileRef?.current?.click();
    }
  };
  const onClickResetHandler = () => {
    setOperatoeDetails({ ...operatorDetailsInit });
  };

  const onClickAddOperatorHandler = () => {
    const UserDetails: addUserRequestPayload = {
      cluster: operatorDetails?.cluster || null,
      clusterIds: [operatorDetails?.clusterIds],
      district: operatorDetails?.district || null,
      districtId: Number(operatorDetails?.districtId),
      licenseImage: operatorDetails?.licenseImage?.split(',')[1] || null,
      licenseNumber: operatorDetails?.licenseNumber || null,
      machineId: operatorDetails?.machineId || null,
      machineType: operatorDetails?.machineType,
      machineTypeId: Number(operatorDetails?.machineTypeId) || null,
      mobileNo: operatorDetails?.mobileNo || null,
      name: operatorDetails?.name,
      state: operatorDetails?.state || null,
      stateId: Number(operatorDetails?.stateId),
      status: operatorDetails?.status,
      userRole: ROLES?.OPERATOR
    };
    addUser({ ...UserDetails });
  };

  const onClickUpdateOperatorHandler = () => {
    setShowConfirmationDialog(true);
  };
  const onUpdateUserHandler = () => {
    setShowConfirmationDialog(false);
    const UserDetails: updateUserRequestPayload = {
      ...data,
      cluster: operatorDetails?.cluster || null,
      clusterIds: [operatorDetails?.clusterIds],
      district: operatorDetails?.district || null,
      districtId: Number(operatorDetails?.districtId),
      licenseImage: operatorDetails?.licenseImage?.split(',')[1] || null,
      licenseNumber: operatorDetails?.licenseNumber || null,
      machineId: operatorDetails?.machineId || null,
      machineType: operatorDetails?.machineType,
      machineTypeId: Number(operatorDetails?.machineTypeId) || null,
      mobileNo: operatorDetails?.mobileNo || null,
      name: operatorDetails?.name,
      state: operatorDetails?.state || null,
      stateId: Number(operatorDetails?.stateId),
      status: operatorDetails?.status,
      userRole: ROLES?.OPERATOR,
      userId: data?.userId
    };
    updateUser({ ...UserDetails });
  };

  const deleteUploadedFileHandler = () => {
    inputFileRef.current.value = null;
    setOperatoeDetails({ ...operatorDetails, licenseImage: '' });
  };

  const isAddOperatorDisable = () => {
    return !(
      operatorDetails.name &&
      operatorDetails?.mobileNo?.length === 10 &&
      // operatorDetails.licenseNumber &&
      operatorDetails.stateId &&
      operatorDetails.clusterIds
      // operatorDetails.licenseImage
    );
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1000;
    const dm = 1;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <>
      {(view || viewLicenceImage) && isLoading
        ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '25%' }}
        >
          <CircularProgress></CircularProgress>
        </div>
          )
        : viewLicenceImage
          ? (
              data?.licenseImage
                ? <> <img
          src={operatorDetails?.licenseImage}
          alt="driving licence"
          height={'70%'}
          width={'100%'}
          className={classes.licenseImage}
    />
    <div style={{ position: 'absolute', top: 0, right: 50, padding: 20 }}><a color='red !important' download={`${operatorDetails?.name}_${operatorDetails?.licenseNumber}.png`} href={operatorDetails?.licenseImage}>Download</a></div></>
                : null)
          : (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                <TextInputField
                  placeholder="Enter"
                  label="Name"
                  value={operatorDetails?.name}
                  onChange={onChangeHandler}
                  view={view}
                  name="name"
                  regExPattern={RegEx.TextwithSpaces}
                  maxLength={50}
                  required
                />
              </Box>
            </div>

            <Box className={classes.inputElementBox}>
              <TextInputField
                placeholder="Enter"
                label="Mobile Number"
                regExPattern={RegEx.OnlyDigits}
                value={operatorDetails?.mobileNo}
                onChange={onChangeHandler}
                view={view}
                name="mobileNo"
                maxLength={10}
                required
              />
            </Box>

            <Box className={classes.inputElementBox}>
              <TextInputField
                placeholder="Enter Licence Number"
                label="Driving / Pilot Licence Number"
                value={operatorDetails?.licenseNumber}
                regExPattern={RegEx.Alphnumeric}
                onChange={onChangeHandler}
                view={view}
                name="licenseNumber"
                maxLength={50}
              />
            </Box>
          </div>
          <div
            className={classes.operatorInfoContainer}
            style={{ marginTop: '-16px' }}
          >
            <Box
              className={classes.inputElementBox}
              style={{ display: 'flex' }}
            >
              {view
                ? (
                <div style={{ flexDirection: 'column' }}>
                  <div className={classes.viewLabel}>Licence Image</div>
                  <img
                    // onClick={() => { setShowViewImageAndDownloadDialog(true) }}
                    src={operatorDetails?.licenseImage}
                    alt="driving licence"
                    height={100}
                    width={200}
                    className={classes.licenseImage}
                  />
                </div>
                  )
                : (
                <>
                  <div>
                    <CustomButton
                      label="Upload Licence Number"
                      onClick={onFileUploadHandler}
                      startIcon={
                        <img
                          src={`${process.env.PUBLIC_URL}/camera.png`}
                          alt="camera"
                        />
                      }
                      customStyle={classes.fileUploadButton}
                    ></CustomButton>
                    {fileSizeError && (
                      <p className={classes.fileSizeError}>
                        File size exceeds maximum limit 3 MB.
                      </p>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={inputFileRef}
                    accept=".jpg, .jpeg, .png"
                    onChange={onFilechangeHandler}
                    style={{ display: 'none' }}
                  />
                  {operatorDetails?.licenseImage && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '-1px'
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/image_logo.png`}
                        alt="image-icon"
                      />
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '0px 20px 0px 12px'
                        }}
                      >
                        <label className={classes.fileName}>
                          Driving licence
                        </label>
                        <label
                          style={{
                            ...fontFamilies.regular1218,
                            color: '#A3A9B9'
                          }}
                        >
                          {formatFileSize(
                            (operatorDetails?.licenseImage.length / 4) * 3
                          )}
                        </label>
                      </Box>
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={deleteUploadedFileHandler}
                        src={`${process.env.PUBLIC_URL}/delete.png`}
                        alt="image-icon"
                      />
                    </Box>
                  )}
                </>
                  )}
            </Box>
          </div>
          <div className={classes.locationContainer}>
            <h3 className={classes.locationTitle}>Location Details</h3>
            <div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="State"
                    placeholder="Select"
                    view={view}
                    value={view ? data?.state : operatorDetails?.stateId}
                    onChange={onChangeHandler}
                    name="stateId"
                    options={states}
                    optionLabel="value"
                    optionValue="id"
                    required
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="Cluster"
                    placeholder="Select"
                    view={view}
                    value={view ? data?.cluster : operatorDetails?.clusterIds}
                    onChange={onChangeHandler}
                    name="clusterIds"
                    options={clustors && clustors[0]?.keyValueDto}
                    optionLabel="value"
                    optionValue="id"
                    disable={!operatorDetails?.stateId}
                    required
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="District"
                    view={view}
                    value={view ? data?.district : operatorDetails?.districtId}
                    onChange={onChangeHandler}
                    name="districtId"
                    options={districts && districts[0]?.keyValueDto}
                    optionLabel="value"
                    optionValue="id"
                    disable={!operatorDetails?.clusterIds}
                    placeholder="Select"
                  />
                </Box>
              </div>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="Machine ID"
                    placeholder="Select"
                    view={view}
                    value={view ? data?.machineId : operatorDetails?.machineId}
                    onChange={onChangeHandler}
                    options={machines && machines[0]?.keyValueDto}
                    optionLabel="value"
                    optionValue="machineId"
                    disable={!operatorDetails?.clusterIds}
                    name="machineId"
                  />
                </Box>

                {/* {operatorDetails?.machineId && (
                  <Box className={classes.inputElementBox}>
                    <CustomRadioButtonsGroup
                      label="Machine Type"
                      value={
                        view
                          ? data?.machineType
                          : operatorDetails?.machineTypeId
                      }
                      onChange={onChangeHandler}
                      view={view}
                      name="machineTypeId"
                      options={machineTypes}
                      optionLabel="value"
                      optionValue="key"
                    />
                  </Box>
                )} */}
                <Box className={[classes.inputElementBox, classes?.toggleSwitchContainer].join(' ')}>
                  <ToggleSwitch
                    value={operatorDetails?.status === 'Active'}
                    onChange={onSwitchHandler}
                    view={view}
                    leftLabel='Status'
                    rightLabel='Active'
                    name="status"
                  />
                </Box>
                {/* {!operatorDetails?.machineId && ( */}
                  <Box className={classes.inputElementBox}></Box>
                {/* )} */}
              </div>
            </div>
            {view
              ? (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Edit"
                  onClick={() => {
                    setView(false);
                    setEdit(true);
                  }}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
                )
              : (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Reset"
                  onClick={onClickResetHandler}
                  disabled={
                    JSON.stringify(operatorDetailsInit) ===
                    JSON.stringify(operatorDetails)
                  }
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label={edit ? 'Update' : 'Add Operator'}
                  disabled={isAddOperatorDisable()}
                  onClick={
                    edit
                      ? onClickUpdateOperatorHandler
                      : onClickAddOperatorHandler
                  }
                  customStyle={classes.applyButton}
                  startIcon={
                    isLoading
                      ? (
                      <CircularProgress size={16} color="inherit" />
                        )
                      : null
                  }
                ></CustomButton>
              </div>
                )}
          </div>
          <CustomizedDialog
            showDialog={showConfirmationDialog}
            setShowDialog={() => {
              setShowConfirmationDialog(!showConfirmationDialog);
            }}
            title={'Confirmation'}
          >
            <div className={classes.confirmcontainer}>
              <div className={classes.confirmLabelText}>
                Are you sure you want to save the changes?
              </div>
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowConfirmationDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Save"
                  onClick={onUpdateUserHandler}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
            </div>
          </CustomizedDialog>
        </>
            )}
    </>
  );
};

export default AddOperator;
