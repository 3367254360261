export const PHONE_COUNTRY_CODE_IN: string = '91';
export const COUNTRY_CODE_IN: string = 'IN';
export const LANGUAGE_CODE_EN: string = 'en';

export const COMMON_HEADERS = {
  defaultLanguageCode: LANGUAGE_CODE_EN,
  defaultCountryCode: COUNTRY_CODE_IN
};

export const OTP_LOCALSTORAGE_KEY: string = 'timestamp';

export const RegEx = {
  ValidPhoneNumber: /^[0-9]{10}$/,
  OnlyDigits: /^[0-9]*$/,
  PhoneNumber: /^[0-9]{0,10}$/,
  TextwithSpaces: /^[a-zA-Z ]*$/,
  Alphnumeric: /^[a-zA-Z0-9- ]*$/
};

export const BOOKING_STATUS_TYPES = {
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  SERVICE_BOOKED: 'Service Booked',
  REJECTED: 'Rejected',
  CONFIRMED: 'Confirmed'
};

export enum ROLES {
  ADMIN = 'ADMIN',
  SDO = 'SDO',
  VENDOR_ADMIN = 'VENDOR_ADMIN',
  OPERATOR = 'OPERATOR',
  DM = 'DM',
  TM = 'TM',
  BM = 'BM',
  NSM = 'NSM'
}
