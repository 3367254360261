import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  label: {
    ...fontFamilies.semiBold1420
  },
  viewLabel: {
    ...fontFamilies.regular1218
  },
  valueLabel: {
    ...fontFamilies.medium1621,
    wordBreak: 'break-word'

  },
  displayNone: {
    display: 'none !important',
    ...fontFamilies.regular1419,
    color: COLORS.Nuetral_100
  },
  options: {
    marginTop: '7px',
    paddingLeft: '5px',
    paddingRight: '5px',
    whiteSpace: 'nowrap'
  },
  optionContainer: {
    padding: '7px 15px 3px 15px',
    width: 'min-content',
    minWidth: '160px',
    maxHeight: '300px',
    overflow: 'scroll'
  },
  inputBox: {
    '& .MuiOutlinedInput-notchedOutline': {
      ...fontFamilies.regular1422,
      color: COLORS.Nuetral_20,
      marginTop: '5px !important',
      // height: '50px'
      padding: '10px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
      ...fontFamilies.regular1422
    },
    '& .MuiMenuItem-root': {
      ...fontFamilies.regular1422
    }
  },
  searchTextInput: {
    padding: '10px 10px 0px'
  }
}));
