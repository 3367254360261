import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  tabs: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#F3F4F6 !important'
    },
    '& .MuiTabs-flexContainer': {
      // marginBottom: '1rem !important',
      borderBottom: '1px solid #0071CD !important'
    },
    '& .MuiTab-root': {
      minWidth: '180px !important',
      ...fontFamilies.regular1820,
      color: '#A3A9B9 !important',
      textTransform: 'none !important',
      marginBottom: '-1px !important',
      background: '0 0 !important',
      border: '1px solid transparent',
      borderTopLeftRadius: '8px !important',
      borderTopRightRadius: '8px !important',
      // color: '#495057',
      borderColor: '#dee2e6 #dee2e6 #0071CD !important',
      marginRight: '10px !important',
      backgroundColor: '#F3F4F6 !important'
    },
    '& .MuiTab-root.Mui-selected': {
      ...fontFamilies.semiBold1820,
      color: '#495057',
      // backgroundColor: '#fff',
      borderColor: '#0071CD #0071CD #F3F4F6 !important',
      borderTop: '7px solid #0071CD !important',
      backgroundColor: '#F3F4F6 !important'
    },
    '& .MuiTabs-indicator': {
      height: '0px !important',
      color: '#495057 !important',
      backgroundColor: '#F3F4F6 !important',
      borderColor: '#dee2e6 #dee2e6 #fff !important'
    }
  },
  tabContainer: {
    marginTop: '-1px',
    border: '1px solid transparent !important',
    borderColor: '#ffff #0071CD #0071CD !important',
    // padding: '20px',
    backgroundColor: '#F3F4F6 !important',
    // maxHeight: 'calc(100vh - 260px) !important',
    // minHeight: 'calc(100vh - 260px) !important',
    display: 'flex',
    flexDirection: 'column'
  }
  // tabContainer:{
  //   '& .MuiTabs-flexContainer css-heg063-MuiTabs-flexContainer':{
  //     marginBottom: '1rem !important',
  //     borderBottom: '1px solid #dee2e6 !important'
  //   },
  //   '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected':{
  //     color: '#495057',
  //     backgroundColor: '#fff',
  //     borderColor: '#dee2e6 #dee2e6 #fff'
  //  },
  //  '& .css-1aquho2-MuiTabs-indicator':{

  //  },
  //  '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root':{

  //  }
  // }
}));
