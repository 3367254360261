import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  container: {
    textAlign: 'start'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  otpTextHelper: {
    fontSize: '30px',
    fontWeight: '700',
    marginBottom: 0
  },
  resendOtp: {
    fontSize: '14px',
    fontWeight: 700,
    marginLeft: '4px'
  },
  otpNotReceived: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#646464'
  },
  adminHelper: {
    fontSize: '40px',
    fontWeight: '400',
    paddingLeft: '8px'
  },
  mobileNumberLabel: {
    fontSize: '16px',
    fontWeight: 700,
    margin: '0px 0px 8px'
  },
  textFieldHelper: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 'unset'
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    }
  },
  disabledTextFieldHelper: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 'unset',
      border: '1px solid #EB4B4B'
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px'
    }
  },
  countryCodeHelper: { width: '64px', marginRight: '16px' },
  buttonHelper: { background: `${COLORS.Navy}`, borderRadius: 'unset' },
  actionContainer: { marginTop: '16px' },
  loginImage: {
    display: 'flex'
  },
  secureLoginImage: {
    marginTop: '8px',
    textAlign: 'center'
  },
  resendContainer: {
    textAlign: 'center',
    display: 'inline-flex',
    width: '-webkit-fill-available',
    justifyContent: 'center'
  },
  editNumberHelper: {
    fontSize: 16,
    fontWeight: 400,
    color: COLORS.LabelGray
  },
  resendButton: {
    '&.MuiButton-root': {
      textTransform: 'initial',
      textDecoration: 'underline',
      padding: 0,
      color: `${COLORS.Navy}`,
      lineHeight: 1,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  wrongOtpText: {
    ...fontFamilies.regular1620,
    color: COLORS.ErrorRed,
    padding: '0px'
  },
  wrongTextContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
