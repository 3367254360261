import {
  GET_ORDER_STATUS,
  GET_ORDER_STATUS_FAILED,
  GET_ORDER_STATUS_SUCCESS
} from './actionType';
import {
  type GetOrderStatusFailedType,
  type GetOrderStatusSuccessType,
  type GetOrderStatusType,
  type OrderStatusFailedPayloadtype,
  type OrderStatusType
} from './types';

export const getOrderStatus = (): GetOrderStatusType => {
  return {
    type: GET_ORDER_STATUS
  };
};

export const getOrderStatusSuccess = (
  payload: OrderStatusType
): GetOrderStatusSuccessType => {
  return {
    type: GET_ORDER_STATUS_SUCCESS,
    payload
  };
};

export const getOrderStatusFailed = (
  payload: OrderStatusFailedPayloadtype
): GetOrderStatusFailedType => {
  return {
    type: GET_ORDER_STATUS_FAILED,
    payload
  };
};
