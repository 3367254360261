import {
  GET_ALL_DETAILS,
  GET_ALL_DETAILS_SUCCESS,
  GET_ALL_DETAILS_FAILED
} from './actionTypes';
import {
  type DashboardDetailsType,
  type ErrorType,
  type GET_DETAILS_REQUEST_FAILURE_TYPE,
  type GET_DETAILS_REQUEST_SUCCESS_TYPE,
  type GET_DETAILS_REQUEST_TYPE,
  type GetAllDetailsRequestPayload
} from './types';

export const getAllDetails = (payload: GetAllDetailsRequestPayload): GET_DETAILS_REQUEST_TYPE => {
  return {
    type: GET_ALL_DETAILS,
    payload
  };
};

export const getAllDetailsSuccess = (payload: DashboardDetailsType): GET_DETAILS_REQUEST_SUCCESS_TYPE => {
  return {
    type: GET_ALL_DETAILS_SUCCESS,
    payload
  };
};

export const getAllDetailsFailure = (payload: ErrorType): GET_DETAILS_REQUEST_FAILURE_TYPE => {
  return {
    type: GET_ALL_DETAILS_FAILED,
    payload
  };
};
