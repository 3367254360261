enum FONT_FAMILY {
  NotoSansLight = 'NotoSansLight',
  NotoSansBold = 'NotoSansBold',
  NotoSansSemiBold = 'NotoSansSemiBold',
  NotoSansRegular = 'NotoSansRegular',
}

enum FontWeight {
  REGULAR = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700',
  EXTRA_BOLD = '800',
}

enum FontStyle {
  NORMAL = 'normal',
}

export const fontFamilies = {
  regular1016: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '10px',
    lineHeight: '16px'
  },
  regular1218: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '12px',
    lineHeight: '18px'
  },
  regular1622: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.REGULAR} !important`,
    fontSize: '16px !important',
    lineHeight: '22px !important'
  },
  regular1616: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '14px'
  },
  regular1620: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '14px',
    lineHeight: '20px !important'
  },

  regular1820: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '18px',
    lineHeight: '20px'
  },
  regular2020: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '20px',
    lineHeight: '20px'
  },
  regular2027: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.REGULAR,
    fontSize: '20px',
    lineHeight: '27px'
  },

  semiBold1016: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '10px',
    lineHeight: '16px'
  },
  semiBold1218: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '12px !important',
    lineHeight: '18px'
  },
  semiBold1622: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '16px',
    lineHeight: '22px'
  },
  semiBold1624: {
    fontFamily: `${FONT_FAMILY.NotoSansSemiBold} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.SEMI_BOLD} !important`,
    fontSize: '16px !important',
    lineHeight: '24px !important'
  },
  semiBold1820: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '18px',
    lineHeight: '20px'
  },
  semiBold1420: {
    fontFamily: `${FONT_FAMILY.NotoSansSemiBold}`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.SEMI_BOLD} !important`,
    fontSize: '14px !important',
    lineHeight: '20px !important'
  },
  regular1420: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.SEMI_BOLD} !important`,
    fontSize: '14px !important',
    lineHeight: '20px !important'
  },
  regular1422: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.REGULAR} !important`,
    fontSize: '14px !important',
    lineHeight: '22px !important'
  },
  semiBold2020: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '20px',
    lineHeight: '20px'
  },
  semiBold2027: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.SEMI_BOLD,
    fontSize: '20px',
    lineHeight: '27px'
  },

  bold1016: {
    fontFamily: FONT_FAMILY.NotoSansBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '10px',
    lineHeight: '16px'
  },
  bold1218: {
    fontFamily: FONT_FAMILY.NotoSansBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '12px',
    lineHeight: '18px'
  },
  bold1622: {
    fontFamily: FONT_FAMILY.NotoSansBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '16px',
    lineHeight: '22px'
  },
  bold1820: {
    fontFamily: FONT_FAMILY.NotoSansBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '18px',
    lineHeight: '20px'
  },
  bold2020: {
    fontFamily: FONT_FAMILY.NotoSansBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '20px',
    lineHeight: '20px'
  },
  boldSemiBold4820: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '48px',
    lineHeight: '20px'
  },
  boldSemiBold4020: {
    fontFamily: FONT_FAMILY.NotoSansSemiBold,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '40px',
    lineHeight: '20px'
  },
  boldRegular2420: {
    fontFamily: FONT_FAMILY.NotoSansRegular,
    fontStyle: FontStyle.NORMAL,
    fontWeight: FontWeight.BOLD,
    fontSize: '24px',
    lineHeight: '20px'
  },
  regular1419: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.MEDIUM} !important`,
    fontSize: '14px !important',
    lineHeight: '19px !important'
  },
  medium1621: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.MEDIUM} !important`,
    fontSize: '16px !important',
    lineHeight: '21px !important'
  },
  mediumSemiBold1621: {
    fontFamily: `${FONT_FAMILY.NotoSansRegular} !important`,
    fontStyle: `${FontStyle.NORMAL} !important`,
    fontWeight: `${FontWeight.SEMI_BOLD} !important`,
    fontSize: '16px !important',
    lineHeight: '21px !important'
  }
};
