import { useDispatch, useSelector } from 'react-redux';
import {
  getStates
} from '../redux/reAssign/selectors';
import { getMachineListRequest, resetReassignStates, setReassignStates, reAssignOrderListRequest } from '../redux/reAssign/action';
import { type reAssignOrderListRequestPayload, type getMachineListRequestPayload, type ReAssignState } from '../redux/reAssign/types';

interface ReAssignType extends ReAssignState {
  fetchMachineList: (action: getMachineListRequestPayload) => void
  fetchReAssignOrderList: (action: reAssignOrderListRequestPayload) => void
  resetReAssignStates: () => void
  fetchSetReAssignStates: (action: any) => void
}

export const useReAssign = (): ReAssignType => {
  const dispatch = useDispatch();
  // const isMachineListLoading: boolean = useSelector(getMachineListLoading)
  // const machineList: getMachineListSucessPayloadType = useSelector(getMachineList)
  // const error: boolean = useSelector(getMachineListError)

  const states: ReAssignState = useSelector(getStates);

  const fetchMachineList = (action: getMachineListRequestPayload) => {
    dispatch(getMachineListRequest(action));
  };

  const fetchReAssignOrderList = (action: reAssignOrderListRequestPayload) => {
    dispatch(reAssignOrderListRequest(action));
  };

  const resetReAssignStates = () => {
    dispatch(resetReassignStates());
  };

  const fetchSetReAssignStates = (action: any) => {
    dispatch(setReassignStates(action));
  };

  return {
    ...states,
    fetchMachineList,
    fetchReAssignOrderList,
    resetReAssignStates,
    fetchSetReAssignStates
  };
};
