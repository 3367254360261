import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_ALL_DETAILS
} from './actionTypes';
import {
  getAllDetailsSuccess,
  getAllDetailsFailure
} from './actions';
import { type DashboardDetailsType, type GET_DETAILS_REQUEST_TYPE } from './types';
import { getAllDetailsRequest } from '../../services/dashboardService';

function * getDashboardDetails (action: GET_DETAILS_REQUEST_TYPE) {
  try {
    const response: DashboardDetailsType = yield call(
      getAllDetailsRequest,
      action.payload
    );
    yield put(getAllDetailsSuccess(response));
  } catch (error: unknown) {
    yield put(getAllDetailsFailure({ message: 'get all details failed' }));
  }
}

function * dashboardDetailsSaga () {
  yield all([takeLatest(GET_ALL_DETAILS, getDashboardDetails)]);
}

export default dashboardDetailsSaga;
