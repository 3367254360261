import {
  GET_MACHINE_LIST,
  GET_MACHINE_LIST_SUCCESS,
  GET_MACHINE_LIST_FAILED,

  REASSIGN_ORDER_LIST,
  REASSIGN_ORDER_LIST_SUCCESS,
  REASSIGN_ORDER_LIST_FAILED,

  RESET_REASSIGN_STATES,

  SET_REASSIGN_STATES
} from './actionType';
import {
  type getMachineListRequestPayload,
  type GetMachineListType,
  type getMachineListSucessPayloadType,
  type GetMachineListSuccessType,
  type getMachineListFailedPayloadtype,
  type GetMachineListFailedType,
  type ReAssignState,
  type ResetReAssignStates,
  type setReAssignStates,
  type reAssignOrderListRequestPayload,
  type reAssignOrderListSucessPayloadType,
  type reAssignOrderListSuccessType,
  type reAssignOrderListFailedPayloadtype,
  type reAssignOrderListFailedType,
  type reAssignOrderListRequestType
} from './types';

// for get user list

export const getMachineListRequest = (payload: getMachineListRequestPayload): GetMachineListType => {
  return {
    type: GET_MACHINE_LIST,
    payload
  };
};

export const getMachineListSuccess = (
  payload: getMachineListSucessPayloadType
): GetMachineListSuccessType => {
  return {
    type: GET_MACHINE_LIST_SUCCESS,
    payload
  };
};

export const getMachineListFailed = (
  payload: getMachineListFailedPayloadtype
): GetMachineListFailedType => {
  return {
    type: GET_MACHINE_LIST_FAILED,
    payload
  };
};

// for reassign orders list

export const reAssignOrderListRequest = (payload: reAssignOrderListRequestPayload): reAssignOrderListRequestType => {
  return {
    type: REASSIGN_ORDER_LIST,
    payload
  };
};

export const reAssignOrderListSuccess = (
  payload: reAssignOrderListSucessPayloadType
): reAssignOrderListSuccessType => {
  return {
    type: REASSIGN_ORDER_LIST_SUCCESS,
    payload
  };
};

export const reAssignOrderListFailed = (
  payload: reAssignOrderListFailedPayloadtype
): reAssignOrderListFailedType => {
  return {
    type: REASSIGN_ORDER_LIST_FAILED,
    payload
  };
};

export const resetReassignStates = (): ResetReAssignStates => {
  return {
    type: RESET_REASSIGN_STATES
  };
};

export const setReassignStates = (
  payload: ReAssignState
): setReAssignStates => {
  return {
    type: SET_REASSIGN_STATES,
    payload
  };
};
