import {
  GET_ORDER_STATUS,
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_STATUS_FAILED,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAILED,
  GET_CLUSTORS,
  GET_CLUSTORS_SUCCESS,
  GET_CLUSTORS_FAILED,
  GET_SDO,
  GET_SDO_SUCCESS,
  GET_SDO_FAILED,
  GET_MACHINES,
  GET_MACHINES_SUCCESS,
  GET_MACHINES_FAILED,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILED,
  GET_FILTER_REQUEST,
  SET_FILTER_REQUEST,
  RESET_FILTER_STATES,
  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAILED,
  GET_OPERATOR_LIST,
  GET_OPERATOR_LIST_SUCCESS,
  GET_OPERATOR_LIST_FAILED,
  GET_GEO_HIERARCHY_DISTRICTS,
  GET_GEO_HIERARCHY_DISTRICTS_SUCCESS,
  GET_GEO_HIERARCHY_DISTRICTS_FAILED
} from './actionType';
import { type FilterState, type filterAction } from './types';

const setDate = (noOfDays: number): Date => {
  const date: Date = new Date();
  date.setDate(date.getDate() - noOfDays);
  return date;
};

const initialState: FilterState = {
  error: false,
  isLoading: false,
  isExcelDownloading: false,
  isOrderDetailsloading: false,
  status: [],
  machineTypes: [],
  machineBreakdownReason: [],
  states: [],
  operators: [],
  clustors: [],
  districts: [],
  geoHierarchyDistricts: [],
  sdos: [],
  machines: [],
  orderDetails: {
    pageNo: 0,
    pageSize: 10,
    serviceOrdersReportDTO: [],
    totalElements: 0,
    totalPages: 0
  },
  allOrderDetails: {
    pageNo: 0,
    pageSize: 10,
    serviceOrdersReportDTO: [],
    totalElements: 0,
    totalPages: 0
  },
  filterRequest: {
    pageNo: 0,
    pageSize: 10,
    isOrderDetailsFlag: false,
    startDate: setDate(7),
    endDate: setDate(0),
    dateRangeLabel: 'Last 7 Days',
    statusIds: [],
    statusNames: [],
    sprayTypeIds: [],
    sprayTypeNames: [],
    statesIds: [],
    stateNames: [],
    clusterIds: [],
    clusterNames: [],
    sdoIds: [],
    sdoNames: [],
    machineIds: [],
    machineNames: [],
    redFlag: false
  }
};

const defaultState: FilterState = JSON.parse(JSON.stringify(initialState));

export const reducer = (
  state = defaultState,
  action: filterAction
): FilterState => {
  switch (action.type) {
    case GET_ORDER_STATUS:
      return { ...state, isLoading: true };
    case GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: action?.payload?.type === 'SPRAY_BOOKING_STATUS' ? action?.payload?.orderStatus || [] : state.status || [],
        machineTypes: action?.payload?.type === 'SPRAY_MACHINE_TYPE' ? action?.payload?.orderStatus || [] : state?.machineTypes || [],
        machineBreakdownReason: action?.payload?.type === 'SPRAY_MACHINE_BREAKDOWN_REASON' ? action?.payload?.orderStatus || [] : state?.machineBreakdownReason || []
      };
    case GET_ORDER_STATUS_FAILED:
      return { ...state, isLoading: false, error: true };
    // for states
    case GET_ALL_STATES:
      return { ...state, isLoading: true };
    case GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action?.payload?.states || []
      };
    case GET_ALL_STATES_FAILED:
      return { ...state, isLoading: false, error: true };
    // for clutors
    case GET_CLUSTORS:
      return { ...state, isLoading: true };
    case GET_CLUSTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clustors: action?.payload?.clustors || []
      };
    case GET_CLUSTORS_FAILED:
      return { ...state, isLoading: false, error: true };

      // for clutors
    case GET_OPERATOR_LIST:
      return { ...state, isLoading: true };
    case GET_OPERATOR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operators: action?.payload?.operators || []
      };
    case GET_OPERATOR_LIST_FAILED:
      return { ...state, isLoading: false, error: true };

    // for SDOs
    case GET_SDO:
      return { ...state, isLoading: true };
    case GET_SDO_SUCCESS:
      return { ...state, isLoading: false, sdos: action?.payload?.sdos || [] };
    case GET_SDO_FAILED:
      return { ...state, isLoading: false, error: true };
    // for MAchines
    case GET_MACHINES:
      return { ...state, isLoading: true };
    case GET_MACHINES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        machines: action?.payload?.machines || []
      };
    case GET_MACHINES_FAILED:
      return { ...state, isLoading: false, error: true };
    // for Districts
    case GET_DISTRICTS:
      return { ...state, isLoading: true };
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        districts: action?.payload?.districts || []
      };
    case GET_DISTRICTS_FAILED:
      return { ...state, isLoading: false, error: true };
    // for Districts
    case GET_GEO_HIERARCHY_DISTRICTS:
      return { ...state, isLoading: true };
    case GET_GEO_HIERARCHY_DISTRICTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geoHierarchyDistricts: action?.payload?.geoHierarchyDistricts || []
      };
    case GET_GEO_HIERARCHY_DISTRICTS_FAILED:
      return { ...state, isLoading: false, error: true };
    // for get order details
    case GET_ORDER_DETAILS:
      return {
        ...state,
        isOrderDetailsloading: !state.filterRequest.isOrderDetailsFlag,
        isExcelDownloading: state.filterRequest.isOrderDetailsFlag,
        allOrderDetails: initialState.allOrderDetails,
        orderDetails: !state.filterRequest.isOrderDetailsFlag ? initialState.orderDetails : state?.orderDetails
      };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isOrderDetailsloading: false,
        isExcelDownloading: false,
        // orderDetails: action?.payload?.orderDetails || [],
        allOrderDetails: state.filterRequest.isOrderDetailsFlag
          ? action?.payload?.orderDetails
          : initialState.allOrderDetails,
        orderDetails: !state.filterRequest.isOrderDetailsFlag
          ? action?.payload?.orderDetails
          : state.orderDetails
      };
    case GET_ORDER_DETAILS_FAILED:
      return { ...state, isOrderDetailsloading: false, error: true, isExcelDownloading: false };
    // for get order details
    case GET_FILTER_REQUEST:
      return {
        ...state,
        filterRequest: {
          pageNo: 0,
          isOrderDetailsFlag: false,
          pageSize: state.filterRequest.pageSize,
          startDate: setDate(7),
          endDate: setDate(0),
          dateRangeLabel: 'Last 7 Days',
          statusIds: [],
          statusNames: [],
          sprayTypeIds: [],
          sprayTypeNames: [],
          statesIds: [],
          stateNames: [],
          clusterIds: [],
          clusterNames: [],
          sdoIds: [],
          sdoNames: [],
          machineIds: [],
          machineNames: [],
          redFlag: false
        }
      };
    case SET_FILTER_REQUEST:
      return { ...state, filterRequest: action?.payload };
    case RESET_FILTER_STATES:
      return { ...state, [action?.payload]: initialState[action?.payload] };
    default:
      return state;
  }
};

export default reducer;
