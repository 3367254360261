import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useStyles } from './styles';
import { Checkbox, ListItemText } from '@mui/material';
import SearchInput from '../searchInput';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface CustomMultiSelectBoxProps {
  label?: string
  placeholder?: string
  view?: boolean
  value?: any
  required?: boolean
  disable?: boolean
  options?: any[]
  optionLabel: string
  optionValue: string
  name?: string
  onChange: (selectedValues: any, name: string) => void
}
const CustomMultiSelectBox: React.FC<CustomMultiSelectBoxProps> = ({
  label,
  placeholder,
  view = false,
  disable = false,
  name,
  value = [],
  required,
  options,
  optionLabel,
  optionValue,
  onChange
}) => {
  const classes = useStyles();
  // const [age, setAge] = React.useState('')

  // const handleChange = (event: SelectChangeEvent) => {
  //   onChange(event);
  // }

  const [searchText, setSearchText] = useState<string>('');

  const renderValue = () => {
    let renderString: any = '';
    if (options) {
      renderString = options?.map((option: any) => value?.includes(option[optionValue]) ? option[optionLabel] : '').filter((opt: any) => opt);
      // renderString = typeof value === 'string' ? renderString : renderString.join(', ');
      renderString = typeof value === 'string' || renderString?.length === 1 ? renderString : `${renderString?.length} Selected`;
    }
    return renderString;
  };

  const handleSearchChange = (eventVal: string) => {
    setSearchText(eventVal?.toLowerCase());
  };

  const onOpenSelectHandler = () => {
    if (searchText?.length) { setSearchText(''); }
  };

  const handleChangeCheckbox = async (item: any) => {
    if (item) {
      const tempSelectedValues: any = [...value];
      const valueIndex = value?.indexOf(item[optionValue]);
      if (valueIndex === -1) {
        tempSelectedValues.push(item[optionValue]);
      } else {
        tempSelectedValues.splice(valueIndex, 1);
      }
      onChange(tempSelectedValues, name || '');
    }
  };

  return (
    <Box>
      <label className={view ? classes.viewLabel : classes.label}>
        {label}
        {required && <span> *</span>}
      </label>
      {view
        ? (
        <div className={classes.valueLabel}>{value}</div>
          )
        : (
        <FormControl fullWidth >
          <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
            className={classes.inputBox}
            inputProps={{ 'aria-label': 'Without label' }}
            // renderValue={value !== '' ? undefined : () => placeholder}
            renderValue={(selected: any) => {
              if (selected.length === 0) {
                return placeholder;
              }

              return renderValue();
            }}
            displayEmpty
            style={{ color: value?.length === 0 ? '#a3a4a8' : 'black' }}
            value={value}
            name={name}
            placeholder={placeholder}
            multiple
            disabled={disable}
            MenuProps={MenuProps}
            onOpen={onOpenSelectHandler}
          >
            {/* <MenuItem value="" disabled hidden>
            {placeholder}
          </MenuItem> */}
           <MenuItem disabled className={classes.displayNone} value={value}>
              {value}
            </MenuItem>
          {/* <div className={classes.optionContainer}> */}
          <div className={classes.searchTextInput}>
          <SearchInput
            onSearch={handleSearchChange}
            placeholder="Search"
            searchText={searchText}
          />
          </div>
          <div className={classes.options}>
            {options && options?.length > 0
              ? (
                  options?.filter((item: any) => item[optionLabel]?.toLowerCase().includes(searchText)
                  )?.map((option: any) => (
                <MenuItem
                  key={`selectopt_${option[optionValue]}`}
                  value={option[optionValue]}
                  onClick={async () => { await handleChangeCheckbox(option); }}
                >
                  {/* {option[optionLabel]} */}
                  <Checkbox checked={value.includes(option[optionValue])} />
                  <ListItemText primary= {option[optionLabel]} />
                </MenuItem>
                  ))
                )
              : (
              <div style={{ paddingLeft: '16px' }}>No data available</div>
                )}
             </div>
             {/* </div> */}
          </Select>

        </FormControl>
          )}
    </Box>
  );
};

export default CustomMultiSelectBox;
