import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { type SelectChangeEvent } from '@mui/material/Select';
// import { useFilter } from '../../hooks/useFilter';

interface RowsPerPageProps {
  rowsPerPage: number
  setRowsPerPage: (e: number) => void
}

const RowsPerPage: React.FC<RowsPerPageProps> = ({ rowsPerPage, setRowsPerPage }) => {
  // Classes
  const classes = useStyles();

  // const { filterRequest, filterRequest: { pageSize }, setFilterRequest, fetchOrderDetails } = useFilter();

  // States
  // const [rowsPerPage, setRowsPerPage] = useState<string>(`${pageSize} Rows`);

  // effects
  // useEffect(() => {
  //   fetchOrderDetails(filterRequest);
  // }, [filterRequest.pageSize]);

  // Handlers
  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(Number(event.target.value));
    // setFilterRequest({ ...filterRequest, pageSize: parseInt(event.target.value), pageNo: 0 });
  };

  return (
    <div className={classes.root}>
      Show:
      <FormControl>
        <Select
          value={`${rowsPerPage}`}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className={classes.selectHelper}
          onChange={handleChange}
        >
          {[10, 20, 30].map((rows, index) => {
            const value = `${rows}`;
            return (
              <MenuItem key={`${index}-${rows}`} value={value}>
                {`${value} Rows`}
              </MenuItem>
            );
          })
          })
        </Select>
      </FormControl>
    </div>
  );
};

export default RowsPerPage;
