import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SEND_OTP, VERIFY_OTP } from './actionType';
import {
  requestOtpSuccess,
  requestOtpFailed,
  requestVerifyOtpSuccess,
  requestVerifyOtpFailed
} from './actions';
import { type ErrorType, type SendOtpRequest, type VerifyOtpRequest, type VerifyOtpRequestSuccessPayload } from './types';
import { getOtp, verifyOtp } from '../../services/login';

function * sendOtp (action: SendOtpRequest) {
  try {
    const response: unknown = yield call(getOtp, action.payload);
    // yield delay(5000);
    console.log('response', response);
    yield put(requestOtpSuccess({ otpSent: true }));
  } catch (error: unknown) {
    const errorResponse = error as ErrorType;
    yield put(requestOtpFailed({ error: errorResponse }));
  }
}

// function* resetOtpSent() {
//   try {
//     yield put(resetOtp({ otpSent: false }));
//   } catch (err) {
//     console.log(err);
//   }
// }

function * verifyOtpRequest (action: VerifyOtpRequest) {
  try {
    const response: VerifyOtpRequestSuccessPayload = yield call(verifyOtp, action.payload);
    // yield delay(5000);
    console.log(response);
    yield put(requestVerifyOtpSuccess(response));
  } catch (error: unknown) {
    yield put(requestVerifyOtpFailed({ error: true }));
  }
}

function * loginSaga () {
  yield all([takeLatest(SEND_OTP, sendOtp)]);
  yield all([takeLatest(VERIFY_OTP, verifyOtpRequest)]);
}

export default loginSaga;
