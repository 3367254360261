import { Box, CircularProgress, type SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import CustomMultiSelectBox from '../../common/customMultiSelectBox';
import CustomSelectBox from '../../common/customSelectBox';
import TextInputField from '../../common/textInputField';
// import ToastMessage from '../../common/toastMessage'
import { RegEx } from '../../constants';
import { useFilter } from '../../hooks';
import { useCluster } from '../../hooks/useCluster';
import { useStyles } from './styles';

interface AddOperatorProps {
  view: boolean
  setView: (e: any) => void
  setShowDialog: (e: any) => void
}

const AddCluster: React.FC<AddOperatorProps> = ({
  view,
  setView,
  setShowDialog
}) => {
  // classes
  const classes = useStyles();

  // custom hooks
  const {
    states,
    geoHierarchyDistricts,
    fetchAllStates,
    fetchGeoHierarchyDistricts
  } = useFilter();
  const {
    isClusterListLoading,
    fetchResetClusterStates,
    fetchUpdateCluster,
    fetchSetClusterStates,
    fetchAddCluster,
    errorMessage,
    clusterDetails
  } = useCluster();

  // states
  const [edit, setEdit] = useState<boolean>(false);

  // effects
  useEffect(() => {
    fetchAllStates();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchResetClusterStates('errorMessage');
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    // fetchSetClusterStates({ clusterDetails: { ...clusterDetails, districtsIds: [] } });
    fetchGeoHierarchyDistricts({ stateId: clusterDetails?.stateId });
  }, [clusterDetails?.stateId]);

  // handlers
  const onChangeHandler = (event: React.ChangeEvent | SelectChangeEvent) => {
    const inputEvent = event.target as HTMLInputElement;
    if (inputEvent?.name === 'stateId') {
      fetchSetClusterStates({ clusterDetails: { ...clusterDetails, [inputEvent?.name]: inputEvent?.value, districtsIds: [], districtList: [], districtsNames: [] } });
    } else {
      fetchSetClusterStates({ clusterDetails: { ...clusterDetails, [inputEvent?.name]: inputEvent?.value } });
    }
  };

  const onMultiChangeHandler = (value: string[] | number [] | string, name: string) => {
    fetchSetClusterStates({
      clusterDetails: {
        ...clusterDetails,
        [name]: typeof value === 'string' ? value?.split(',') : value
      }
    });
  };

  const onClickResetHandler = () => {
    fetchResetClusterStates('clusterDetails');
  };

  const onClickAddOperatorHandler = () => {
    fetchAddCluster({ ...clusterDetails, districtList: clusterDetails?.districtsIds || [] });
  };

  const onClickUpdateOperatorHandler = () => {
    fetchUpdateCluster({ ...clusterDetails, districtList: clusterDetails?.districtsIds || [] });
  };

  const isAddOperatorDisable = () => {
    return !(
      clusterDetails?.clusterName &&
      clusterDetails?.districtsIds?.length &&
      clusterDetails?.stateId
    );
  };

  const isResetOperatorDisable = () => {
    return clusterDetails?.clusterName ||
      // clusterDetails?.districtList?.length &&
      clusterDetails?.stateId;
  };

  return (
    <>
      {view && isClusterListLoading
        ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '25%' }}
        >
          <CircularProgress></CircularProgress>
        </div>
          )
        : (
        <>
          <div className={classes.operatorInfoContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Box className={classes.inputElementBox}>
                <TextInputField
                  placeholder="Enter"
                  label="New Cluster"
                  value={clusterDetails?.clusterName}
                  onChange={onChangeHandler}
                  view={view}
                  name="clusterName"
                  regExPattern={RegEx.TextwithSpaces}
                  maxLength={500}
                  required
                />
              </Box>
            </div>
              <Box className={classes.inputElementBox}></Box>

          </div>

          <div className={classes.locationContainer}>
            <h3 className={classes.locationTitle}>Location Details</h3>
            <div>
              <div style={{ display: 'flex', flex: 1 }}>
                <Box className={classes.inputElementBox}>
                  <CustomSelectBox
                    label="State"
                    placeholder="Select"
                    view={view}
                    value={view ? clusterDetails?.stateName : clusterDetails?.stateId}
                    onChange={onChangeHandler}
                    name="stateId"
                    options={states}
                    optionLabel="value"
                    optionValue="id"
                    required
                  />
                </Box>
                <Box className={classes.inputElementBox}>
                  <CustomMultiSelectBox
                    label="District"
                    placeholder="Select"
                    view={view}
                    value={ view ? clusterDetails?.districtsNames?.join(',') : clusterDetails?.districtsIds}
                    // value={[]}
                    onChange={(values: string[] | number[] | string, name: string) => { onMultiChangeHandler(values, name); }}
                    name="districtsIds"
                    // disable={!clusterDetails?.stateId}
                    options={geoHierarchyDistricts || []}
                    optionLabel="districtName"
                    optionValue="districtId"
                    required
                  />
                </Box>
              </div>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              </div>
            </div>
            {view
              ? (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Edit"
                  onClick={() => {
                    setView(false);
                    setEdit(true);
                  }}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
                )
              : (
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="Reset"
                  onClick={onClickResetHandler}
                  disabled={!isResetOperatorDisable()}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label={edit ? 'Update' : 'Add Cluster'}
                  disabled={isAddOperatorDisable()}
                  onClick={
                    edit
                      ? onClickUpdateOperatorHandler
                      : onClickAddOperatorHandler
                  }
                  customStyle={classes.applyButton}
                  startIcon={
                    isClusterListLoading
                      ? (
                      <CircularProgress size={16} color="inherit" />
                        )
                      : null
                  }
                ></CustomButton>
              </div>
                )}
          </div>
        </>
          )}
    </>
  );
};

export default AddCluster;
