import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getMachineListBystatusService, saveMachineDetailsService, updateMachineDetailsService, updateMachineStatusService } from '../../services/machine';
import { getMachineListByStatusFailed, getMachineListByStatusSuccess, saveMachineDetailsFailed, saveMachineDetailsSuccess, updateMachineDetailsFailed, updateMachineDetailsSuccess, updateMachineStatusFailed, updateMachineStatusSuccess } from './action';
import { GET_MACHINE_LIST_BY_STATUS, SAVE_MACHINE_DETAILS, UPDATE_MACHINE_DETAILS, UPDATE_MACHINE_STATUS } from './actionType';
import { type saveMachineDetailsRequestType, type getMachineListByStatusRequestType, type getMachineListByStatusSucessPayloadType, type updateMachineStatusRequestType, type updateMachineStatusSucessPayloadType, type updateMachineDetailsRequestType, type updateMachineDetailsSucessPayloadType } from './types';

function * updateMachineStatus (action: updateMachineStatusRequestType) {
  try {
    const response: updateMachineStatusSucessPayloadType = yield call(updateMachineStatusService, action.payload);
    yield put(updateMachineStatusSuccess({ message: response?.message }));
  } catch (error: any) {
    const { message } = error;
    yield put(updateMachineStatusFailed({ error: true, errorMessage: message }));
  }
}

function * getMachineListBystatus (action: getMachineListByStatusRequestType) {
  try {
    const response: getMachineListByStatusSucessPayloadType = yield call(getMachineListBystatusService, action.payload);
    yield put(getMachineListByStatusSuccess({ ...response }));
  } catch (error: any) {
    const { message } = error;
    yield put(getMachineListByStatusFailed({ error: true, errorMessage: message }));
  }
}

function * saveMachineDetails (action: saveMachineDetailsRequestType) {
  try {
    const response: updateMachineStatusSucessPayloadType = yield call(saveMachineDetailsService, action.payload);
    yield put(saveMachineDetailsSuccess({ message: response?.message }));
  } catch (error: any) {
    const { message } = error;
    yield put(saveMachineDetailsFailed({ error: true, errorMessage: message }));
  }
}

function * updateMachineDetails (action: updateMachineDetailsRequestType) {
  try {
    const response: updateMachineDetailsSucessPayloadType = yield call(updateMachineDetailsService, action.payload);
    yield put(updateMachineDetailsSuccess({ message: response?.message }));
  } catch (error: any) {
    const { message } = error;
    yield put(updateMachineDetailsFailed({ error: true, errorMessage: message }));
  }
}

function * machineSaga () {
  yield all([takeLatest(UPDATE_MACHINE_STATUS, updateMachineStatus)]);
  yield all([takeLatest(GET_MACHINE_LIST_BY_STATUS, getMachineListBystatus)]);
  yield all([takeLatest(SAVE_MACHINE_DETAILS, saveMachineDetails)]);
  yield all([takeLatest(UPDATE_MACHINE_DETAILS, updateMachineDetails)]);
}
export default machineSaga;
