import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  dateInputBox: {
    ...fontFamilies.regular1419,
    textAlign: 'start',
    textTransform: 'uppercase',
    height: '29px',
    background: 'white',
    // height: '24px',
    // border: '1px solid #DFE2E7',
    border: '1px solid #868CA2',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    justifyContent: 'space-between',
    padding: '0px 5px 0px 15px',
    cursor: 'pointer',
    // backgroundColor: '#F3F4F6',
    color: COLORS.Nuetral_100,
    minWidth: '130px',
    maxWidth: '150px',

    '& .css-1ydptia-MuiButtonBase-root-MuiListItem-root': {
      // backgroundColor: '#0092E4 !important'
    },
    '& .css-xpa0r5-MuiButtonBase-root-MuiIconButton-root': {
      backgroundColor: '#82CFFF !important',
      borderRadius: '0px !important'
    },
    '& .MuiBox-root css-bc1jo5': {
      backgroundColor: '#82CFFF !important',
      borderRadius: '0px !important'
    },
    '& .css-1xisk3n': {
      backgroundColor: '#C6E6FF !important'
    }
  },
  calendarIcon: {
    padding: '7px'
  },
  datePickerPopup: {
    position: 'absolute',
    zIndex: 100
  }

}));
