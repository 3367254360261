import React, { useState } from 'react';
import { FormGroup, MenuItem, Select, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import SearchInput from '../searchInput';
import { useStyles } from './styles';

interface RenderCheckboxType {
  label: string
  item: any
  selectedIds: string[]
  selectedNames: string[]
  optionValue: string
  optionLabel: string
  options: any
  index: number
  setSelectedIds: (item: any, item1: any) => void
  // setSelectedNames: (item: any) => void;
}

const RenderCheckbox: React.FC<RenderCheckboxType> = ({
  label,
  item,
  selectedIds,
  selectedNames,
  optionValue,
  optionLabel,
  index,
  setSelectedIds,
  // setSelectedNames,
  options
}) => {
  const classes = useStyles();

  const handleChangeCheckbox = async (item: any) => {
    let tempSelectedIds = [...selectedIds];
    let tempSelectedNames = [...selectedNames];
    if (selectedIds.includes(item[optionValue])) {
      tempSelectedIds.splice(selectedIds.indexOf(item[optionValue]), 1);
      tempSelectedNames.splice(selectedNames.indexOf(label), 1);
    } else {
      tempSelectedIds = [...selectedIds, item[optionValue]];
      tempSelectedNames = [...selectedNames, label];
    }

    setSelectedIds([...tempSelectedIds], [...tempSelectedNames]);
    // setSelectedNames([...tempSelectedNames]);
  };

  const checkAllHandlar = () => {
    let allSelectedIds = [...options];
    let allSelectedNames = [...options];

    allSelectedIds = allSelectedIds.slice(1).map((e: any) => e[optionValue]);
    allSelectedNames = allSelectedNames.slice(1).map((e: any) => e[optionLabel]);

    if (selectedIds?.length === options?.length - 1) {
      setSelectedIds([], []);
    } else {
      setSelectedIds([...allSelectedIds], [...allSelectedNames]);
    }
  };

  return (
    <>
      {item[optionValue] === 0
        ? (
        <MenuItem className={classes.menuItems} value={label}>
          <FormGroup>
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  checked={selectedIds?.length === options?.length - 1}
                  indeterminate={
                    selectedIds?.length > 0 &&
                    selectedIds?.length !== options?.length - 1
                  }
                  onChange={checkAllHandlar}
                  className={classes.checkbox}
                />
              }
            />
          </FormGroup>
        </MenuItem>
          )
        : (
        <MenuItem className={classes.menuItems} value={label}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={async () => { await handleChangeCheckbox(item); }} />}
              label={label}
              checked={selectedIds.includes(item[optionValue])}
            />
          </FormGroup>
        </MenuItem>
          )}
    </>
  );
};

interface CheckboxInputProps {
  label: string
  options: any
  optionValue: string
  customStyle?: any
  optionLabel: string
  selectedIds: string[]
  selectedNames: string[]
  isDisabled?: boolean
  setSelectedIds: (item: any, item1: any) => void
}

const CheckBox: React.FC<CheckboxInputProps> = ({
  label,
  options,
  optionValue,
  customStyle,
  isDisabled,
  optionLabel,
  selectedIds,
  selectedNames,
  setSelectedIds
  // setSelectedNames,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const classes = useStyles();

  const handleSearchChange = (eventVal: string) => {
    setSearchText(eventVal?.toLowerCase());
  };

  const onOpenSelectHandler = () => {
    setSearchText('');
  };

  return (
    <Select
      value=""
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      className={[classes.selectBox, customStyle].join(' ')}
      disabled={!!isDisabled}
      onOpen={onOpenSelectHandler}
    >
      <MenuItem disabled className={classes.displayNone} value="">
        {label}
      </MenuItem>
      <div className={classes.optionContainer}>
        <SearchInput
          onSearch={handleSearchChange}
          placeholder="Search"
          searchText={searchText}
        />

        <div className={classes.options}>
          {options
            .filter((item: any) =>
              item[optionLabel]?.toLowerCase().includes(searchText)
            )
            ?.map((item: any, index: number) => (
              <RenderCheckbox
                key={`${index}-${item[optionValue]}`}
                label={item[optionLabel]}
                item={item}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                selectedNames={selectedNames}
                // setSelectedNames={setSelectedNames}
                optionValue={optionValue}
                optionLabel={optionLabel}
                options={options}
                index={index}
              />
            ))}
        </div>
      </div>
    </Select>
  );
};

export default CheckBox;
