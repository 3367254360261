import { type DASHBOARD_DETAILS_TYPE, type DashboardDetailsType } from './types';
import {
  GET_ALL_DETAILS,
  GET_ALL_DETAILS_SUCCESS,
  GET_ALL_DETAILS_FAILED,
  RESET_ALL_DETAILS
} from './actionTypes';

export interface DASHBOARD_STATE_TYPE extends DashboardDetailsType {
  loading: boolean

}

const initialState: DASHBOARD_STATE_TYPE = {
  loading: false,
  allSprayOrdersCount: '',
  statusCount: [],
  operatorsCount: [],
  topSprayCropRanks: [],
  topSprayProductRanks: [],
  totalAcreage: '',
  totalRevenue: '',
  machineStatusCount: []
};

const defaultState: DASHBOARD_STATE_TYPE = JSON.parse(
  JSON.stringify(initialState)
);

export default (state = defaultState, action: DASHBOARD_DETAILS_TYPE) => {
  switch (action.type) {
    case GET_ALL_DETAILS:
      return { ...state, loading: true };
    case GET_ALL_DETAILS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case GET_ALL_DETAILS_FAILED:
      return { ...state, loading: false, ...action.payload };
    case RESET_ALL_DETAILS:
      return JSON.parse(JSON.stringify(defaultState)) as DASHBOARD_STATE_TYPE;
    default:
      return state;
  }
};
