import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  operatorInfoContainer: {
    padding: '12px 16px 12px 16px',
    backgroundColor: '#F3F4F6',
    display: 'flex'
  },
  locationContainer: {
    padding: '12px 16px 12px 16px'
  },
  locationTitle: {
    ...fontFamilies.semiBold1622,
    margin: '0px 0px 4px 8px'
  },
  addButton: {
    backgroundColor: '#14803C !important',
    width: 'auto !important'
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  inputElementBox: {
    margin: '8px',
    // marginLeft:'8px',
    // marginRight:'8px',
    flex: 1
  },
  buttonContainer: {
    display: 'flex',
    height: '35px',
    margin: '44px 8px 8px',
    justifyContent: 'end',
    columnGap: '20px'
  },
  applyButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: '#19A04B !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  resetButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.green_50} !important`,
    background: 'none !important',
    border: '1px dashed #19A04B !important',
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  fileUploadButton: {
    ...fontFamilies.semiBold1420,
    color: `${COLORS.Nuetral_100} !important`,
    background: 'none !important',
    border: '1px dashed #19A04B !important',
    boxShadow: 'none !important',
    marginRight: '12px !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    height: '35px !important',
    textTransform: 'none'
  },
  fileName: {
    ...fontFamilies.semiBold1420
  },
  fileSizeError: {
    ...fontFamilies.regular1218,
    color: '#d32f2f',
    margin: '5px 0px'
  },
  confirmLabelText: {
    ...fontFamilies.regular1419
  },
  confirmTitle: {
    ...fontFamilies.semiBold1622
  },
  confirmcontainer: {
    padding: '0px 24px 15px'
  },
  viewLabel: {
    ...fontFamilies.regular1218,
    wordBreak: 'break-word',
    marginBottom: '5px'
  },
  licenseImage: {

    // '&:hover': {
    //   transform: 'scale(1.5)'
    // }
  },
  toggleSwitchContainer: {
    marginTop: '20px'
  }
})
);
