import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  rootContainer: {
    display: 'flex',
    height: '100vh'
  },
  root: {
    display: 'inline-flex'
  },
  contents: { margin: '50px 150px', width: '400px' },
  container: {
    textAlign: 'start'
  },
  headerContainer: {
    display: 'flex'
  },
  welcomeHelper: {
    fontSize: '40px',
    fontWeight: '700',
    marginTop: '16px'
  },
  adminHelper: {
    fontSize: '40px',
    fontWeight: '400',
    paddingLeft: '8px',
    marginTop: '16px'
  },
  mobileNumberLabel: {
    fontSize: '16px',
    fontWeight: 700
  },
  textFieldHelper: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 'unset'
    }
  },
  textFieldErrorHelper: {
    '& fieldset': {
      borderColor: 'red'
    }
  },
  countryCodeHelper: { width: '64px', marginRight: '16px' },
  buttonHelper: { background: `${COLORS.Navy}`, borderRadius: 'unset' },
  actionContainer: { marginTop: '16px' },
  loginImage: {
    display: 'flex'
  },
  secureLoginImage: {
    marginTop: '32px'
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 24px 0px 0px'
  },
  errorImage: {
    height: 16,
    width: 16
  },
  errorText: {
    color: COLORS.ErrorRed,
    margin: '0px',
    marginLeft: '8px',
    fontSize: 14,
    fontWeight: 400
  }
}));
