export const GET_CLUSTER_LIST = 'GET_CLUSTER_LIST';
export const GET_CLUSTER_LIST_SUCCESS = 'GET_CLUSTER_LIST_SUCCESS';
export const GET_CLUSTER_LIST_FAILED = 'GET_CLUSTER_LIST_FAILED';

export const ADD_CLUSTER = 'ADD_CLUSTER';
export const ADD_CLUSTER_SUCCESS = 'ADD_CLUSTER_SUCCESS';
export const ADD_CLUSTER_FAILED = 'ADD_CLUSTER_FAILED';

export const UPDATE_CLUSTER = 'UPDATE_CLUSTER';
export const UPDATE_CLUSTER_SUCCESS = 'UPDATE_CLUSTER_SUCCESS';
export const UPDATE_CLUSTER_FAILED = 'UPDATE_CLUSTER_FAILED';

export const RESET_CLUSTER_STATES = 'RESET_CLUSTER_STATES';

export const SET_CLUSTER_STATES = 'SET_CLUSTER_STATES';
