import { type AppState, type Selector } from '../rootReducer';
import { type getUserListSucessPayloadType, type userItemType } from './types';

export const getUserListLoading: Selector<boolean> = (state: AppState) =>
  state.onBoard.isUserListLoading;

export const getUserListError: Selector<boolean> = (state: AppState) =>
  state.onBoard.error;

export const getUserList: Selector<getUserListSucessPayloadType> = (state: AppState) =>
  state.onBoard.userList;

export const addUserLoading: Selector<boolean> = (state: AppState) =>
  state.onBoard.isLoading;

export const addUserError: Selector<boolean> = (state: AppState) =>
  state.onBoard.error;

export const addUserErrorMessage: Selector<string> = (state: AppState) =>
  state.onBoard.errorMessage;

export const addUserSucess: Selector<string> = (state: AppState) =>
  state.onBoard.message;

export const updateUserLoading: Selector<boolean> = (state: AppState) =>
  state.onBoard.isLoading;

export const updateUserError: Selector<boolean> = (state: AppState) =>
  state.onBoard.error;

export const updateUserSucess: Selector<string> = (state: AppState) =>
  state.onBoard.message;

export const getUserLoading: Selector<boolean> = (state: AppState) =>
  state.onBoard.isLoading;

export const getUserError: Selector<boolean> = (state: AppState) =>
  state.onBoard.error;

export const getUser: Selector<userItemType> = (state: AppState) =>
  state.onBoard.user;
