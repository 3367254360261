import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import SelectedValueBadge from '../../common/selectedValueBadge';
import { Doughnut } from 'react-chartjs-2';
import CardComponent from './CardComponent';
import { useStyles } from './styles';
import { COLORS } from '../../resources/colors';
import { useDashboardDetails, useFilter, useLogin } from '../../hooks';
import {
  type OrderStatusType,
  type OperatorsCountType,
  type TopSprayCropRanksType,
  type MachineCountType,
  type MachineStatusCountType
} from '../../redux/dashboard/types';
import NestedDropdown from '../../common/NestedDropdown';

enum OPERATOR_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

enum MACHINE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BREAKDOWN = 'BREAKDOWN'
}

type OPERATOR_STATUS_TYPE = OPERATOR_STATUS.ACTIVE | OPERATOR_STATUS.INACTIVE;
type MACHINE_STATUS_TYPE = MACHINE_STATUS.ACTIVE | MACHINE_STATUS.INACTIVE | MACHINE_STATUS.BREAKDOWN;

enum BOOKING_STATUS_TYPES {
  ServiceBooked = 'Service Booked',
  Confirmed = 'Confirmed',
  Rejected = 'Rejected',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

type BookingStatuType =
  BOOKING_STATUS_TYPES.ServiceBooked
  | BOOKING_STATUS_TYPES.Cancelled
  | BOOKING_STATUS_TYPES.Completed
  | BOOKING_STATUS_TYPES.Confirmed
  | BOOKING_STATUS_TYPES.Rejected;

const HomeDashboard: React.FC = () => {
  const classes = useStyles();
  const formatter = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  });

  const { dashboardDetails, fetchDashboardDetails } = useDashboardDetails();
  const {
    allSprayOrdersCount,
    statusCount,
    topSprayCropRanks,
    // topSprayProductRanks,
    machineStatusCount
  } = dashboardDetails;
  const { userProfile: { userRole, userId } } = useLogin();
  const {
    fetchClustors,
    setFilterRequest,
    filterRequest,
    clustors
  } = useFilter();
  const {
    clusterIds,
    clusterNames
  } = filterRequest;

  const [isDroneSpray, setIsDroneSpray] = useState(true);
  const [machinesByStatus, setMachinesByStatus] = useState<MachineCountType[]>([]);
  const [allMachinesCount, setAllMachinesCount] = useState<number | string>();
  const [fetchAllDetails, setFetchAllDetails] = useState<boolean>(false);

  const getDashboardDetails = () => {
    fetchDashboardDetails({
      userId,
      userRole,
      clusterIds: clusterIds?.map((id: string) => Number(id))
    });
  };

  const reset = () => {
    setFilterRequest({ ...filterRequest, clusterIds: [], clusterNames: [] });
  };

  useEffect(() => {
    fetchClustors({ statesIds: [] });
    getDashboardDetails();
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (fetchAllDetails) {
      getDashboardDetails();
      setFetchAllDetails(false);
    }
  }, [fetchAllDetails]);

  useEffect(() => {
    const machinetype: string = isDroneSpray ? 'DRONE' : 'BOOM';
    const machineByType: MachineStatusCountType[] = machineStatusCount.filter((machine: MachineStatusCountType) => machine.machineType === machinetype);
    if (machineByType?.length > 0) {
      setMachinesByStatus(machineByType[0].machineStatusCount);
      setAllMachinesCount(machineByType[0].allMachineCount);
    }
  }, [isDroneSpray, allSprayOrdersCount]);

  const getMachineCountByStatus = (type: MACHINE_STATUS_TYPE) => {
    const machineByType: MachineCountType[] = machinesByStatus?.filter((machine: MachineCountType) => machine.machineStatus === type);
    return machineByType?.length > 0 ? machineByType[0].count : 0;
  };

  const getOrderCountByStatus = (status: BookingStatuType) => {
    const orderStatus: OrderStatusType[] = statusCount.filter((machineStatus: OrderStatusType) => machineStatus.bookingStatus === status);
    return orderStatus?.length > 0 ? orderStatus[0].count : 0;
  };

  const totalOrderData = {
    datasets: [
      {
        data: [
          getOrderCountByStatus(BOOKING_STATUS_TYPES.Completed),
          getOrderCountByStatus(BOOKING_STATUS_TYPES.ServiceBooked),
          getOrderCountByStatus(BOOKING_STATUS_TYPES.Confirmed),
          getOrderCountByStatus(BOOKING_STATUS_TYPES.Cancelled),
          getOrderCountByStatus(BOOKING_STATUS_TYPES.Rejected)
        ],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#005693',
          '#CF3537'
        ],
        centerText: allSprayOrdersCount || '0'
      }
    ]
  };

  const totalOrderLabel = {
    id: 'totalOrderLabel',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = 'bold 30px sans-serif';
      ctx.fillStyle = 'rgba(54, 162, 235, 1)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${data.datasets[0].centerText}`, xCoor, yCoor);
    }
  };
  const droneData = {
    datasets: [
      {
        data: [getMachineCountByStatus(MACHINE_STATUS.ACTIVE), getMachineCountByStatus(MACHINE_STATUS.INACTIVE), getMachineCountByStatus(MACHINE_STATUS.BREAKDOWN)],
        backgroundColor: [COLORS.statusCompleted, COLORS.statusOpened, COLORS.statusConfirmed],
        centerText: allMachinesCount || '0'
      }
    ]
  };
  const boomData = {
    datasets: [
      {
        data: [getMachineCountByStatus(MACHINE_STATUS.ACTIVE), getMachineCountByStatus(MACHINE_STATUS.INACTIVE), getMachineCountByStatus(MACHINE_STATUS.BREAKDOWN)],
        backgroundColor: [COLORS.statusCompleted, COLORS.statusOpened, COLORS.statusConfirmed],
        centerText: allMachinesCount || '0'
      }
    ]
  };

  const totalMachineLabel = {
    id: 'totalOrderLabel',
    beforeDatasetsDraw (chart: any, args: any, pluginOptions: any) {
      const { ctx, data } = chart;
      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;
      ctx.font = 'bold 30px sans-serif';
      ctx.fillStyle = 'rgba(54, 162, 235, 1)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${data.datasets[0].centerText}`, xCoor, yCoor);
    }
  };

  const getOperatorCount = (status: OPERATOR_STATUS_TYPE) => {
    const operatorByStatus: OperatorsCountType[] = dashboardDetails.operatorsCount.filter((operator: OperatorsCountType) => operator.isActiveStatus === status);
    return operatorByStatus?.length > 0 && operatorByStatus[0].count;
  };

  const getTopCrops = (rank: number) => {
    return topSprayCropRanks?.length > 0 ? topSprayCropRanks.filter((topCrop: TopSprayCropRanksType) => topCrop.topCropRank === rank)[0]?.cropName : '';
  };

  // const getTopProducts = (rank: number) => {
  //   return topSprayProductRanks?.length > 0 ? topSprayProductRanks.filter((topCrop: TopSprayProductRanksType) => topCrop.topProductRank === rank)[0]?.productName : '';
  // };

  const setSelectedIds = (e: any, label?: string, e1?: any, label1?: string) => {
    if (label && label1) {
      setFilterRequest({ ...filterRequest, [label]: [...e], [label1]: [...e1] });
    }
  };

  const getSelectedOptionLength = (arr: any) => {
    if (arr && arr?.length) {
      return arr?.map((e: any) => e?.keyValueDto?.length)?.reduce((partialSum: number, a: number) => partialSum + a, 0);
    } else {
      return 0;
    }
  };

  const handleApplyClick = () => {
    getDashboardDetails();
  };

  const handleResetClick = () => {
    reset();
    fetchClustors({ statesIds: [] });
    setFetchAllDetails(true);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.regionWrapper}>
        <div className={classes.displayInlineBlock}>
          <div className={classes.displayInlineWithMarginRight16}>
            <NestedDropdown
              label="State & Cluster"
              subOptionName="keyValueDto"
              subOptionlabel="stateName"
              subOptionValue="stateId"
              optionLabel="value"
              optionValue="id"
              options={clustors ? [{ stateId: 0, stateName: 'All', keyValueDto: [] }, ...clustors] : []}
              setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'clusterIds', e1, 'clusterNames'); }}
              selectedIds={clusterIds}
              selectedNames={clusterNames}
            // isDisabled={statesIds?.length === 0}
            />
            <SelectedValueBadge title={'Cluster'} contentList={clusterNames} selectionLabel={clusterIds && clusterIds?.length && clusterIds?.length !== getSelectedOptionLength(clustors) ? `${clusterIds?.length} Selected` : 'All'}></SelectedValueBadge>
          </div>
        </div>
        <div>
          <CustomButton
            label='RESET'
            onClick={handleResetClick}
            customStyle={classes.resetButton}
          ></CustomButton>
          <CustomButton
            label='APPLY'
            onClick={handleApplyClick}
            customStyle={classes.applyButton}
          ></CustomButton>
        </div>
      </div>

      <div className={classes.cardWrapper}>
        <CardComponent>
          <div className={classes.totalRevenuWrapper}>
            <div className={classes.totalRevenuTextWrapper}>
              <span className={classes.totalRevenueText}>TOTAL REVENUE</span>
              <span className={classes.totalRevenueTextValue}>
                {dashboardDetails?.totalRevenue ? formatter.format(Number(dashboardDetails.totalRevenue)) : ''}
              </span>
            </div>
          </div>
          <div>
            <img className={classes.coinImage} src={`${process.env.PUBLIC_URL}/coinAnimation.gif`} />
          </div>
        </CardComponent>

        <CardComponent customStyle={classes.totalAcerageWrapper}>
          <div className={classes.totalRevenuWrapper}>
            <div>
              <p className={classes.totalRevenueText}>TOTAL ACERAGE</p>
              <span className={classes.totalAcerageTextValue}>
                {dashboardDetails.totalAcreage}
              </span>
            </div>
          </div>
          <div className={classes.totalAcerageIconImageWrapper}>
            <img
              src={`${process.env.PUBLIC_URL}/totalAcerageIcon.png`}
              className={classes.marginTop24}
            />
          </div>
        </CardComponent>

        <CardComponent customStyle={classes.totalAcerageWrapper}>
          <div className={classes.totalOperatorWrapper}>
            <p className={classes.totalRevenueText}>TOTAL OPERATORS</p>
            <div className={classes.activeInActiveWrapper}>
              <div>
                <p className={classes.activeValueText}>
                  {getOperatorCount(OPERATOR_STATUS.ACTIVE)}
                  { }
                </p>
                <p className={classes.activeLabelText}>Active</p>
              </div>
              <div className={classes.inactiveWraper} />
              <div>
                <p className={classes.inactveValueText}>
                  {getOperatorCount(OPERATOR_STATUS.INACTIVE)}
                </p>
                <p className={classes.inactiveLabelText}>in - Active</p>
              </div>
            </div>
          </div>

          <div className={classes.activeInActiveImgWrapper}>
            <img
              src={`${process.env.PUBLIC_URL}/profileIcon.png`}
              className={classes.activeInActiveImg}
            />
          </div>
        </CardComponent>
      </div>

      <div className={classes.cardWrapper} style={{ height: '400px' }}>
        <CardComponent customStyle={classes.totalAcerageWrapper}>
          <div className={classes.totalOrderWrapper}>
            <div className={classes.totalOrderTextWrapper}>
              <span className={classes.totalRevenueText}>TOTAL ORDERS</span>
              <div className={classes.totalOrderDoughnutWrapper}>
                <Doughnut data={totalOrderData} plugins={[totalOrderLabel]} />
              </div>
            </div>
            <div className={classes.totalorderListWrapper}>
              <div className={classes.totalOrderItemWrapper}>
                <span className={classes.totalOrderKeyItem} style={{ color: COLORS.statusCompleted }}>
                  {getOrderCountByStatus(BOOKING_STATUS_TYPES.Completed)}
                </span>
                <span className={classes.totalOrderValueItem}>COMPLETED</span>
              </div>
              <div className={classes.totalOrderItemWrapper}>
                <span className={classes.totalOrderKeyItem} style={{ color: COLORS.statusOpened }}>
                  {getOrderCountByStatus(BOOKING_STATUS_TYPES.ServiceBooked)}
                </span>
                <span className={classes.totalOrderValueItem}>OPEN</span>
              </div>
              <div className={classes.totalOrderItemWrapper}>
                <span className={classes.totalOrderKeyItem} style={{ color: COLORS.statusConfirmed }}>
                  {getOrderCountByStatus(BOOKING_STATUS_TYPES.Confirmed)}
                </span>
                <span className={classes.totalOrderValueItem}>CONFIRMED</span>
              </div>
              <div className={classes.totalOrderItemWrapper}>
                <span className={classes.totalOrderKeyItem} style={{ color: COLORS.statusCancelled }}>
                  {getOrderCountByStatus(BOOKING_STATUS_TYPES.Cancelled)}
                </span>
                <span className={classes.totalOrderValueItem}>CANCELLED</span>
              </div>
              <div className={classes.totalOrderItemWrapper}>
                <span className={classes.totalOrderKeyItem} style={{ color: COLORS.statusRejected }}>
                  {getOrderCountByStatus(BOOKING_STATUS_TYPES.Rejected)}
                </span>
                <span className={classes.totalOrderValueItem}>REJECTED</span>
              </div>
            </div>
          </div>
        </CardComponent>

        <CardComponent customStyle={classes.topCropsAndProducts}>
          <div className={classes.topCropChartWrapper}>
            <div className={classes.rankChart}>
              <span className={classes.topCropsText}>TOP CROPS</span>
              <div className={classes.rankWrapper}>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.secondRankText}>
                    {getTopCrops(2)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/cropRankTwo.png`}
                    alt='no-data'
                  />
                </div>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.firstRankText}>
                    {getTopCrops(1)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/cropRankFirst.png`}
                    alt='no-data'
                  />
                </div>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.threeRankText}>
                    {getTopCrops(3)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/cropRankThree.png`}
                    alt='no-data'
                  />
                </div>
              </div>
            </div>
            {/* <div className={classes.rankChart}>
              <span className={classes.topProductsText}>©</span>
              <div className={classes.rankWrapper}>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.secondProdRankText}>
                    {getTopProducts(2)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/productRankTwo.png`}
                    alt='no-data'
                  />
                </div>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.firstProdRankText}>
                    {getTopProducts(1)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/productRankFirst.png`}
                    alt='no-data'
                  />
                </div>
                <div className={classes.rankItemWrapper}>
                  <p className={classes.threeProdRankText}>
                    {getTopProducts(1)}
                  </p>
                  <img
                    src={`${process.env.PUBLIC_URL}/productRankThree.png`}
                    alt='no-data'
                  />
                </div>
              </div>
            </div> */}
          </div>
        </CardComponent>

        <CardComponent customStyle={classes.totalAcerageWrapper}>
          <div className={classes.totalMachineWrapper}>
            <span className={classes.totalMachineText}>TOTAL MACHINES</span>

            <div className={classes.sprayWrapper}>
              <div
                className={[
                  classes.droneWrapper,
                  isDroneSpray && classes.selectedSprayType
                ].join(' ')}
                onClick={() => {
                  setIsDroneSpray(true);
                }}
              >
                <span
                  className={[
                    classes.droneText,
                    { color: isDroneSpray ? COLORS.Black : COLORS.SwitchGray }
                  ].join(' ')}
                >
                  DRONE
                </span>
              </div>
              <div
                className={[
                  classes.boomWrapper,
                  !isDroneSpray && classes.selectedSprayType
                ].join(' ')}
                onClick={() => {
                  setIsDroneSpray(false);
                }}
              >
                <span
                  className={[
                    classes.droneText,
                    { color: !isDroneSpray ? COLORS.Black : COLORS.SwitchGray }
                  ].join(' ')}
                >
                  BOOM
                </span>
              </div>
            </div>

            <div className={classes.totalMachineChartWrapper}>
              <div className={classes.totaMachineDoughnutWrapper}>
                <Doughnut
                  data={isDroneSpray ? droneData : boomData}
                  plugins={[totalMachineLabel]}
                />
              </div>
              <div className={classes.totalMachineChartListWrapper}>
                <div className={classes.totalOrderItemWrapper}>
                  <span className={classes.totalOrderKeyItem} style={{ color: '#FF6384' }}>
                    {getMachineCountByStatus(MACHINE_STATUS.ACTIVE)}
                  </span>
                  <span className={classes.totalOrderValueItem}>ACTIVE</span>
                </div>
                <div className={classes.totalOrderItemWrapper}>
                  <span className={classes.totalOrderKeyItem} style={{ color: '#FFCE56' }}>
                    {getMachineCountByStatus(MACHINE_STATUS.BREAKDOWN)}
                  </span>
                  <span className={classes.totalOrderValueItem}>BREAKDOWN</span>
                </div>
                <div className={classes.totalOrderItemWrapper}>
                  <span className={classes.totalOrderKeyItem} style={{ color: '#36A2EB' }}>
                    {getMachineCountByStatus(MACHINE_STATUS.INACTIVE)}
                  </span>
                  <span className={classes.totalOrderValueItem}>IN-ACTIVE</span>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  );
};
export default HomeDashboard;
