import { type AxiosError } from 'axios';
import { type DashboardDetailsType, type GetAllDetailsRequestPayload } from '../redux/dashboard/types';
import { postApiRequest } from '../api/api';
import { apiUrls } from '../api/apiUrls';

export const getAllDetailsRequest = async (
  body: GetAllDetailsRequestPayload
) => {
  try {
    const { userId, userRole, clusterIds } = body;
    const response: DashboardDetailsType = await postApiRequest(apiUrls.DASHBOARD_DETAILS, {
      userId,
      userRole,
      clusterIds
    });
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
