import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7 !important',
    borderRadius: 'unset !important'
  },
  inputText: {
    ...fontFamilies.regular1622,
    // fontWeight:400,
    // fontSize:'16px',
    height: '35px',
    backgroundColor: 'rgba(243, 244, 246, 0.5)',
    paddingRight: '10px !important',
    borderRadius: 'unset !important',
    // color: '#A3A9B9',
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
}));
