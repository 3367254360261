import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useStyles } from './styles';

interface errorTypes {
  success: string
  error: string
  warning: string
  info: string
}
interface ToastMessageProps {
  message: string
  errorType: keyof errorTypes

}
const ToastMessage: React.FC<ToastMessageProps> = ({ message, errorType = 'success' }) => {
  const classes = useStyles();

  const getErrorTypeIcon = (errorType: keyof errorTypes) => {
    switch (errorType) {
      case 'success':
        return <img src={`${process.env.PUBLIC_URL}/check_circle.png`} alt="success" />;
      case 'error':
        return <img src={`${process.env.PUBLIC_URL}/errorMsg.png`} alt="error" />;
      case 'warning':
        return <img src={`${process.env.PUBLIC_URL}/warning.png`} alt="warning" />;
      case 'info':
        return <img src={`${process.env.PUBLIC_URL}/infoMsg.png`} alt="info" />;
      default:
        break;
    }
  };
  return (
    <Stack className={classes.toastContainer}>
      <Alert icon={getErrorTypeIcon(errorType)} className={classes.toastMessage}>
        {message}
      </Alert>
    </Stack>
  );
};
export default ToastMessage;
