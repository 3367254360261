import * as React from 'react';
import Chip from '@mui/material/Chip';

interface DeletableChipProps {
  label: string
  onDelete: (index: number) => void
}

export const DeletableChip: React.FC<DeletableChipProps> = ({
  label,
  onDelete
}) => {
  return (
    <>
      <Chip label={label} onDelete={ onDelete} />
    </>
  );
};
