import { useDispatch, useSelector } from 'react-redux';
import {
  getStates
} from '../redux/cluster/selectors';
import { type getClusterListRequestPayload, type clusterState, type addClusterRequestPayload, type updateClusterRequestPayload } from '../redux/cluster/types';
import { addClusterRequest, getClusterListRequest, resetClusterStates, setClusterStates, updateClusterRequest } from '../redux/cluster/action';

interface useClusterType extends clusterState {
  fetchGetClusterList: (action: getClusterListRequestPayload) => void
  fetchAddCluster: (action: addClusterRequestPayload) => void
  fetchUpdateCluster: (action: updateClusterRequestPayload) => void
  fetchResetClusterStates: (action: keyof clusterState) => void
  fetchSetClusterStates: (action: any) => void
}

export const useCluster = (): useClusterType => {
  const dispatch = useDispatch();
  // const isMachineListLoading: boolean = useSelector(getMachineListLoading)
  // const machineList: getMachineListSucessPayloadType = useSelector(getMachineList)
  // const error: boolean = useSelector(getMachineListError)

  const states: clusterState = useSelector(getStates);

  const fetchGetClusterList = (action: getClusterListRequestPayload) => {
    dispatch(getClusterListRequest(action));
  };

  const fetchAddCluster = (action: addClusterRequestPayload) => {
    dispatch(addClusterRequest(action));
  };

  const fetchUpdateCluster = (action: updateClusterRequestPayload) => {
    dispatch(updateClusterRequest(action));
  };

  const fetchResetClusterStates = (action: keyof clusterState) => {
    dispatch(resetClusterStates(action));
  };

  const fetchSetClusterStates = (action: any) => {
    dispatch(setClusterStates(action));
  };

  return {
    ...states,
    fetchGetClusterList,
    fetchAddCluster,
    fetchUpdateCluster,
    fetchResetClusterStates,
    fetchSetClusterStates
  };
};
