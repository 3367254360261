import { makeStyles } from '@mui/styles';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  buttonHelper: { background: '#00004B !important', borderRadius: 'unset !important' },
  disabledButton: {
    background: '#DFE2E7',
    borderRadius: 'unset !important'
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'fit-content',
      // maxWidth: '720px',
      minWidth: '520px'
    },
    '& .MuiDialogTitle-root': {
      ...fontFamilies.semiBold1622,
      margin: '0px 0px 0px 8px'
    }
  }
}));
