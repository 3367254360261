import { Button } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';

interface CustomButtonProps {
  label: string
  disabled?: boolean
  loading?: boolean
  customStyle?: any
  startIcon?: any
  endIcon?: any
  fullWidth?: boolean
  onClick: () => void
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  disabled = false,
  onClick,
  loading = false,
  endIcon,
  startIcon,
  fullWidth = false,
  customStyle
}) => {
  const classes = useStyles();
  return (
    <Button
      // fullWidth
      disabled={disabled}
      variant="contained"
      fullWidth={fullWidth}
      className={disabled ? classes.disabledButton : [classes.buttonHelper, customStyle].join(' ')}
      // sx={customStyle}
      // startIcon={loading ? <CircularProgress size={16} color="inherit" /> : null}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={() => { onClick(); }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
