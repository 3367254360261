import { type AppState, type Selector } from '../rootReducer';
import { type UserProfile, type ErrorType } from './types';

export const getIsLoading: Selector<boolean> = (state: AppState) =>
  state.login.isLoading;

export const getOtpSent: Selector<boolean | null> = (state: AppState) =>
  state.login.otpSent;

export const getMobile: Selector<string> = (state: AppState) =>
  state.login.mobile;

export const getToken: Selector<string> = (state: AppState) =>
  state.login.token;

export const getIsAuthenticated: Selector<boolean> = (state: AppState) =>
  state.login.isAuthenticated;

export const getErrorSendingOtp: Selector<ErrorType> = (state: AppState) =>
  state.login.errorSendingOtp;

export const getErrorVerifyOtp: Selector<boolean> = (state: AppState) =>
  state.login.errorVerifyOtp;

export const getUserProfile: Selector<UserProfile> = (state: AppState) =>
  state.login.userProfile;
