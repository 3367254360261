import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  onBoardContainer: {
    padding: '20px'
  },
  addButton: {
    // backgroundColor: '#14803C !important',
    // width: 'auto !important'
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: '#19A04B !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none',
    minWidth: '130px'
  },
  inputContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchInput: {
    backgroundColor: 'white'
  },
  customTableContainer: {
    '& .MuiTableContainer-root': {
      width: '100% !important',
      margin: '0px',
      minHeight: 'calc(100vh - 260px) !important',
      maxHeight: 'calc(100vh - 260px) !important'
    },
    '& .MuiTableRow-root': {
      height: '40px !important'
    },
    '& .0.026531764203473474-makeStyles-tableContainerHelper-58': {
      minHeight: '64px !important',
      maxHeight: '64px !important'
    },
    '& .0.37887554359313613-makeStyles-tableContainerHelper-58': {
      minHeight: '64px !important',
      maxHeight: '64px !important'
    },
    '& 0.49201502793465757-makeStyles-tableContainerHelper-58': {
      minHeight: '64px !important',
      maxHeight: '64px !important'
    },
    table: {
      minHeight: '64px !important',
      maxHeight: '64px !important'
    },
    '& .tableContainerHelper': {
      minHeight: 'calc(100vh - 260px) !important',
      maxHeight: 'calc(100vh - 260px) !important'
    }
  },
  buttonContainer: {
    display: 'flex',
    height: '35px',
    margin: '44px 8px 8px',
    justifyContent: 'end',
    columnGap: '20px'
  },
  applyButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: '#19A04B !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  resetButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.green_50} !important`,
    background: 'none !important',
    border: '1px dashed #19A04B !important',
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  confirmLabelText: {
    ...fontFamilies.regular1419
  },
  confirmTitle: {
    ...fontFamilies.semiBold1622
  },
  confirmcontainer: {
    padding: '0px 24px 24px'
  },
  statusLabel: {
    color: '#14803C'
  },
  customizedDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '720px'
    }
  },
  viewLicennseImgCustomizedDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'fit-content'
    }
  },
  clusterContainer: {
    maxWidth: '150px',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
    lineHeight: '20px !important'
  }
})
);
