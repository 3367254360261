import { all, delay, put } from 'redux-saga/effects';
import { GET_ORDER_STATUS } from './actionType';
import { getOrderStatusSuccess, getOrderStatusFailed } from './action';

function * getOrderStatus () {
  try {
    yield delay(5000);
    yield put(getOrderStatusSuccess({ orderStatus: ['All'] }));
  } catch (error: unknown) {
    yield put(getOrderStatusFailed({ error: true }));
  }
}

function * orderStatusSaga () {
  // yield all([takeLatest(GET_ORDER_STATUS, getOrderStatus)]);
  yield all([
    put({ type: GET_ORDER_STATUS, getOrderStatus })
  ]);
}

export default orderStatusSaga;
