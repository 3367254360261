import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  selectBox: {
    ...fontFamilies.regular1419,
    color: COLORS.Nuetral_100,
    textAlign: 'start',
    textTransform: 'uppercase',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    height: '30px',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #868CA2',
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    }
  },
  menuItems: {
    display: 'flex',
    paddingLeft: '0px !important',
    height: '24px',
    margin: '4px 0px !important'
  },
  checkbox: {
    padding: '7px 9px'
  },
  displayNone: {
    display: 'none !important'
  },
  optionContainer: {
    padding: '7px 15px 3px 15px',
    width: 'min-content',
    minWidth: '160px',
    maxHeight: '300px',
    overflow: 'scroll'

  },
  options: {
    marginTop: '7px',
    paddingLeft: '5px',
    ...fontFamilies.regular1622,
    color: `${COLORS.Nuetral_80} !important`
  }
}));
