import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  sidebar: {
    backgroundColor: COLORS.Navy,
    width: '80px'
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '74px',
    cursor: 'pointer'
  },
  selectedMenuContainer: {
    background: COLORS.MenuBlue
  },
  menuIcon: { height: '20px', width: '20px' },
  menuLabel: {
    color: COLORS.White,
    fontSize: 16,
    fontWeight: 600

  }
})
);
