import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useStyles } from './styles';
import { useMemo, useState } from 'react';

interface SearchInputProps {
  placeholder: string
  searchText: string
  customStyle?: any
  onSearch: (event: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  searchText,
  customStyle,
  onSearch
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    debouncedHandler(value);
  };

  const debounce = (func: any, wait: any) => {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedHandler = useMemo(() => { return debounce(onSearch, 500); }, [onSearch]);

  return (
        <FormControl sx={{ width: '100%' }}>
            <OutlinedInput
                className={[classes.inputText, customStyle].join(' ')}
                placeholder={placeholder}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  change(event);
                }}
                endAdornment={
                    <InputAdornment position="end">
                            <img src={`${process.env.PUBLIC_URL}/search.png`} alt="search-input" />
                    </InputAdornment>
                }
                // aria-describedby="outlined-search-helper-text"
            />
        </FormControl>
  );
};

export default SearchInput;
