import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useLogin } from '../../hooks';
import { useStyles } from './styles';
import PersonIcon from '@mui/icons-material/Person';

const Header: React.FC = () => {
  // classes
  const classes = useStyles();

  // hooks
  const { userProfile: { operatorName }, logoutUser } = useLogin();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoutUser();
  };

  let name: string = '';
  if (operatorName) {
    name = operatorName.charAt(0);
    const spaceIndex: number = operatorName.indexOf(' ');
    if (spaceIndex > -1) {
      name = `${name}${operatorName.charAt(spaceIndex + 1)}`;
    }
  }

  return (
    <div className={classes.root}>
      <div>
        <img style={{ height: '32px', width: '96px' }} src={`${process.env.PUBLIC_URL}/cropwise-icon.png`} alt="cropwise-icon" />
      </div>
      <div className={classes.profileContainer}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar className={classes.nameInitials}>
            {name?.length > 0
              ? (
                  name
                )
              : (
                <PersonIcon />
                )}
          </Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleLogout}>
            <img style={{ height: '24px', width: '24px' }} src={`${process.env.PUBLIC_URL}/logout.png`} alt="logout" />
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
