import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  tooltipContainer: {
    marginTop: '10px'
  },
  selectedText: {
    ...fontFamilies.semiBold1420,
    color: COLORS.Nuetral_100,
    border: '1px solid #DFE2E7',
    borderRadius: '4px',
    height: '24px',
    width: 'fit-content',
    padding: '0px 5px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    // backgroundColor: '#F3F4F6'
    backgroundColor: 'white'
  },
  infoIcon: {
    marginLeft: '17px'
  },
  listItem: {
    padding: '0px 5px 0px 5px !important'
  },
  listContainer: {
    maxHeight: '300px',
    overflow: 'scroll'
  }
}));
