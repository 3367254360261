import React from 'react';
import { useStyles } from './styles';
import { Menu, type MenuProps } from './Menu';
import { useLogin } from '../../hooks';

interface SidebarProps {
  selectedMenu: string
  onChangeManu: (selectedMenu: string) => void
}

const Sidebar: React.FC<SidebarProps> = ({
  selectedMenu,
  onChangeManu
}) => {
  // classes
  const classes = useStyles();
  const { userProfile: { userRole } } = useLogin();

  const onSelectMenuHandler = (selectedMenuIndex: number) => {
    onChangeManu(Menu[selectedMenuIndex].label);
  };

  const userBasedMenu: MenuProps[] = Menu.filter((menu: MenuProps) => menu.roles.split(',').includes(userRole));

  return (
    <div className={classes.sidebar}>
      {
        userBasedMenu.map((menu: MenuProps, index: number) => {
          const rootClass = menu.label === selectedMenu ? `${classes.menuContainer} ${classes.selectedMenuContainer}` : classes.menuContainer;
          return (
            <div className={rootClass} key={`${index}-${menu}`} onClick={() => {
              onSelectMenuHandler(index);
            }}>
              <img className={classes.menuIcon} src={`${process.env.PUBLIC_URL}/${menu.imagePath}`} alt="red-flag" />
              <span className={classes.menuLabel}>{menu.label}</span>
            </div>
          );
        })
      }
    </div>
  );
};

export default Sidebar;
