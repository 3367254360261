import { apiUrls } from '../api/apiUrls';
import { getApiRequest, postApiRequest, putApiRequest } from '../api/api';
import { type AxiosError } from 'axios';
import { type machineDetailsType, type getMachineListByStatusRequestPayload, type updateMachineStatusRequestPayload } from '../redux/machine/types';

export const updateMachineStatusService = async (body: updateMachineStatusRequestPayload) => {
  try {
    const { machineId } = body;
    const response: unknown = await putApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.UPDATE_BREAKDOWN_MACHINE_STATUS_URL}${machineId}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const saveMachineDetailsService = async (body: machineDetailsType) => {
  try {
    const response: unknown = await postApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.SAVE_MACHINE_URL}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const updateMachineDetailsService = async (body: machineDetailsType) => {
  try {
    const { machineId } = body;
    const response: unknown = await putApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.UPDATE_MACHINE_URL}${machineId}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getMachineListBystatusService = async (body: getMachineListByStatusRequestPayload) => {
  try {
    let queryString: string = '';

    (Object.keys(body) as Array<keyof typeof body>)?.map((key: keyof getMachineListByStatusRequestPayload) => {
      queryString += body[key]?.toString()?.length ? queryString?.length ? `&${key}=${body[key]}` : `${key}=${body[key]}` : '';
    });

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_MACHINE_URL}?${queryString}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
