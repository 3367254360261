import { apiUrls } from '../api/apiUrls';
import { getApiRequest, postApiRequest, putApiRequest } from '../api/api';
import { type AxiosError } from 'axios';
import { type updateClusterRequestPayload, type addClusterRequestPayload, type getClusterListRequestPayload } from '../redux/cluster/types';

export const getClusterListService = async (body: getClusterListRequestPayload) => {
  try {
    const { clusterOnboardingFlow, pageNo, pageSize, searchKey } = body;
    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.GET_CLUSTORS_DETAILS_URL}?pageNo=${pageNo}&pageSize=${pageSize}&clusterOnboardingFlow=${clusterOnboardingFlow}&searchKey=${searchKey || ''}`
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const addClusterService = async (body: addClusterRequestPayload) => {
  try {
    const response: unknown = await postApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.ADD_CLUSTOR_URL}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const updateClusterService = async (body: updateClusterRequestPayload) => {
  try {
    const { clusterId } = body;
    const response: unknown = await putApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.UPDATE_CLUSTOR_URL}${clusterId}`,
      body,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
