import { useSelector, useDispatch } from 'react-redux';
import {
  getErrorSendingOtp,
  getErrorVerifyOtp,
  getIsAuthenticated,
  getIsLoading,
  getMobile,
  getOtpSent,
  getToken,
  getUserProfile
} from '../redux/login/selectors';
import { requestOtp, resetOtpSent as resetOtp, requestVerifyOtp, logoutUser as logout } from '../redux/login/actions';
import { type UserProfile, type ErrorType, type LoginState, type SendOtpRequestPayload, type VerifyOtpRequestPayload } from '../redux/login/types';

interface LoginStateType extends LoginState {
  sendOtp: (action: SendOtpRequestPayload) => void
  verifyOtp: (action: VerifyOtpRequestPayload) => void
  resetOtpSent: () => void
  logoutUser: () => void
}

export const useLogin = (): LoginStateType => {
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(getIsLoading);
  const otpSent: boolean | null = useSelector(getOtpSent);
  const isAuthenticated: boolean = useSelector(getIsAuthenticated);
  const userProfile: UserProfile = useSelector(getUserProfile);
  const errorSendingOtp: ErrorType = useSelector(getErrorSendingOtp);
  const errorVerifyOtp: boolean = useSelector(getErrorVerifyOtp);
  const mobile: string = useSelector(getMobile);
  const token: string = useSelector(getToken);

  const sendOtp = (action: SendOtpRequestPayload) => {
    dispatch(requestOtp(action));
  };

  const resetOtpSent = () => {
    dispatch(resetOtp({ otpSent: false }));
  };

  const verifyOtp = (action: VerifyOtpRequestPayload) => {
    dispatch(requestVerifyOtp(action));
  };

  const logoutUser = () => {
    dispatch(logout());
  };

  return {
    mobile,
    isLoading,
    otpSent,
    token,
    userProfile,
    isAuthenticated,
    errorVerifyOtp,
    errorSendingOtp,
    sendOtp,
    resetOtpSent,
    verifyOtp,
    logoutUser
  };
};
