import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/button';
import DataTable, { type HeaderType } from '../../common/datatable';
import CustomizedDialog from '../../common/dialog';
import CustomPagination from '../../common/pagination';
import RowsPerPage from '../../common/rowsPerPage';
import SearchInput from '../../common/searchInput';
import CustomTabs, { type tabOptionProps } from '../../common/tabs';
import ToastMessage from '../../common/toastMessage';
import { ROLES } from '../../constants';
import { useOnBoard } from '../../hooks/onBoard';
import AddOperator from '../addOperator';
import AddSDO from '../addSDO';
import { useStyles } from './styles';

const tabs: tabOptionProps[] = [
  { id: 0, label: 'SDO' },
  { id: 1, label: 'Operator' }
];

const OnBoard: React.FC = () => {
  // classes
  const classes = useStyles();

  const viewLicenseImage = (row: any) => {
    setViewLicenceImage(true);
    fetchUser({ ...row, userId: row?.userId });
    setShowDialog(true);
  };

  // table headers
  const operatorHeader: HeaderType[] = [
    {
      label: 'Name',
      key: 'name',
      enableSort: false
    },
    {
      label: 'Mobile',
      key: 'mobileNo',
      enableSort: false
    },
    {
      label: 'Driving / Pilot licence',
      key: 'licenseNumber',
      enableSort: false,
      isLink: true,
      actions: viewLicenseImage
    },
    {
      label: 'State',
      key: 'state',
      enableSort: true
    },
    {
      label: 'Cluster',
      key: 'cluster',
      enableSort: true
    },
    {
      label: 'SDO Name',
      key: 'sdoName',
      enableSort: false
    },
    {
      label: 'Machine ID',
      key: 'machineId',
      enableSort: false
    },
    {
      label: 'Type',
      key: 'machineType',
      enableSort: true
    },
    {
      label: 'Status',
      key: 'status',
      enableSort: true,
      customStyle: classes?.statusLabel
    },
    {
      label: 'Action',
      key: '',
      enableSort: false,
      actions: (index: any) => renderAction(index)
    }
  ];

  const SDOHeader: HeaderType[] = [
    {
      label: 'Name',
      key: 'name',
      enableSort: false
    },
    {
      label: 'Mobile',
      key: 'mobileNo',
      enableSort: false
    },
    {
      label: 'State',
      key: 'state',
      enableSort: true
    },
    {
      label: 'Cluster',
      key: 'cluster',
      enableSort: true,
      customStyle: classes.clusterContainer
    },
    {
      label: 'Status',
      key: 'status',
      enableSort: false,
      customStyle: classes?.statusLabel
    },
    {
      label: 'Action',
      key: '',
      enableSort: false,
      actions: (index: any) => renderAction(index)
    }
  ];

  // custom hooks
  const {
    userList: { userProfiles, totalPages },
    user,
    message,
    errorMessage,
    isUserListLoading,
    fetchUserList,
    fetchUser,
    fetchResetOnboardStates
  } = useOnBoard();

  // states
  const [view, setView] = useState<boolean>(false);
  const [viewLicenceImage, setViewLicenceImage] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);

  // effects
  useEffect(() => {
    fetchResetOnboardStates('message');
  }, []);

  useEffect(() => {
    if (message) {
      fetchUserList({
        userrole: tabIndex === 1 ? ROLES?.OPERATOR : ROLES?.SDO,
        pgno: 0,
        pgsize: 10,
        searchkey: searchText
      });
      setShowDialog(false);
    }
    setTimeout(() => {
      fetchResetOnboardStates('message');
    }, 5000);
  }, [message]);

  useEffect(() => {
    setTimeout(() => {
      fetchResetOnboardStates('errorMessage');
    }, 5000);
  }, [errorMessage]);

  useEffect(() => {
    setSearchText('');
    setPageNumber(0);
    fetchResetOnboardStates('user');
  }, [tabIndex]);

  useEffect(() => {
    fetchUserList({
      userrole: tabIndex === 1 ? ROLES?.OPERATOR : ROLES?.SDO,
      pgno: searchText ? 0 : pageNumber,
      pgsize: rowsPerPage,
      searchkey: searchText
    });
  }, [tabIndex, searchText, rowsPerPage, pageNumber]);

  useEffect(() => {
    fetchResetOnboardStates('user');
    if (!showDialog) {
      setViewLicenceImage(false);
    }
  }, [showDialog]);
  // handlers
  const onViewUserClickHandler = (data: any) => {
    setViewLicenceImage(false);
    fetchUser({ ...data, userId: data?.userId });
    setView(true);
    setShowDialog(true);
  };

  const renderAction = (index: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          style={{ cursor: 'pointer' }}
          src={`${process.env.PUBLIC_URL}/view.png`}
          onClick={() => {
            onViewUserClickHandler(index);
          }}
        />
      </div>
    );
  };

  const handleChangeTabHandler = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (eventVal: string) => {
    setSearchText(eventVal?.toLowerCase());
  };

  const handlePageChange = (pageNo: number) => {
    setPageNumber(pageNo - 1);
  };

  return (
    <div className={classes.onBoardContainer}>
      <CustomTabs
        options={tabs}
        optionLabel={'label'}
        optionValue={'id'}
        handleChange={handleChangeTabHandler}
        tabIndex={tabIndex}
      >
        {tabIndex === 1
          ? (
          <>
            <Box className={classes.inputContainer}>
              <CustomButton
                label="Add Operator"
                onClick={() => {
                  setView(false);
                  setShowDialog(!showDialog);
                }}
                customStyle={classes.addButton}
                startIcon={
                  <img src={`${process.env.PUBLIC_URL}/add.png`} alt="add" />
                }
              />
              <Box style={{ width: '22%', border: '1px solid #C2C7D0' }}>
                <SearchInput
                  onSearch={handleSearchChange}
                  placeholder="Search Operator/ mobile no."
                  searchText={searchText}
                  customStyle={classes.searchInput}
                ></SearchInput>
              </Box>
            </Box>
            <Box className={classes.customTableContainer}>
              <DataTable
                data={userProfiles}
                headers={operatorHeader}
                stickyHeaders={[]}
                loading={isUserListLoading}
              ></DataTable>
            </Box>
            <CustomizedDialog
              customStyle={viewLicenceImage ? classes.viewLicennseImgCustomizedDialog : classes.customizedDialog}
              showDialog={showDialog}
              setShowDialog={() => {
                setShowDialog(!showDialog);
              }}
              title={viewLicenceImage ? 'Licence Image' : view ? 'View Operator' : 'Add Operator'}
            >
              <AddOperator
                view={view}
                data={user}
                setView={(e) => {
                  setView(e);
                }}
                setShowDialog={(e) => {
                  setShowDialog(e);
                }}
                viewLicenceImage={viewLicenceImage}
              ></AddOperator>
            </CustomizedDialog>
          </>
            )
          : tabIndex === 0
            ? (
          <>
            <Box className={classes.inputContainer}>
              <CustomButton
                label="Add SDO"
                onClick={() => {
                  setView(false);
                  setShowDialog(!showDialog);
                }}
                customStyle={classes.addButton}
                startIcon={
                  <img src={`${process.env.PUBLIC_URL}/add.png`} alt="add" />
                }
              />
              <Box style={{ width: '20%', border: '1px solid #C2C7D0' }}>
                <SearchInput
                  onSearch={handleSearchChange}
                  placeholder="Search SDO"
                  searchText={searchText}
                  customStyle={classes.searchInput}
                ></SearchInput>
              </Box>
            </Box>
            <Box className={classes.customTableContainer}>
              <DataTable
                data={userProfiles}
                headers={SDOHeader}
                stickyHeaders={[]}
                loading={isUserListLoading}
              ></DataTable>
            </Box>
            <CustomizedDialog
              showDialog={showDialog}
              setShowDialog={() => {
                setShowDialog(!showDialog);
              }}
              title={view ? 'View SDO' : 'Add SDO'}
            >
              <AddSDO
                view={view}
                data={user}
                setView={(e) => {
                  setView(e);
                }}
                setShowDialog={(e) => {
                  setShowDialog(e);
                }}
              ></AddSDO>
            </CustomizedDialog>
          </>
              )
            : null}
      </CustomTabs>
      <Box style={{ display: 'flex', marginTop: '8px', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          style={{
            position: 'absolute',
            left: '53%',
            transform: 'translateX(-50%)'
          }}
        >
          {totalPages > 0 && !isUserListLoading && (
            <CustomPagination
              onChangePageNumber={handlePageChange}
              pageNumber={pageNumber + 1}
              totalPages={totalPages}
            />
          )}
        </Box>
        <Box style={{ marginLeft: 'auto' }}>
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(e: number) => {
              setRowsPerPage(e);
            }}
          />
        </Box>
      </Box>
      {message && (
        <ToastMessage message={message} errorType="success"></ToastMessage>
      )}
       {errorMessage && (
            <ToastMessage
              message={errorMessage}
              errorType="error"
            ></ToastMessage>
       )}
    </div>
  );
};

export default OnBoard;
