import { apiUrls } from '../api/apiUrls';
import { getApiRequest, postApiRequest, putApiRequest } from '../api/api';
import { type AxiosError } from 'axios';
import { type getUserRequestPayload, type updateUserRequestPayload, type addUserRequestPayload, type getUserListRequestPayload } from '../redux/onBoard/types';
// import { type getClustorRequestPayload, type getMachineRequestPayload, type getOrderDetailsRequestPayload, type getSDORequestPayload } from '../redux/filter/types'

export const getUserListService = async (body: getUserListRequestPayload) => {
  try {
    const { userrole, pgno, pgsize, searchkey } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.USER_ONBOARD_URL}?userrole=${userrole}&pgno=${pgno}&pgsize=${pgsize}&searchkey=${searchkey}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const addUserService = async (body: addUserRequestPayload) => {
  try {
    const response: unknown = await postApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.USER_ONBOARD_URL}`,
      body,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const getUserService = async (body: getUserRequestPayload) => {
  try {
    const { userId } = body;

    const response: unknown = await getApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.USER_ONBOARD_URL}/${userId}`,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const updateUserService = async (body: updateUserRequestPayload) => {
  try {
    const { userId } = body;

    const response: unknown = await putApiRequest(
      `${apiUrls.BASE_URL}${apiUrls.USER_ONBOARD_URL}/${userId}`,
      body,
      {
      }
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
