import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getMachineListService, reAssignOrderListService } from '../../services/reAssign';
import { getMachineListFailed, getMachineListSuccess, reAssignOrderListFailed, reAssignOrderListSuccess } from './action';
import { GET_MACHINE_LIST, REASSIGN_ORDER_LIST } from './actionType';
import { type reAssignOrderListRequestType, type getMachineListSucessPayloadType, type GetMachineListType } from './types';

function * getMachineList (action: GetMachineListType) {
  try {
    const response: getMachineListSucessPayloadType = yield call(getMachineListService, action.payload);
    yield put(getMachineListSuccess({ ...response }));
  } catch (error: any) {
    const { message } = error;
    yield put(getMachineListFailed({ error: true, errorMessage: message }));
  }
}

function * reAssignOrderList (action: reAssignOrderListRequestType) {
  try {
    const response: string = yield call(reAssignOrderListService, action.payload);
    yield put(reAssignOrderListSuccess({ message: response }));
  } catch (error: any) {
    const { message } = error;
    yield put(reAssignOrderListFailed({ error: true, errorMessage: message }));
  }
}

function * reAssignSaga () {
  yield all([takeLatest(GET_MACHINE_LIST, getMachineList)]);
  yield all([takeLatest(REASSIGN_ORDER_LIST, reAssignOrderList)]);
}
export default reAssignSaga;
