import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    height: '48px',
    display: 'flex',
    margin: '0px 24px',
    width: '-webkit-fill-available',
    alignItems: 'center'
  },
  profileContainer: {
    marginLeft: 'auto'
  },
  profileIcon: {
    height: '24px',
    width: '24px',
    borderRadius: 16,
    border: `1px solid ${COLORS.Black}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameInitials: {
    ...fontFamilies.semiBold1218,
    background: `${COLORS.Navy} `,
    height: '24px ',
    width: '24px '
  }
})
);
