import {
  SEND_OTP,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILED,
  RESET_OTP_SENT,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  LOGOUT_USER
} from './actionType';
import { type OtpActions, type LoginState } from './types';

const initialState: LoginState = {
  token: '',
  userProfile: {
    id: '',
    isActive: null,
    isAnanthamUser: null,
    mobileNo: '',
    operatorId: null,
    operatorName: '',
    uniqueDistrictId: null,
    uniqueTalukaId: null,
    userRole: '',
    userId: ''
  },
  mobile: '',
  otpSent: false,
  isLoading: false,
  isAuthenticated: false,
  errorVerifyOtp: false,
  errorSendingOtp: {
    date: '',
    errorCode: '',
    message: ''
  }
};

const defaultState: LoginState = JSON.parse(JSON.stringify(initialState));

export const reducer = (state = defaultState, action: OtpActions): LoginState => {
  switch (action.type) {
    case SEND_OTP:
      return {
        ...state,
        isLoading: true,
        mobile: action.payload.mobile,
        errorVerifyOtp: false
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpSent: action?.payload?.otpSent,
        errorSendingOtp: {
          message: '',
          date: '',
          errorCode: ''
        }
      };
    case SEND_OTP_FAILED:
      return { ...state, isLoading: false, errorSendingOtp: action.payload.error };
    case RESET_OTP_SENT:
      return { ...state, otpSent: action?.payload?.otpSent };
    case VERIFY_OTP:
      return { ...state, isLoading: true };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        userProfile: action.payload.userProfile,
        isAuthenticated: action.payload.token?.length > 0,
        errorVerifyOtp: false
      };
    case VERIFY_OTP_FAILED:
      return { ...state, isLoading: false, errorVerifyOtp: action.payload.error };
    case LOGOUT_USER:
      return JSON.parse(JSON.stringify(initialState)) as LoginState;
    default:
      return state;
  }
};

export default reducer;
