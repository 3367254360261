import {
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAILED,

  GET_ORDER_STATUS,
  GET_ORDER_STATUS_FAILED,
  GET_ORDER_STATUS_SUCCESS,

  GET_CLUSTORS,
  GET_CLUSTORS_FAILED,
  GET_CLUSTORS_SUCCESS,

  GET_SDO,
  GET_SDO_FAILED,
  GET_SDO_SUCCESS,

  GET_MACHINES,
  GET_MACHINES_SUCCESS,
  GET_MACHINES_FAILED,

  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILED,

  SET_FILTER_REQUEST,
  GET_FILTER_REQUEST,

  RESET_FILTER_STATES,

  GET_DISTRICTS,
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAILED,

  GET_OPERATOR_LIST,
  GET_OPERATOR_LIST_SUCCESS,
  GET_OPERATOR_LIST_FAILED,
  GET_GEO_HIERARCHY_DISTRICTS,
  GET_GEO_HIERARCHY_DISTRICTS_FAILED,
  GET_GEO_HIERARCHY_DISTRICTS_SUCCESS

} from './actionType';
import {
  type GetOrderStatusFailedType,
  type GetOrderStatusSuccessType,
  type GetOrderStatusType,
  type OrderStatusFailedPayloadtype,
  type OrderStatusType,

  type GetAllStatesSuccessType,
  type GetAllStatesFailedType,
  type GetAllStatesType,
  type getAllStatesFailedPayloadtype,
  type getAllStatesSucessPayloadType,
  type GetClustorsType,
  type getClutorsSucessPayloadType,
  type GetClutorsSuccessType,
  type getClustorsFailedPayloadtype,
  type GetClutorsFailedType,
  type getClustorRequestPayload,
  type getSDORequestPayload,
  type GetSDOType,
  type getSDOSucessPayloadType,
  type GetSDOSuccessType,
  type getSDOFailedPayloadtype,
  type GetSDOFailedType,
  type getMachineRequestPayload,
  type GetMachineType,
  type GetMachineSuccessType,
  type getMachineFailedPayloadtype,
  type GetMachineFailedType,
  type getMachineSuccessPayloadType,
  type getOrderDetailsSuccessPayloadType,
  type GetOrderDetailsType,
  type GetOrderDetailsSuccessType,
  type getOrderDetailsFailedPayloadtype,
  type GetOrderDetailsFailedType,
  type getOrderDetailsRequestPayload,
  type SetFilterRequestType,
  type filterRequestPayloadType,
  type GetFilterRequestType,
  type ResetFilterStates,
  type FilterState,
  type getOrderStatusRequestPayload,
  type getDistrictRequestPayload,
  type GetDistrictType,
  type getDistrictSuccessPayloadType,
  type GetDistrictSuccessType,
  type getDistrictFailedPayloadtype,
  type GetDistrictFailedType,
  type getOperatorListFailedPayloadtype,
  type GetOperatorListFailedType,
  type getOperatorListRequestPayload,
  type GetOperatorListSuccessType,
  type getOperatorListSucessPayloadType,
  type GetOperatorListType,
  type getGeoHierarchyDistrictsFailedPayloadtype,
  type GetGeoHierarchyDistrictsFailedType,
  type getGeoHierarchyDistrictsRequestPayload,
  type getGeoHierarchyDistrictsSuccessPayloadType,
  type GetGeoHierarchyDistrictsSuccessType,
  type GetGeoHierarchyDistrictsType
} from './types';

// for statuses

export const getOrderStatus = (payload: getOrderStatusRequestPayload): GetOrderStatusType => {
  return {
    type: GET_ORDER_STATUS,
    payload
  };
};

export const getOrderStatusSuccess = (
  payload: OrderStatusType
): GetOrderStatusSuccessType => {
  return {
    type: GET_ORDER_STATUS_SUCCESS,
    payload
  };
};

export const getOrderStatusFailed = (
  payload: OrderStatusFailedPayloadtype
): GetOrderStatusFailedType => {
  return {
    type: GET_ORDER_STATUS_FAILED,
    payload
  };
};

// for states

export const getAllStates = (): GetAllStatesType => {
  return {
    type: GET_ALL_STATES
  };
};

export const getAllStatesSuccess = (
  payload: getAllStatesSucessPayloadType
): GetAllStatesSuccessType => {
  return {
    type: GET_ALL_STATES_SUCCESS,
    payload
  };
};

export const getAllStatesFailed = (
  payload: getAllStatesFailedPayloadtype
): GetAllStatesFailedType => {
  return {
    type: GET_ALL_STATES_FAILED,
    payload
  };
};

// for clustors

export const getClustors = (
  payload: getClustorRequestPayload
): GetClustorsType => {
  return {
    type: GET_CLUSTORS,
    payload
  };
};

export const getClutorsSuccess = (
  payload: getClutorsSucessPayloadType
): GetClutorsSuccessType => {
  return {
    type: GET_CLUSTORS_SUCCESS,
    payload
  };
};

export const getClutorsFailed = (
  payload: getClustorsFailedPayloadtype
): GetClutorsFailedType => {
  return {
    type: GET_CLUSTORS_FAILED,
    payload
  };
};

// for Opeartor List

export const getOpearatorList = (
  payload: getOperatorListRequestPayload
): GetOperatorListType => {
  return {
    type: GET_OPERATOR_LIST,
    payload
  };
};

export const getOpearatorListSuccess = (
  payload: getOperatorListSucessPayloadType
): GetOperatorListSuccessType => {
  return {
    type: GET_OPERATOR_LIST_SUCCESS,
    payload
  };
};

export const getOpearatorListFailed = (
  payload: getOperatorListFailedPayloadtype
): GetOperatorListFailedType => {
  return {
    type: GET_OPERATOR_LIST_FAILED,
    payload
  };
};

// for SDOs

export const getSDOs = (
  payload: getSDORequestPayload
): GetSDOType => {
  return {
    type: GET_SDO,
    payload
  };
};

export const getSDOsSuccess = (
  payload: getSDOSucessPayloadType
): GetSDOSuccessType => {
  return {
    type: GET_SDO_SUCCESS,
    payload
  };
};

export const getSDOsFailed = (
  payload: getSDOFailedPayloadtype
): GetSDOFailedType => {
  return {
    type: GET_SDO_FAILED,
    payload
  };
};

// for Machines

export const getMachines = (
  payload: getMachineRequestPayload
): GetMachineType => {
  return {
    type: GET_MACHINES,
    payload
  };
};

export const getMachinesSuccess = (
  payload: getMachineSuccessPayloadType
): GetMachineSuccessType => {
  return {
    type: GET_MACHINES_SUCCESS,
    payload
  };
};

export const getMachinesFailed = (
  payload: getMachineFailedPayloadtype
): GetMachineFailedType => {
  return {
    type: GET_MACHINES_FAILED,
    payload
  };
};

// for get order details

export const getOrderDetails = (
  payload: getOrderDetailsRequestPayload
): GetOrderDetailsType => {
  return {
    type: GET_ORDER_DETAILS,
    payload
  };
};

export const getOrderDetailsSuccess = (
  payload: getOrderDetailsSuccessPayloadType
): GetOrderDetailsSuccessType => {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload
  };
};

export const getOrderDetailsFailed = (
  payload: getOrderDetailsFailedPayloadtype
): GetOrderDetailsFailedType => {
  return {
    type: GET_ORDER_DETAILS_FAILED,
    payload
  };
};

// for set filter request

export const setFilterRquest = (
  payload: filterRequestPayloadType
): SetFilterRequestType => {
  return {
    type: SET_FILTER_REQUEST,
    payload
  };
};

export const getFilterRquest = (): GetFilterRequestType => {
  return {
    type: GET_FILTER_REQUEST
  };
};

export const resetFilterStates = (
  payload: keyof FilterState
): ResetFilterStates => {
  return {
    type: RESET_FILTER_STATES,
    payload
  };
};

// for districts

export const getDistricts = (
  payload: getDistrictRequestPayload
): GetDistrictType => {
  return {
    type: GET_DISTRICTS,
    payload
  };
};

export const getDistrictsSuccess = (
  payload: getDistrictSuccessPayloadType
): GetDistrictSuccessType => {
  return {
    type: GET_DISTRICTS_SUCCESS,
    payload
  };
};

export const getDistrictsFailed = (
  payload: getDistrictFailedPayloadtype
): GetDistrictFailedType => {
  return {
    type: GET_DISTRICTS_FAILED,
    payload
  };
};

// for geo hierarchy districts

export const getGeoHierarchyDistricts = (
  payload: getGeoHierarchyDistrictsRequestPayload
): GetGeoHierarchyDistrictsType => {
  return {
    type: GET_GEO_HIERARCHY_DISTRICTS,
    payload
  };
};

export const getGeoHierarchyDistrictsSuccess = (
  payload: getGeoHierarchyDistrictsSuccessPayloadType
): GetGeoHierarchyDistrictsSuccessType => {
  return {
    type: GET_GEO_HIERARCHY_DISTRICTS_SUCCESS,
    payload
  };
};

export const getGeoHierarchyDistrictsFailed = (
  payload: getGeoHierarchyDistrictsFailedPayloadtype
): GetGeoHierarchyDistrictsFailedType => {
  return {
    type: GET_GEO_HIERARCHY_DISTRICTS_FAILED,
    payload
  };
};
