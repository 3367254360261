import { useSelector, useDispatch } from 'react-redux';
import { getDashboardDetails, getIsLoading } from '../redux/dashboard/selectors';
import { type DashboardDetailsType, type GetAllDetailsRequestPayload } from '../redux/dashboard/types';
import { getAllDetails } from '../redux/dashboard/actions';

interface DashboardType {
  isLoading: boolean
  dashboardDetails: DashboardDetailsType
  fetchDashboardDetails: (payload: GetAllDetailsRequestPayload) => void
}

export const useDashboardDetails = (): DashboardType => {
  const dispatch = useDispatch();

  const isLoading: boolean = useSelector(getIsLoading);
  const dashboardDetails: DashboardDetailsType = useSelector(getDashboardDetails);

  const fetchDashboardDetails = (payload: GetAllDetailsRequestPayload) => {
    dispatch(getAllDetails(payload));
  };

  return {
    isLoading,
    dashboardDetails,
    fetchDashboardDetails
  };
};
