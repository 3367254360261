import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { useStyles } from './styles';

interface CustomSelectBoxProps {
  label?: string
  placeholder?: string
  view?: boolean
  value?: string
  required?: boolean
  options?: any[]
  optionLabel: string
  optionValue: string
  name?: string
  disable?: boolean
  onChange?: (e: SelectChangeEvent) => void
}
const CustomSelectBox: React.FC<CustomSelectBoxProps> = ({
  label, placeholder, view = false, name,
  value,
  required,
  options,
  disable = false,
  optionLabel,
  optionValue,
  onChange
}) => {
  const classes = useStyles();

  // const handleChange = (event: SelectChangeEvent) => {
  //   onChange(event);
  // }

  return (
    <Box>
      <label className={view ? classes.viewLabel : classes.label}>{label}{required && <span> *</span>}</label>
       {view
         ? <div className={classes.valueLabel}>{value}</div>
         : <FormControl fullWidth >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.inputBox}
          renderValue={value !== '' ? undefined : () => placeholder}
          displayEmpty
          style={{ color: value === '' ? '#a3a4a8' : 'black' }}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disable}
        >
          {/* <MenuItem value="" disabled hidden>
            {placeholder}
          </MenuItem> */}
          {options && options?.length > 0 ? options.map((option: any) => <MenuItem key ={`selectopt_${option[optionValue]}`} value={option[optionValue]}>{option[optionLabel]}</MenuItem>) : <div style={{ paddingLeft: '16px' }}>No data available</div>}

        </Select>
      </FormControl>}
    </Box>
  );
};

export default CustomSelectBox;
