import { useDispatch, useSelector } from 'react-redux';
import {
  getStates
} from '../redux/machine/selectors';
import { type updateMachineStatusRequestPayload, type machineState, type getMachineListByStatusRequestPayload, type machineDetailsType } from '../redux/machine/types';
import { getMachineListByStatusRequest, resetMachineStates, saveMachineDetailsRequest, setMachineStates, updateMachineDetailsRequest, updateMachineStatusRequest } from '../redux/machine/action';

interface useMachineType extends machineState {
  fetchUpdateMachineStatus: (action: updateMachineStatusRequestPayload) => void
  fetchSaveMachineDetails: (action: machineDetailsType) => void
  fetchUpdateMachineDetails: (action: machineDetailsType) => void
  fetchGetMachineByStatusList: (action: getMachineListByStatusRequestPayload) => void
  fetchResetMachineStates: (action: keyof machineState) => void
  fetchSetMachineStates: (action: any) => void
}

export const useMachine = (): useMachineType => {
  const dispatch = useDispatch();
  // const isMachineListLoading: boolean = useSelector(getMachineListLoading)
  // const machineList: getMachineListSucessPayloadType = useSelector(getMachineList)
  // const error: boolean = useSelector(getMachineListError)

  const states: machineState = useSelector(getStates);

  const fetchUpdateMachineStatus = (action: updateMachineStatusRequestPayload) => {
    dispatch(updateMachineStatusRequest(action));
  };

  const fetchSaveMachineDetails = (action: machineDetailsType) => {
    dispatch(saveMachineDetailsRequest(action));
  };

  const fetchUpdateMachineDetails = (action: machineDetailsType) => {
    dispatch(updateMachineDetailsRequest(action));
  };

  const fetchGetMachineByStatusList = (action: getMachineListByStatusRequestPayload) => {
    dispatch(getMachineListByStatusRequest(action));
  };

  const fetchResetMachineStates = (action: keyof machineState) => {
    dispatch(resetMachineStates(action));
  };

  const fetchSetMachineStates = (action: any) => {
    dispatch(setMachineStates(action));
  };

  return {
    ...states,
    fetchUpdateMachineStatus,
    fetchGetMachineByStatusList,
    fetchResetMachineStates,
    fetchSetMachineStates,
    fetchSaveMachineDetails,
    fetchUpdateMachineDetails
  };
};
