import { type AppState, type Selector } from '../rootReducer';
import { type SDOItemType, type clustorItemType, type filterRequestPayloadType, type MachineItemType, type OrderDetailsType, type stateItemType, type statusItemType, type DistrictItemType, type OperatorListItemType, type GeoHierarchyDistrictType } from './types';

export const getOrderStatusLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getOrderStatusError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getOrderStatus: Selector<statusItemType[]> = (state: AppState) =>
  state.filter.status;

export const getMachineTypes: Selector<statusItemType[]> = (state: AppState) =>
  state.filter.machineTypes;

export const getMachineBreakDownReason: Selector<statusItemType[]> = (state: AppState) =>
  state.filter?.machineBreakdownReason;

export const getPageSize: Selector<number> = (state: AppState) =>
  state.filter.filterRequest.pageSize;

export const getAllStateLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getAllStateError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getAllStates: Selector<stateItemType[]> = (state: AppState) =>
  state.filter.states;

export const getClustorsLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getClustorsError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getClustors: Selector<clustorItemType[]> = (state: AppState) =>
  state.filter.clustors;

export const getOperatorListLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getOperatorListError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getOperatorList: Selector<OperatorListItemType[]> = (state: AppState) =>
  state.filter.operators;

export const getSDOLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getSDOError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getSDO: Selector<SDOItemType[]> = (state: AppState) =>
  state.filter.sdos;

export const getMachineLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getMachineError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getMachine: Selector<MachineItemType[]> = (state: AppState) =>
  state.filter.machines;

export const getOrderDetailsLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getOrderDetailsError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getOrderDetails: Selector<OrderDetailsType> = (state: AppState) =>
  state.filter.orderDetails;

export const getFilterRequest: Selector<filterRequestPayloadType> = (state: AppState) =>
  state.filter.filterRequest;

export const getAllOrderDetails: Selector<OrderDetailsType> = (state: AppState) =>
  state.filter.allOrderDetails;

export const getExcelDownLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isExcelDownloading;

export const getOrderDetailsloading: Selector<boolean> = (state: AppState) =>
  state.filter.isOrderDetailsloading;

export const getDistrictLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getDistrictError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getDistrict: Selector<DistrictItemType[]> = (state: AppState) =>
  state.filter.districts;

export const getGeoHierarchyDistrictsLoading: Selector<boolean> = (state: AppState) =>
  state.filter.isLoading;

export const getGeoHierarchyDistrictsError: Selector<boolean> = (state: AppState) =>
  state.filter.error;

export const getGeoHierarchyDistricts: Selector<GeoHierarchyDistrictType[]> = (state: AppState) =>
  state.filter.geoHierarchyDistricts;
