import { useSelector, useDispatch } from 'react-redux';
import {
  getOrderStatus,
  getOrderStatusLoading,
  getOrderStatusError
} from '../redux/status/selectors';
import { getOrderStatus as getOrder } from '../redux/status/action';
import { type OrderStatusState } from '../redux/status/types';

interface OrderStatusType extends OrderStatusState {
  fetchOrderStatus: () => void
}

export const useOrderStatus = (): OrderStatusType => {
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector(getOrderStatusLoading);
  const error: boolean = useSelector(getOrderStatusError);
  const status: string[] = useSelector(getOrderStatus);

  const fetchOrderStatus = () => {
    dispatch(getOrder());
  };

  return {
    error,
    isLoading,
    status,
    fetchOrderStatus
  };
};
