import { ROLES } from '../../constants';

export interface MenuProps {
  label: string
  imagePath: string
  selected: boolean
  roles: string
}

export const Menu: MenuProps[] = [
  { label: 'Home', imagePath: 'home-icon.png', selected: false, roles: `${ROLES.ADMIN},${ROLES.SDO},${ROLES.VENDOR_ADMIN},${ROLES.DM},${ROLES.TM},${ROLES.BM},${ROLES.NSM}` },
  { label: 'Reports', imagePath: 'spray-icon.png', selected: true, roles: `${ROLES.ADMIN},${ROLES.SDO},${ROLES.VENDOR_ADMIN}` },
  { label: 'Cluster', imagePath: 'on-board.png', selected: false, roles: `${ROLES.ADMIN},${ROLES.SDO}` },
  { label: 'Onboard', imagePath: 'on-board.png', selected: false, roles: `${ROLES.ADMIN},${ROLES.SDO}` },
  { label: 'Machine', imagePath: 'on-board.png', selected: false, roles: `${ROLES.ADMIN},${ROLES.SDO}` },
  { label: 'Re-assign', imagePath: 'on-board.png', selected: false, roles: `${ROLES.ADMIN},${ROLES.SDO}` }
];
