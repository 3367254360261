import { all } from 'redux-saga/effects';
import orderStatus from '../redux/status/sagas';
import filterSaga from '../redux/filter/sagas';
import onBoardSaga from '../redux/onBoard/sagas';
import loginSaga from '../redux/login/sagas';
import reAssignSaga from './reAssign/sagas';
import machineSaga from './machine/sagas';
import clusterSaga from './cluster/sagas';
import dashboardSaga from './dashboard/sagas';

function * rootSaga () {
  yield all([
    orderStatus(),
    loginSaga(),
    filterSaga(),
    onBoardSaga(),
    reAssignSaga(),
    machineSaga(),
    clusterSaga(),
    dashboardSaga()
  ]);
}

export default rootSaga;
