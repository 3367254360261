import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';

export const useStyles = makeStyles(() => ({
  container: {
    background: '#F3F4F6',
    minHeight: 'calc(100vh - 220px) !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  noDataHeader: {
    fontSize: 20, fontWeight: 600
  },
  noDataSubHeader: {
    color: COLORS.SubHEaderGray,
    fontSize: 14,
    fontWeight: 400
  }
}));
