export const UPDATE_MACHINE_STATUS = 'UPDATE_MACHINE_SATUS';
export const UPDATE_MACHINE_STATUS_SUCCESS = 'UPDATE_MACHINE_STATUS_SUCCESS';
export const UPDATE_MACHINE_STATUS_FAILED = 'UPDATE_MACHINE_STATUS_FAILED';

export const GET_MACHINE_LIST_BY_STATUS = 'GET_MACHINE_LIST_BY_STATUS';
export const GET_MACHINE_LIST_BY_STATUS_SUCCESS = 'GET_MACHINE_LIST_BY_STATUS_SUCCESS';
export const GET_MACHINE_LIST_BY_STATUS_FAILED = 'GET_MACHINE_LIST_BY_STATUS_FAILED';

export const SAVE_MACHINE_DETAILS = 'SAVE_MACHINE_DETAILS';
export const SAVE_MACHINE_DETAILS_SUCCESS = 'SAVE_MACHINE_DETAILS_SUCCESS';
export const SAVE_MACHINE_DETAILS_FAILED = 'SAVE_MACHINE_DETAILS_FAILED';

export const UPDATE_MACHINE_DETAILS = 'UPDATE_MACHINE_DETAILS';
export const UPDATE_MACHINE_DETAILS_SUCCESS = 'UPDATE_MACHINE_DETAILS_SUCCESS';
export const UPDATE_MACHINE_DETAILS_FAILED = 'UPDATE_MACHINE_DETAILS_FAILED';

export const RESET_MACHINE_STATES = 'RESET_MACHINE_STATES';

export const SET_MACHINE_STATES = 'SET_MACHINE_STATES';
