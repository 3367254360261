import { combineReducers, type AnyAction } from 'redux';
import orderStatusReducer from './status/reducer';
import loginReducer from './login/reducer';
import filterReducer from './filter/reducer';
import onBoardReducer from './onBoard/reducer';
import reAssignReducer from './reAssign/reducer';
import machineReducer from './machine/reducer';
import clusterReducer from './cluster/reducer';
import dashboardReducer from './dashboard/reducer';
import { LOGOUT_USER } from './login/actionType';

export const appReducer = combineReducers({
  orderStatus: orderStatusReducer,
  login: loginReducer,
  filter: filterReducer,
  onBoard: onBoardReducer,
  reAssign: reAssignReducer,
  machine: machineReducer,
  cluster: clusterReducer,
  dashboard: dashboardReducer
});

export type AppState = ReturnType<typeof appReducer>;

export type Selector<T> = (state: AppState) => T;

// export default combineReducers({
//   orderStatus: orderStatusReducer,
//   login: loginReducer,
// });

const rootReducer = (state: AppState, action: AnyAction) => {
  if (action.type === LOGOUT_USER) {
    localStorage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
