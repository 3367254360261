import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  Select,
  FormControlLabel,
  Box
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import SearchInput from '../searchInput';
import { useStyles } from './styles';

interface CheckboxInputProps {
  label: string
  options: any
  customStyle?: any
  optionValue: string
  optionLabel: string
  subOptionlabel: string
  subOptionName: string
  subOptionValue: string
  isDisabled?: boolean
  selectedIds: any
  selectedNames: any
  setSelectedIds: (item: any, item1: any) => void
}

const NestedDropdown: React.FC<CheckboxInputProps> = ({
  label,
  options,
  optionValue,
  subOptionName,
  customStyle,
  optionLabel,
  subOptionlabel,
  subOptionValue,
  selectedIds,
  isDisabled,
  setSelectedIds
}) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState<string>('');
  const [opt, setOpt] = useState(options);
  const [expandableArr, setexpandableArr] = useState<any>([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (opt && opt?.length !== options?.length) { setOpt(options); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (selectedIds?.length === 0) {
      const temp = [...opt];
      temp.map((e: any) => {
        e.isChecked = false;
        e[subOptionName]?.map((op: any) => {
          op.isChecked = false;
        });
      });

      setOpt([...temp]);
    }
  }, [selectedIds]);
  const updateSelectedState = (temp: any) => {
    const tempArr: any = [];
    const tempNamesArr: any = [];

    temp.map((e: any) => {
      e[subOptionName].map((op: any) => {
        if (op?.isChecked) {
          tempArr.push(op[optionValue]);
          tempNamesArr.push(op[optionLabel]);
        }
      });
    });

    setSelectedIds([...tempArr], [...tempNamesArr]);
  };

  const handleSearchChange = (eventVal: string) => {
    setSearchText(eventVal?.toLowerCase());
  };

  const handleChangeCheckbox = (event: any, item: any, optionval: number) => {
    let indeterminateFlag = 0;
    let indeterminateFlagForAll = 0;
    let temp = [...opt];
    temp = temp.map((optItem: any) => {
      if (item[subOptionValue] === optItem[subOptionValue]) {
        const obj = {
          ...optItem,
          [subOptionName]: optItem[subOptionName].map((e: any, inx: number) => {
            const tempE = {
              ...e,
              isChecked: optionval === e[optionValue] ? event.target.checked : e?.isChecked
            };
            if (tempE?.isChecked) {
              indeterminateFlag = indeterminateFlag + 1;
            }
            return tempE;
          }),
          isChecked: optItem[subOptionName]?.length === indeterminateFlag,
          indeterminate:
            optItem[subOptionName]?.length !== indeterminateFlag &&
            indeterminateFlag !== 0
        };
        return obj;
      } else {
        return { ...optItem };
      }
    });
    temp.map((e: any) => {
      if (e[subOptionValue] !== 0 && (e.isChecked)) {
        indeterminateFlagForAll += 1;
      }
    });

    if (indeterminateFlagForAll === 0 || indeterminateFlagForAll !== temp?.length - 1) {
      temp[0].indeterminate = true;
    } else {
      temp[0].indeterminate = false;
      temp[0].isChecked = true;
    }

    setOpt(temp);
    updateSelectedState(temp);
  };

  const handleCheckboxChange = async (event: any, item: any) => {
    let indeterminateFlagForAll = 0;
    let temp = [...opt];
    temp = await temp.map((optItem: any) => {
      if (item[subOptionValue] === 0) {
        return {
          ...optItem,
          [subOptionName]: optItem[subOptionName].map((e: any) => {
            const temp1 = { ...e, isChecked: event.target.checked };
            return temp1;
          }),
          isChecked: event.target.checked,
          indeterminate: false
        };
      } else if (item[subOptionValue] === optItem[subOptionValue]) {
        return {
          ...optItem,
          [subOptionName]: optItem[subOptionName].map((e: any) => {
            const temp2 = { ...e, isChecked: event.target.checked };
            return temp2;
          }),
          isChecked: event.target.checked,
          indeterminate: false
        };
      } else {
        return { ...optItem };
      }
    });

    temp.map((e: any) => {
      if (e[subOptionValue] !== 0 && e.isChecked) {
        indeterminateFlagForAll += 1;
      }
    });

    if (indeterminateFlagForAll !== 0 && indeterminateFlagForAll !== temp?.length - 1) {
      temp[0].indeterminate = true;
    } else {
      temp[0].indeterminate = false;
      temp[0].isChecked = true;
    }

    setOpt([...temp]);
    updateSelectedState(temp);
  };

  const children = (item: any) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} >
      {item[subOptionName]
        ?.filter((e: any) => e[optionLabel]?.toLowerCase().includes(searchText) || item[subOptionlabel]?.toLowerCase().includes(searchText))
        ?.map((ext: any, index: number) => (
          <FormControlLabel
            key={`${ext[optionLabel]}-${index}`}
            label={ext[optionLabel]}
            control={
              <Checkbox
                onChange={(e: any) => { handleChangeCheckbox(e, item, ext[optionValue]); }}
                checked={!!ext?.isChecked}
                className={classes.checkbox}
              />
            }
          />
        ))}
    </Box>
  );

  const expandDiv = (divNo: any) => {
    const tempExpandableArr = [...expandableArr];
    if (tempExpandableArr.includes(divNo)) {
      tempExpandableArr.splice(tempExpandableArr.indexOf(divNo), 1);
    } else {
      tempExpandableArr.push(divNo);
    }

    setexpandableArr([...tempExpandableArr]);
  };

  const onOpenSelectHandler = () => {
    setSearchText('');
    setexpandableArr([]);
  };
  return (
    <Select
      value=""
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      className={[classes.selectBox, customStyle].join(' ')}
      disabled={!!isDisabled}
      onOpen={onOpenSelectHandler}
    >
      <MenuItem disabled className={classes.displayNone} value="">
        {label}
      </MenuItem>
      <div className={classes.optionContainer}>
        <SearchInput
          onSearch={handleSearchChange}
          placeholder="Search"
          searchText={searchText}
        />
        <div className={classes.options}>
          {opt && opt?.length > 1
            ? opt
              ?.filter((item: any) =>
                item[subOptionName]?.filter((e: any) =>
                  e[optionLabel]?.toLowerCase().includes(searchText)
                )?.length > 0
                  ? true
                  : item[subOptionlabel]?.toLowerCase().includes(searchText)
              )
              .map((item: any, index: number) => (
                <div key={`${item[subOptionlabel]}-${index}`}>
                  <div className={classes.sublabel} key={`com${index}`}>
                    <FormControlLabel
                      label={item[subOptionlabel]}
                      control={
                        <Checkbox
                          checked={!!item?.isChecked}
                          indeterminate={item?.indeterminate}
                          onChange={async (e: any) => { await handleCheckboxChange(e, item); }}
                          className={classes.checkbox}
                        />
                      }
                    />
                    {item[subOptionValue] !== 0 && (
                      <img
                        onClick={() => { expandDiv(item[subOptionValue]); }}
                        src={`${process.env.PUBLIC_URL}/expand_more.png`}
                        alt="expand-more"
                        className={classes.expandIcon}
                      />
                    )}
                  </div>
                  {((!expandableArr.includes(item[subOptionValue]) && searchText) || (expandableArr.includes(item[subOptionValue]) && !searchText)) && children(item)}
                </div>
              ))
            : <div className={classes.noDataDiv}>No Data Available</div>}
        </div>
      </div>
    </Select>
  );
};

export default NestedDropdown;
