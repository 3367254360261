import { makeStyles } from '@mui/styles';
import { COLORS } from '../../resources/colors';
import { fontFamilies } from '../../resources/fonts';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  onBoardContainer: {
    padding: '20px',
    '& .css-1ps4owl-MuiTypography-root-MuiLink-root': {
      cursor: 'pointer'
    }
  },
  addButton: {
    // backgroundColor: '#14803C !important',
    // width: 'auto !important'
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: '#14803C !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
    // minWidth: '130px'
  },
  inputContainer: {
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #C2C7D0',
    padding: '20px',
    backgroundColor: '#F3F4F6'
  },
  searchInput: {
    backgroundColor: 'white !important'
  },
  checkBoxInput: {
    backgroundColor: '#F3F4F6'
  },
  customTableContainer: {
    '& .MuiTableContainer-root': {
      width: '100% !important',
      margin: '0px',
      minHeight: 'calc(100vh - 220px) !important',
      maxHeight: 'calc(100vh - 220px) !important'
    },
    '& .MuiTableRow-root': {
      height: '40px !important'
    },
    '& .tableContainerHelper': {
      minHeight: 'calc(100vh - 260px) !important',
      maxHeight: 'calc(100vh - 260px) !important'
    }
  },
  buttonContainer: {
    display: 'flex',
    height: '35px',
    marginTop: '44px',
    justifyContent: 'end',
    columnGap: '10px',
    alignItems: 'baseline'
  },
  applyButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.White} !important`,
    background: '#19A04B !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  resetButton: {
    ...fontFamilies.regular1420,
    color: `${COLORS.green_50} !important`,
    background: 'none !important',
    border: '1px dashed #19A04B !important',
    boxShadow: 'none !important',
    borderRadius: '4px !important',
    width: 'auto !important',
    textTransform: 'none'
  },
  confirmLabelText: {
    ...fontFamilies.regular1419
  },
  confirmTitle: {
    ...fontFamilies.semiBold1622
  },
  confirmcontainer: {
    padding: '0px 24px 24px'
  },
  statusLabel: {
    color: '#14803C'
  },
  filterSubContent: {
    paddingRight: '16px',
    width: '-webkit-fill-available'
  },
  customizedDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '520px !important'
    }
  },
  inActiveStatus: {
    ...fontFamilies.semiBold1624,
    color: '#696F88 !important'
  },
  pincodesContainer: {
    // width: '155px',
    display: 'flex',
    // marginRight: '-32px',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
    lineHeight: '20px'
  }
})
);
