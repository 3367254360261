import React, { useEffect, useState } from 'react';
import CheckBox from '../../common/Checkbox';
import NestedDropdown from '../../common/NestedDropdown';
import DateRangeInput from '../../common/dateRangeInput';
import SelectedValueBadge from '../../common/selectedValueBadge';
import { useStyles } from './styles';
import CustomButton from '../../common/button';
import { useFilter } from '../../hooks/useFilter';
import moment from 'moment';
import { useLogin } from '../../hooks';
import { ROLES } from '../../constants';

const Filter: React.FC = () => {
  // classes
  const classes = useStyles();

  // states
  // const [selectedStatusIds, setSelectedStatusIds] = useState<any>([]);
  // const [selectedStatesIds, setSelectedStatesIds] = useState<any>([]);
  const [resetFlag, setResetFlag] = useState<boolean>(false);

  // hooks
  const {
    fetchAllStates,
    states,
    machineTypes,
    fetchOrderStatus,
    status,
    clustors,
    fetchClustors,
    sdos,
    fetchSDOs,
    machines,
    fetchMachines,
    fetchOrderDetails,
    filterRequest,
    fetchFilterRequest,
    setFilterRequest,
    fetchResetFilterStates
  } = useFilter();
  const { userProfile: { userRole } } = useLogin();

  const { statesIds, clusterIds, sdoIds, statusIds, machineIds, sprayTypeIds, sprayTypeNames, statusNames, stateNames, clusterNames, sdoNames, machineNames, dateRangeLabel } = filterRequest;
  const { startDate, endDate } = filterRequest;

  useEffect(() => {
    fetchResetFilterStates('machines');
    fetchFilterRequest();
    fetchAllStates();
    fetchOrderStatus({ type: 'SPRAY_BOOKING_STATUS' });
    fetchClustors({ statesIds });
    fetchSDOs({ clusterIds });
    fetchMachines({ sdoIds, clusterIds: [] });
    // CHANGES for multiple API call
    // fetchOrderDetails(filterRequest);
  }, []);

  useEffect(() => {
    if (status?.length > 0) {
      fetchOrderStatus({ type: 'SPRAY_MACHINE_TYPE' });
    }
  }, [status?.length]);

  useEffect(() => {
    statesIds && statesIds?.length && fetchClustors({ statesIds });
    clusterIds && clusterIds?.length && fetchSDOs({ clusterIds });
    clusterIds && sdoIds && sdoIds?.length && fetchMachines({ sdoIds, clusterIds: [] });
  }, [statesIds, clusterIds, sdoIds]);

  useEffect(() => {
    setFilterRequest({ ...filterRequest, clusterIds: [], sdoIds: [], machineIds: [], clusterNames: [], sdoNames: [], machineNames: [] });
  }, [statesIds]);

  useEffect(() => {
    setFilterRequest({ ...filterRequest, sdoIds: [], machineIds: [], sdoNames: [], machineNames: [] });
  }, [clusterIds]);

  useEffect(() => {
    setFilterRequest({ ...filterRequest, machineIds: [], machineNames: [] });
  }, [sdoIds]);

  useEffect(() => {
    if (resetFlag) {
      fetchOrderDetails(filterRequest);
      setResetFlag(false);
    }
  }, [filterRequest]);

  const setSelectedIds = (e: any, label?: string, e1?: any, label1?: string) => {
    if (label && label1) {
      setFilterRequest({ ...filterRequest, [label]: [...e], [label1]: [...e1] });
    }
  };

  const resetFilter = () => {
    setResetFlag(true);
    fetchFilterRequest();
  };

  const applyFilter = () => {
    setFilterRequest({ ...filterRequest, pageNo: 0 });
    fetchOrderDetails(filterRequest);
  };

  const getSelectedOptionLength = (arr: any) => {
    if (arr && arr?.length) {
      return arr?.map((e: any) => e?.keyValueDto?.length)?.reduce((partialSum: number, a: number) => partialSum + a, 0);
    } else {
      return 0;
    }
  };

  return (
    <>
      {
        userRole === ROLES.VENDOR_ADMIN
          ? (
            <div className={classes.filterContainer}>
              <div className={classes.filterSubContent}>
                <DateRangeInput></DateRangeInput>
                <SelectedValueBadge title={'Selected Date'} selectionLabel={dateRangeLabel || 'Custom Date'} contentList={dateRangeLabel ? [] : [moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY')]}></SelectedValueBadge>
              </div>
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="reset"
                  onClick={resetFilter}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Apply"
                  onClick={applyFilter}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
            </div>
            )
          : (
            <div className={classes.filterContainer}>
              <div className={classes.filterSubContent}>
                <DateRangeInput></DateRangeInput>
                <SelectedValueBadge title={'Selected Date'} selectionLabel={dateRangeLabel || 'Custom Date'} contentList={dateRangeLabel ? [] : [moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY')]}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <CheckBox
                  label="Status"
                  optionLabel="value"
                  optionValue="key"
                  options={[{ key: 0, value: 'All' }, ...status]}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'statusIds', e1, 'statusNames'); }}
                  selectedIds={statusIds}
                  // setSelectedNames={(e: any) =>{}}
                  selectedNames={statusNames}
                />
                <SelectedValueBadge title={'Status'} contentList={statusNames} selectionLabel={statusIds && statusIds?.length && statusIds?.length !== status?.length ? `${statusIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <CheckBox
                  label="States"
                  optionLabel="value"
                  optionValue="id"
                  options={[{ id: 0, value: 'All' }, ...states]}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'statesIds', e1, 'stateNames'); }}
                  selectedIds={statesIds}
                  // setSelectedNames={(e: any) => setSelectedNames(e,'statusNames')}
                  selectedNames={stateNames}
                  isDisabled={statesIds?.length === 0 && (clusterIds?.length > 0 || sdoIds?.length > 0 || machineIds?.length > 0)}

                />
                <SelectedValueBadge title={'States'} contentList={stateNames} selectionLabel={statesIds && statesIds?.length && statesIds?.length !== states?.length ? `${statesIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <NestedDropdown
                  label="Cluster"
                  subOptionName="keyValueDto"
                  subOptionlabel="stateName"
                  subOptionValue="stateId"
                  optionLabel="value"
                  optionValue="id"
                  options={clustors ? [{ stateId: 0, stateName: 'All', keyValueDto: [] }, ...clustors] : []}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'clusterIds', e1, 'clusterNames'); }}
                  selectedIds={clusterIds}
                  selectedNames={clusterNames}
                  isDisabled={clusterIds?.length === 0 && (sdoIds?.length > 0 || machineIds?.length > 0)}
                />
                <SelectedValueBadge title={'Cluster'} contentList={clusterNames} selectionLabel={clusterIds && clusterIds?.length && clusterIds?.length !== getSelectedOptionLength(clustors) ? `${clusterIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <NestedDropdown
                  label="SDO"
                  subOptionName="keyValueDto"
                  subOptionlabel="clusterName"
                  subOptionValue="clusterId"
                  optionLabel="value"
                  optionValue="id"
                  options={[{ clusterId: 0, clusterName: 'All', keyValueDto: [] }, ...sdos]}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'sdoIds', e1, 'sdoNames'); }}
                  selectedIds={sdoIds}
                  selectedNames={sdoNames}
                  isDisabled={(sdoIds?.length === 0 && machineIds?.length > 0) || (statesIds?.length > 0 && clusterIds?.length <= 0)}
                />
                <SelectedValueBadge title={'SDO'} contentList={sdoIds} selectionLabel={sdoIds && sdoIds?.length && sdoIds?.length !== getSelectedOptionLength(sdos) ? `${sdoIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <NestedDropdown
                  label="Machine"
                  subOptionName="keyValueDto"
                  subOptionlabel="sdoName"
                  subOptionValue="sdoId"
                  optionLabel="value"
                  optionValue="machineId"
                  options={machines ? [{ sdoId: 0, sdoName: 'All', keyValueDto: [] }, ...machines] : []}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'machineIds', e1, 'machineNames'); }}
                  selectedIds={machineIds}
                  selectedNames={machineNames}
                  isDisabled={(statesIds?.length > 0 && clusterIds?.length <= 0) || (clusterIds?.length > 0 && sdoIds?.length <= 0)}
                />
                <SelectedValueBadge title={'Machine'} contentList={machineNames} selectionLabel={machineIds && machineIds?.length && machineIds?.length !== getSelectedOptionLength(machines) ? `${machineIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.filterSubContent}>
                <CheckBox
                  label="Spray Type"
                  optionLabel="value"
                  optionValue="key"
                  options={machineTypes ? [{ key: 0, value: 'All' }, ...machineTypes] : [{ key: 0, value: 'All' }]}
                  setSelectedIds={(e: any, e1: any) => { setSelectedIds(e, 'sprayTypeIds', e1, 'sprayTypeNames'); }}
                  selectedIds={sprayTypeIds}
                  // setSelectedNames={(e: any) =>{}}
                  selectedNames={sprayTypeNames}
                />
                <SelectedValueBadge title={'Spray Type'} contentList={sprayTypeNames} selectionLabel={sprayTypeIds && sprayTypeIds?.length && sprayTypeIds?.length !== machineTypes?.length ? `${sprayTypeIds?.length} Selected` : 'All'}></SelectedValueBadge>
              </div>
              <div className={classes.buttonContainer}>
                <CustomButton
                  label="reset"
                  onClick={resetFilter}
                  customStyle={classes.resetButton}
                ></CustomButton>
                <CustomButton
                  label="Apply"
                  onClick={applyFilter}
                  customStyle={classes.applyButton}
                ></CustomButton>
              </div>
            </div>
            )}
    </>
  );
};

export default Filter;
