import React, { useEffect } from 'react';
import { DateRangePicker, type DefinedRange } from 'mui-daterange-picker';
import { useStyles } from './style';
import { useFilter } from '../../hooks/useFilter';

interface Props {}

const setDate = (noOfDays: number): Date => {
  const date: Date = new Date();
  date.setDate(date.getDate() - noOfDays);
  return date;
};

const customLabels: DefinedRange[] = [
  {
    startDate: setDate(0),
    endDate: setDate(0),
    label: 'Today'
  },
  {
    startDate: setDate(1),
    endDate: setDate(1),
    label: 'Yesterday'
  },
  {
    startDate: setDate(7),
    endDate: setDate(0),
    label: 'Last 7 Days'
  },
  {
    startDate: setDate(30),
    endDate: setDate(0),
    label: 'Last 30 Days'
  },
  {
    startDate: setDate(90),
    endDate: setDate(0),
    label: 'Last 3 Months'
  }
];

const DateRangeInput: React.FunctionComponent<Props> = () => {
  const clasess = useStyles();
  const { setFilterRequest, filterRequest } = useFilter();

  const [open, setOpen] = React.useState(false);

  const toggle = () => { setOpen(!open); };

  useEffect(() => {
    if (filterRequest?.dateRangeLabel === 'Last 7 Days') {
      const obj: any = document.getElementById('dateRangePicker');
      obj?.querySelector('.MuiList-root :nth-child(3)')?.click();
      setOpen(false);
    }
  }, [filterRequest?.dateRangeLabel === 'Last 7 Days']);

  const onDateChange = (date: any) => {
    const { startDate, endDate, label } = date;
    setFilterRequest({ ...filterRequest, startDate, endDate, dateRangeLabel: label || '' });
  };

  return (
    <>
      <div onClick={() => { toggle(); }} className={clasess.dateInputBox}>
        Date range
        <img
          src={`${process.env.PUBLIC_URL}/calendarIcon.png`}
          alt="calendar-icon"
          className={clasess.calendarIcon}
        />
      </div>
      <div className={clasess.datePickerPopup} id='dateRangePicker' style={{ visibility: open ? 'visible' : 'hidden' }} >
        <DateRangePicker
          definedRanges={customLabels}
          open={true}
          initialDateRange={{ startDate: setDate(7), endDate: setDate(0) }}
          toggle={toggle}
          onChange={(range: any) => { onDateChange(range); }}
          maxDate={new Date()}
        />
      </div>
    </>
  );
};

export default DateRangeInput;
