import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useStyles } from './styles';
// import { type } from '@testing-library/user-event/dist/type'

export interface tabOptionProps {
  id: number
  label: string
}

interface CustomTabsProps {
  options: tabOptionProps[]
  optionLabel: string
  optionValue: string
  tabIndex: number
  children: React.ReactNode
  handleChange: (event: React.SyntheticEvent, tabIndex: number) => void
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  options,
  optionValue,
  optionLabel,
  tabIndex,
  handleChange,
  children
}) => {
  const classes = useStyles();

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Tabs value={tabIndex} onChange={handleChange} className={classes.tabs}>
          {options && options.map((option: tabOptionProps) => <Tab label={option.label} key={`tabOpt_${option.id}`}/>)}
        </Tabs>
      </div>
      <div className={classes.tabContainer}>
        {children}
      </div>
    </div>
  );
};
export default CustomTabs;
